import React from 'react';

import RightPaneWidgetFrame from '../01_atoms/RightPaneWidgetFrame';
import EditPassengerForm from '../02_molecules/EditPassengerForm';

const EditOnePassengerRightPane = ({ focusedLeftItemId, ...otherProps }) => (
  <RightPaneWidgetFrame focusedLeftItemId={focusedLeftItemId}>
    <EditPassengerForm {...otherProps} />
  </RightPaneWidgetFrame>
);

export default EditOnePassengerRightPane;
