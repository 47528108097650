import React from 'react';
import { Responsive } from 'semantic-ui-react';
import {
  backgroundColor,
  lightBackgroundColor,
  paneBackgroundColor,
  primaryColor,
} from '../00_environment/Colors';
import { withDefaultFont } from '../00_environment/Fonts';
import bg from '../../assets/bg.png';

const Page = ({ fullScreen = false, children }) => (
  <div
    id="app-wrapper"
    style={{
      width: '100%',
      minHeight: '100vh',
      margin: 0,
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      // backgroundImage: `url(${bg})`,
      // backgroundSize: 'cover',
      // backgroundRepeat: 'no-repeat',
      backgroundColor: paneBackgroundColor,
      position: 'relative',
    }}
  >
    <div
      id="inner-wrapper"
      style={withDefaultFont({
        width: '100%',
        // minWidth: '100vw',

        flex: '1',
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'relative',
        // maxWidth: Responsive.onlyTablet.maxWidth,
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: paneBackgroundColor,
        color: primaryColor,
        margin: 0,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        // boxShadow: '0px -1px 6px 2px rgba(0,0,0,0.2)',
      })}
    >
      {children}
    </div>
  </div>
);

export default Page;
