import React from 'react';
import { withBlueLabelFont } from '../00_environment/Fonts';
import {
  primaryColor,
  blueLabelBackgroundColor,
  highlightedBlueLabelBackgroundColor,
  blueLabelFontColor,
} from '../00_environment/Colors';
import { roundedElementBorderRadius } from '../00_environment/Constants';

const BlueLabel = ({ highlighted: h = false, children }) => (
  <div
    style={withBlueLabelFont({
      backgroundColor: h ? highlightedBlueLabelBackgroundColor : blueLabelBackgroundColor,
      color: h ? primaryColor : blueLabelFontColor,
      borderRadius: roundedElementBorderRadius,
      padding: 5,
      paddingTop: 2,
      paddingBottom: 2,
    })}
  >
    {children}
  </div>
);

export default BlueLabel;
