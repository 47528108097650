import React from 'react';

import PopupScreen from '../../02_molecules/PopupScreen';
import LanguageSettingsWidget from '../../03_organisms/LanguageSettingsWidget';

const MobileLanguageSettingsPage = ({
  lang,
  supportedLanguages,
  onLangChange = () => {},
  onBack = () => {},
  translator: t,
}) => (
  <PopupScreen title={t('Language settings')} onBack={onBack} leftIcon="close">
    <LanguageSettingsWidget
      {...{
        lang,
        supportedLanguages,
        onLangChange,
        onBack,
        translator: t,
      }}
    />
  </PopupScreen>
);
export default MobileLanguageSettingsPage;
