import React from 'react';
import SvgColor from 'react-svg-color';
import arrow from './arrow.svg';

const LightRightArrow = ({ color, width }) => (
  <div
    style={{
      display: 'inline-block',
      marginRight: 10,
      marginLeft: 10,
      marginTop: 2,
      width,
    }}
  >
    <SvgColor svg={arrow} width={width} colors={[color]} />
  </div>
);

export default LightRightArrow;
