import React from 'react';

import DualPanePage from '../01_atoms/DualPanePage';
import Page from '../01_atoms/Page';
import HomeTopBar from '../02_molecules/HomeTopBar';
import TicketRightPane from '../02_molecules/TicketRightPane';
import OrdersListWidget from '../03_organisms/OrdersListWidget';

const OrdersPage = ({
  tickets,
  isFiltered,
  onUnfilter,
  ticket = null,
  translationManager,
  translatorForNamespace,
}) => {
  const t = translatorForNamespace('OrdersPageUI');
  return (
    <Page>
      <HomeTopBar translatorForNamespace={translatorForNamespace} selectedTab="orders" />
      <DualPanePage
        leftComponent={OrdersListWidget}
        rightComponent={
          ticket
          && (({ scrollTop = 0 }) => (
            <TicketRightPane
              scrollTop={scrollTop}
              focusedLeftItemId={`ticket-thumb_${ticket.id}`}
              ticket={ticket}
              translationManager={translationManager}
              translatorForNamespace={translatorForNamespace}
            />
          ))
        }
        {...{
          tickets,
          isFiltered,
          onUnfilter,
          translationManager,
          translatorForNamespace,
          translator: t,
        }}
      />
    </Page>
  );
};
export default OrdersPage;
