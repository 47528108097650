import React from 'react';
import { Header, Icon } from 'semantic-ui-react';

import { withWidgetTitleFont } from '../00_environment/Fonts';

const PlaceHolder = ({ title, icon, content = null }) => (
  <div
    style={{
      flex: '1',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Header icon style={withWidgetTitleFont({})}>
      <Icon name={icon} />
      {title}
    </Header>
    {content && (
      <div
        style={{
          marginTop: 30,
        }}
      >
        {content}
      </div>
    )}
  </div>
);

export default PlaceHolder;
