import React from 'react';
import SignInOrSignUpPageUI from '../../03_organisms/SignInOrSignUpPageUI';

const LoginPageUI = ({
  error,
  email,
  password,
  onEmailChange = () => {},
  onPasswordChange = () => {},
  onSubmit = () => {},
  onFacebookSignIn = () => {},
  onGoogleSignIn = () => {},
  onBack = () => {},
  translatorForNamespace,
}) => {
  const t = translatorForNamespace('LoginPageUI');
  return (
    <SignInOrSignUpPageUI
      {...{
        error,
        email,
        password,
        onEmailChange,
        onPasswordChange,
        onSubmit,
        onFacebookSignIn,
        onGoogleSignIn,
        title: t('Login'),
        dividerText: t('or login with'),
        onBack,
        translatorForNamespace,
      }}
    />
  );
};

export default LoginPageUI;
