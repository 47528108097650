import React from 'react';
import { ucfirstEachWord } from '@seafront/ucfirst';

import DefaultScreen from '../../02_molecules/DefaultScreen';
import TripDetailsWidget from '../../03_organisms/TripDetailsWidget';

const GenericTripDetailsPageUI = ({
  sdk,
  selectedTrip,
  isReturn,
  showSeatsLink,
  confirmLink,
  numberOfPassengers,
  isOneWayTrip,
  onBack = () => {},
  history,
}) => {
  const {
    i18n: { translatorForNamespace },
  } = sdk;
  const t = translatorForNamespace('GenericTripDetailsPageUI');

  const dep = selectedTrip.originTerminal.name;
  const arr = selectedTrip.destinationTerminal.name;

  const title = (a, b) => `${ucfirstEachWord(a)} ⟶ ${ucfirstEachWord(b)}`;

  return (
    <DefaultScreen
      title={t('{CityXtoCityY} trip', { CityXtoCityY: title(dep, arr) })}
      onBack={onBack}
    >
      <TripDetailsWidget
        {...{
          sdk,
          trip: selectedTrip,
          isReturn,
          numberOfPassengers,
          isOneWayTrip,
          confirmLink,
          showSeatsLink,
          onBack: isReturn ? () => history.push('/book/return') : () => history.push('/book/out'),
        }}
      />
    </DefaultScreen>
  );
};

export default GenericTripDetailsPageUI;
