import React, { Component } from 'react';
import { Responsive } from 'semantic-ui-react';
import { Elements } from 'react-stripe-elements';

import ResponsivePage from '../../01_atoms/ResponsivePage';
import DefaultScreen from '../../02_molecules/DefaultScreen';
import PaymentPaneWidget from '../../03_organisms/PaymentPaneWidget';

// Define component here to avoid losing focus on forms because of anonymous comp
const BasicPageLayout = (props) => {
  const { onBack, translator: t } = props;

  return (
    <Elements>
      <DefaultScreen title={t('Payment')} onBack={onBack}>
        <PaymentPaneWidget {...props} />
      </DefaultScreen>
    </Elements>
  );
};

class CreditCardPaymentPageUI extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);

    this.state = {
      cardComplete: false,
    };
  }

  async submit(stripe) {
    const { email, onToken = () => {} } = this.props;

    // Create Stripe token
    const { token } = await stripe.createToken({ name: email });
    // console.log(`Stripe token generated: ${token}`);
    onToken(email, token);
  }

  canSubmit() {
    const { cardComplete = false } = this.state;
    return cardComplete;
  }

  render() {
    const {
      translatorForNamespace,
      payAmount,
      error,
      onBack = () => {},
      numberOfPassengers,
    } = this.props;
    const t = translatorForNamespace('CreditCardPaymentPageUI');

    const elementsOptions = {
      style: {
        base: {
          fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
          margin: '0',
          outline: '0',
          WebkitAppearance: 'none',
          tapHighlightColor: 'rgba(255,255,255,0)',
          lineHeight: '1.21428571em',
          padding: '.67857143em 1em',
          fontSize: '1em',
          background: '#fff',
          border: '1px solid rgba(34,36,38,.15)',
          color: 'rgba(0,0,0,.87)',
          borderRadius: '.28571429rem',
          boxShadow: '0 0 0 0 transparent inset',
          transition: 'color .1s ease,border-color .1s ease',
        },
        invalid: {
          color: '#c23d4b',
        },
      },
    };

    const widgetProps = {
      translator: t,
      onBack,
      canSubmit: () => this.canSubmit(),
      submit: (...args) => this.submit(...args),
      setState: (...args) => this.setState(...args),

      numberOfPassengers,
      payAmount,
      elementsOptions,
      error,
    };

    // We need to use two different Elements components to manage responsiveness with Stripe
    // cf https://github.com/stripe/react-stripe-elements/issues/479
    return (
      <ResponsivePage
        wideScreenComponent={BasicPageLayout}
        mobileComponent={BasicPageLayout}
        threshold={Responsive.onlyMobile.maxWidth}
        {...widgetProps}
      />
    );
  }
}

export default CreditCardPaymentPageUI;
