import React from 'react';
import { Form, Message, Icon } from 'semantic-ui-react';

import { withWidgetSubtitleFont } from '../00_environment/Fonts';
import Divider from '../01_atoms/Divider';
import Container from '../01_atoms/Container';
import Bottom from '../01_atoms/Bottom';
import LinkButton from '../01_atoms/LinkButton';
import Button from '../01_atoms/Button';
import DefaultScreen from '../02_molecules/DefaultScreen';
import FormContainer from '../01_atoms/FormContainer';
import TitledContainer from '../01_atoms/TitledContainer';
import ResponsiveCentralPaneWrapper from '../02_molecules/ResponsiveCentralPaneWrapper';

const SignInOrSignUpPageUI = ({
  error,
  email,
  password,
  onEmailChange = () => {},
  onPasswordChange = () => {},
  onSubmit = () => {},
  onFacebookSignIn = () => {},
  onGoogleSignIn = () => {},
  onAppleSignIn = null,
  title,
  dividerText,
  onBack = () => {},
  showCreateAccount = true,
  translatorForNamespace,
}) => {
  const t = translatorForNamespace('SignInOrSignUpPageUI');

  return (
    <DefaultScreen title={title} onBack={onBack}>
      <ResponsiveCentralPaneWrapper>
        <TitledContainer title={title} rightButtonText={t('Back')} onRightButtonClick={onBack}>
          <FormContainer>
            <Form onSubmit={onSubmit} error={!!error}>
              {!!error && <Message error header={t('Error')} content={error} />}

              <Form.Field>
                <Form.Input
                  key="SignInOrSignUp__email"
                  name="email"
                  placeholder={t('E-mail')}
                  value={email || ''}
                  onChange={(e, o) => onEmailChange(o.value)}
                />
              </Form.Field>
              <Form.Field>
                <Form.Input
                  key="SignInOrSignUp__password"
                  name="password"
                  type="password"
                  placeholder={t('Password')}
                  value={password || ''}
                  onChange={(e, o) => onPasswordChange(o.value)}
                />
              </Form.Field>
              <Button fluid type="submit" primary>
                {title}
              </Button>
            </Form>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                marginTop: 10,
              }}
            >
              <LinkButton
                to="/login/forgot-password/"
                style={withWidgetSubtitleFont({ fontSize: '0.9rem' })}
              >
                {t('Forgot your password?')}
              </LinkButton>
            </div>
          </FormContainer>

          {/*
              // Disabling social auth temporarily because of Apple
              <Divider text={dividerText} />

              <Container
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  width: '100%',
                  flexWrap: 'wrap',
                  maxWidth: 510,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  padding: 0,
                }}
              >
                {onAppleSignIn && (
                  <Button
                    color="black"
                    onClick={onAppleSignIn}
                    style={{ flex: '1', minWidth: 170, margin: 5 }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Icon style={{ display: 'inline-block', lineHeight: '1.3rem' }} name="apple" />
                      {' Apple'}
                    </div>
                  </Button>
                )}
                <Button
                  color="facebook"
                  onClick={onFacebookSignIn}
                  style={{ flex: '1', minWidth: 170, margin: 5 }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Icon style={{ display: 'inline-block', lineHeight: '1.3rem' }} name="facebook" />
                    {' Facebook'}
                  </div>
                </Button>
                <Button
                  color="google plus"
                  onClick={onGoogleSignIn}
                  style={{ flex: '1', minWidth: 170, margin: 5 }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Icon style={{ display: 'inline-block', lineHeight: '1.3rem' }} name="google" />
                    {' Google'}
                  </div>
                </Button>
              </Container>
            */}

          {showCreateAccount && (
            <Bottom>
              <Container style={{ textAlign: 'center' }}>
                <LinkButton to="/create-account/">{t('Create a free account')}</LinkButton>
              </Container>
            </Bottom>
          )}
        </TitledContainer>
      </ResponsiveCentralPaneWrapper>
    </DefaultScreen>
  );
};

export default SignInOrSignUpPageUI;
