import React from 'react';

import Button from '../01_atoms/Button';
import ElementGroup from '../01_atoms/ElementGroup';
import SquaredText from '../01_atoms/SquaredText';
import KeyValueElement from '../01_atoms/KeyValueElement';

const TripSeatDetails = ({
  numberOfPassengers,
  selectedSeats = [],
  onEditLink = null,
  onEdit = null,
  translator: t,
  style = {},
  id,
  dontHighlight = false,
}) => {
  const nSelectedSeats = selectedSeats.length;
  const seatsLeftToBook = numberOfPassengers - nSelectedSeats;

  return (
    <ElementGroup id={id} style={style} title={t('Placement')}>
      <KeyValueElement
        title={nSelectedSeats === 0 ? t('No seats selected yet') : t('Selected seats')}
        subtitle={t('This bus uses reserved seats')}
        value={selectedSeats.map((s) => (
          <SquaredText key={`placement:${s}`} style={{ width: 24, height: 24 }}>
            {s}
          </SquaredText>
        ))}
      >
        {(onEditLink || onEdit) && (
          <Button
            to={onEditLink}
            onClick={onEdit}
            fluid
            primary={!dontHighlight && seatsLeftToBook > 0}
          >
            {seatsLeftToBook > 0 ? t('Select seats') : t('Change seats')}
          </Button>
        )}
      </KeyValueElement>
    </ElementGroup>
  );
};

export default TripSeatDetails;
