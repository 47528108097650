import React from 'react';

import Element from '../01_atoms/Element';
import ElementGroup from '../01_atoms/ElementGroup';
import TicketDetailsContent from './TicketDetailsContent';

const TicketDetails = ({
  title,
  style = {},
  elementStyle,
  id,
  titlePadding,
  backgroundColor,
  ...otherProps
} = {}) => (
  <ElementGroup
    id={id}
    style={style}
    title={title}
    titlePadding={titlePadding}
    backgroundColor={backgroundColor}
  >
    <Element style={elementStyle}>
      <TicketDetailsContent {...otherProps} />
    </Element>
  </ElementGroup>
);

export default TicketDetails;
