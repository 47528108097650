import React from 'react';

import RightPaneWidgetFrame from '../01_atoms/RightPaneWidgetFrame';
import MainSeatsSelectorWidget from '../03_organisms/MainSeatsSelectorWidget';

const SeatsSelectionRightPane = ({ scrollTop, focusedLeftItemId, ...props }) => (
  <RightPaneWidgetFrame scrollTop={scrollTop} focusedLeftItemId={focusedLeftItemId}>
    <MainSeatsSelectorWidget {...props} />
  </RightPaneWidgetFrame>
);

export default SeatsSelectionRightPane;
