import React from 'react';
import DualPaneOrderSetupPage from './07_OrderSetupPage.widescreen';

const DualPaneCovidInfoPage = (props) => (
  <DualPaneOrderSetupPage
    defaultRightComponent="covid-info"
    defaultFocusedLeftItemId="edit-covid-details-button"
    {...props}
  />
);

export default DualPaneCovidInfoPage;
