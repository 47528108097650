import React from 'react';
import { withDefaultFont } from '../00_environment/Fonts';

const FormElement = ({ children, style, ...rest }) => {
  const parsedStyle = Object.assign(
    withDefaultFont({
      border: 'none',
      borderRadius: 0,
      boxShadow: 0,
      ...style,
    }),
  );

  return (
    <div style={parsedStyle} {...rest}>
      {children}
    </div>
  );
};

export default FormElement;
