import React from 'react';

import Page from '../../01_atoms/Page';
import HomeTopBar from '../../02_molecules/HomeTopBar';
import BookingForm from '../../02_molecules/BookingForm';

const BookingFormPage = ({
  formFields,
  onChange,
  onSubmit,
  translationManager,
  translatorForNamespace,
  dropDownSearch,
  departures,
  arrivals,
  disableReturnField,
  isIdle = false,
}) => {
  const t = translatorForNamespace('BookingFormPageUI');

  return (
    <Page>
      <HomeTopBar translatorForNamespace={translatorForNamespace} />

      <BookingForm
        formData={formFields}
        onChange={onChange}
        onSubmit={onSubmit}
        translationManager={translationManager}
        translator={t}
        dropDownSearch={dropDownSearch}
        departures={departures}
        arrivals={arrivals}
        disableReturnField={disableReturnField}
        isIdle={isIdle}
      />
    </Page>
  );
};

export default BookingFormPage;
