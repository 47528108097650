import React from 'react';

import PopupScreen from '../../02_molecules/PopupScreen';
import MainSeatsSelectorWidget from '../../03_organisms/MainSeatsSelectorWidget';

const MobileGenericTripSeatSelectionPage = ({ sdk, onBack = () => {}, ...props }) => {
  const {
    i18n: { translatorForNamespace },
  } = sdk;

  const t = translatorForNamespace('GenericTripSeatSelectionPageUI');

  console.log('MobileGenericTripSeatSelectionPage.props = ', { ...props });

  return (
    <PopupScreen title={t('Select seats')} onBack={onBack} fullScreen>
      <MainSeatsSelectorWidget
        {...{
          sdk,
          onBack,
          ...props,
          onConfirm: onBack,
        }}
      />
    </PopupScreen>
  );
};

export default MobileGenericTripSeatSelectionPage;
