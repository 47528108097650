import React from 'react';
import QRCode from 'qrcode.react';
import { Image } from 'semantic-ui-react';
import moment from '../../lib/moment';

import Element from '../01_atoms/Element';
import ElementGroup from '../01_atoms/ElementGroup';
import SquaredText from '../01_atoms/SquaredText';
import Icon from '../01_atoms/Icon';
import HighligthedText from '../01_atoms/HighligthedText';

const BoardingPass = ({
  ticket, passengerIndex, t, backgroundColor, qrCodeData,
}) => {
  // FIXME: Add passengers
  const passenger = ticket.ticketDetails.passengers[passengerIndex];
  const {
    firstName, lastName, seat, ssn,
  } = passenger;

  const KeyValue = ({ name, value }) => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div style={{ fontWeight: 'bold' }}>{name}</div>
      <div>{value}</div>
    </div>
  );

  const { ticketDetails } = ticket;
  const departure = moment(ticketDetails.departureTime);
  const arrival = moment(ticketDetails.arrivalTime);
  // const rideDuration = moment.duration(arrival.diff(departure));
  // const formattedDuration = `${rideDuration.hours()}h ${rideDuration.minutes()}m`;
  const nDaysAdded = moment
    .duration(moment(arrival.format('YYYY-MM-DD')).diff(moment(departure.format('YYYY-MM-DD'))))
    .days();
  const d = nDaysAdded ? ` (+${nDaysAdded}d)` : '';
  const dh = departure.format('HH:mm');
  const ah = arrival.format('HH:mm');

  return (
    <>
      <ElementGroup
        backgroundColor={backgroundColor}
        style={{ maxWidth: 500, marginLeft: 'auto', marginRight: 'auto' }}
        title={null /* `Here your ticket for ${passenger.firstName}` */}
      >
        <Element
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <Image
            src={ticket.ticketDetails.transporter.logo}
            width={50}
            style={{ marginRight: 20 }}
          />
          <div style={{ fontWeight: 'bold', fontSize: '1rem' }}>
            {ticket.ticketDetails.transporter.name}
          </div>
        </Element>
        <Element style={{ textAlign: 'center' }}>{departure.format('LL')}</Element>
        <Element style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ width: '40%' }}>
            <div style={{ marginBottom: 10, fontWeight: 'bold' }}>
              {ticket.ticketDetails.originTerminal.name}
            </div>
            <div>
              <span style={{ fontWeight: 'bold', fontSize: '12px' }}>{t('Departing')}</span>
              <br />
              <span style={{ fontWeight: 'normal', fontSize: '10px' }}>{dh}</span>
            </div>
          </div>
          <div style={{ width: '20%', textAlign: 'center' }}>
            <Icon
              name="arrow alternate circle right outline"
              style={{ color: 'gray', fontSize: '3rem' }}
            />
          </div>
          <div style={{ width: '40%', textAlign: 'right' }}>
            <div style={{ marginBottom: 10, fontWeight: 'bold' }}>
              {ticket.ticketDetails.destinationTerminal.name}
            </div>
            <div>
              <span style={{ fontWeight: 'bold', fontSize: '12px' }}>{t('Arriving')}</span>
              <br />
              <span style={{ fontWeight: 'normal', fontSize: '10px' }}>{`${ah}${d}`}</span>
            </div>
          </div>
        </Element>
        <Element>
          <KeyValue name={t('First name')} value={<HighligthedText>{firstName}</HighligthedText>} />
          <KeyValue name={t('Last name')} value={lastName} />
          <KeyValue name={t('Cedula')} value={ssn || '1234567890'} />
          <div style={{ height: 20 }} />
          <KeyValue
            name={t('Seat')}
            value={<SquaredText style={{ width: 24, height: 24 }}>{seat}</SquaredText>}
          />
        </Element>

        <Element
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <QRCode value={qrCodeData} size={90} />
          <pre>{ticket.id.toUpperCase()}</pre>
        </Element>
      </ElementGroup>
    </>
  );
};

export default BoardingPass;
