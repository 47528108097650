import React from 'react';
import TopBar from '../01_atoms/TopBar';
import isNative from '../../lib/is-native';

const HomeTopBar = ({ translatorForNamespace, ...props }) => {
  const t = translatorForNamespace('HomeTopBar');

  const tabs = [];

  if (!isNative()) {
    tabs.push({
      link: 'https://reinadelcamino.ec/',
      icon: 'home',
    });
  }

  tabs.push(
    {
      link: '/book/',
      name: 'book',
      icon: 'search',
      displayName: t('New trip'),
    },
    {
      link: '/orders/',
      name: 'orders',
      displayName: t('My tickets'),
      icon: 'ticket',
    },
  );

  return (
    <TopBar
      {...{
        selectedTab: 'book',
        tabs,
        rightIcon: 'user',
        rightIconLink: '/settings/',
        ...props,
      }}
    />
  );
};

export default HomeTopBar;
