import React from 'react';

import Page from '../01_atoms/Page';
import PlaceHolder from '../02_molecules/PlaceHolder';
import HomeTopBar from '../02_molecules/HomeTopBar';

const EmptyOrdersPageUI = ({ translatorForNamespace }) => {
  const t = translatorForNamespace('EmptyOrdersPageUI');

  return (
    <Page>
      <HomeTopBar translatorForNamespace={translatorForNamespace} selectedTab="orders" />
      <PlaceHolder icon="ticket" title={t('No upcoming trip')} />
    </Page>
  );
};

export default EmptyOrdersPageUI;
