import React from 'react';
import check from 'check-types';
import { Redirect } from 'react-router-dom';
import { Checkbox } from 'semantic-ui-react';

import { thirdColor } from '../00_environment/Colors';
import Bottom from '../01_atoms/Bottom';
import Button from '../01_atoms/Button';
import Container from '../01_atoms/Container';
import Element from '../01_atoms/Element';
import ElementGroup from '../01_atoms/ElementGroup';
import TitledContainer from '../01_atoms/TitledContainer';
import PassengerDetails from './PassengerDetails';

const PassengerSelectionWidget = ({
  title,
  subtitle,
  translatorForNamespace,

  savedPassengers: saved,
  selectedPassengers: selected,
  numberOfPassengers: numberOfPassengersToSelect,

  onPassengerToggle = () => {},
  onAddPassenger = () => {},
  onConfirm = () => {},

  translator: t,
}) => {
  // If there's no passenger in the list,
  // forward directly to the "add passenger" form
  // for simpler UX.
  // FIXME: Should be done in flow control
  /*
  if (!check.nonEmptyArray(saved)) {
     return <Redirect to="/book/passenger-details/add" />;
  }
  */

  const allPassengersSelected = selected.length === numberOfPassengersToSelect;

  return (
    <TitledContainer title={t('Select passengers')}>
      <PassengerDetails
        title={title}
        subtitle={subtitle}
        translatorForNamespace={translatorForNamespace}
        passengers={selected}
        translator={t}
      />

      {saved.length > 0 && (
        <ElementGroup backgroundColor="white">
          {saved.map((p) => {
            const checked = selected.some((sp) => sp.id === p.id);
            const disabled = !checked && allPassengersSelected;
            const onClick = disabled
              ? () => {}
              : () => {
                onPassengerToggle(p);
              };

            return (
              <Element key={p.id} onClick={onClick}>
                <Checkbox
                  checked={checked}
                  label={`${p.firstName} ${p.lastName}`}
                  style={{ marginRight: 15 }}
                  disabled={disabled}
                />
              </Element>
            );
          })}
        </ElementGroup>
      )}

      <Bottom>
        {selected.length < numberOfPassengersToSelect && (
          <Container>
            <Button
              onClick={onAddPassenger}
              fluid
              style={{ marginBottom: 15, color: 'white' }}
              color={thirdColor}
            >
              {t('Add passenger')}
            </Button>
          </Container>
        )}

        <Container>
          <Button onClick={onConfirm} fluid primary disabled={!allPassengersSelected}>
            {t('Confirm')}
          </Button>
        </Container>
      </Bottom>
    </TitledContainer>
  );
};

export default PassengerSelectionWidget;
