import React from 'react';

import ResponsivePage from '../../01_atoms/ResponsivePage';

import SinglePaneAddPassengerPopup from './AddPassengerPopup.mobile';
import DualPaneAddPassengerPopup from './AddPassengerPopup.widescreen';

const AddPassengerPopup = ({ sdk, ...props }) => {
  const {
    i18n: { translatorForNamespace },
  } = sdk;

  const t = translatorForNamespace('AddPassengerPopupUI');

  return (
    <ResponsivePage
      wideScreenComponent={DualPaneAddPassengerPopup}
      mobileComponent={SinglePaneAddPassengerPopup}
      {...props}
      {...{
        sdk,
        translator: t,
      }}
    />
  );
};

export default AddPassengerPopup;
