import React from 'react';
import { panePadding, roundedElementBorderRadius } from '../00_environment/Constants';
import { leftPaneBackgroundColor, paneBorderColor } from '../00_environment/Colors';

const LeftPaneWrapper = ({ fullHeight = false, noSidePadding = false, children }) => (
  <div
    style={{
      border: paneBorderColor ? `solid 1px ${paneBorderColor}` : 'none',
      borderRadius: roundedElementBorderRadius,
      backgroundColor: leftPaneBackgroundColor || 'none',
      padding: noSidePadding ? 0 : panePadding,
      marginBottom: fullHeight ? 0 : 100,
      minHeight: fullHeight ? '100%' : 'undefined',
    }}
  >
    {children}
  </div>
);

export default LeftPaneWrapper;
