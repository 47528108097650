import React from 'react';
import { ucfirstEachWord } from '@seafront/ucfirst';

import DefaultScreen from '../../02_molecules/DefaultScreen';
import DualPanePage from '../../01_atoms/DualPanePage';
import TripDetailsWidget from '../../03_organisms/TripDetailsWidget';

import SeatsSelectionRightPane from '../../02_molecules/SeatsSelectionRightPane';

const LeftComponent = ({
  sdk,
  selectedTrip,
  isReturn,
  confirmLink,
  numberOfPassengers,
  isOneWayTrip,
  history,
}) => (
  <TripDetailsWidget
    {...{
      sdk,
      trip: selectedTrip,
      isReturn,
      numberOfPassengers,
      isOneWayTrip,
      confirmLink,
      showConfirmButton: false,
      onBack: isReturn ? () => history.push('/book/return') : () => history.push('/book/out'),
    }}
  />
);

const RightComponent = (p) => {
  const { history, confirmLink } = p;

  return (
    <SeatsSelectionRightPane
      focusedLeftItemId="seats-details"
      {...p}
      onConfirm={() => {
        history.push(confirmLink);
      }}
    />
  );
};

const GenericTripDetailsPageUI = (props) => {
  const { sdk, selectedTrip, onBack = () => {} } = props;
  const {
    i18n: { translatorForNamespace },
  } = sdk;
  const t = translatorForNamespace('GenericTripDetailsPageUI');

  const dep = selectedTrip.originTerminal.name;
  const arr = selectedTrip.destinationTerminal.name;

  const title = (a, b) => `${ucfirstEachWord(a)} ⟶ ${ucfirstEachWord(b)}`;

  return (
    <DefaultScreen
      title={t('{CityXtoCityY} trip', { CityXtoCityY: title(dep, arr) })}
      onBack={onBack}
    >
      <DualPanePage {...props} leftComponent={LeftComponent} rightComponent={RightComponent} />
    </DefaultScreen>
  );
};

export default GenericTripDetailsPageUI;
