import React from 'react';

import {
  primaryColor,
  blackTextColor,
  highlightedWidgetBackgroundColor,
  widgetBorderColor,
  seatMatrixBusyColor,
} from '../00_environment/Colors';
import { withBoldFont } from '../00_environment/Fonts';

const LegendItem = ({
  color, borderColor, bgColor, label, width = 30, marginRight = 10,
}) => (
  <div
    id="SeatMatrixAvailabilityLegend"
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
    }}
  >
    <div
      style={{
        width,
        height: Math.round((4 * width) / 5),
        lineHeight: Math.round((4 * width) / 5),
        borderRadius: 5,
        border: `solid 1.5px ${borderColor}`,
        backgroundColor: bgColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color,
        fontWeight: 'bold',
        marginLeft: 10,
      }}
    >
      x
    </div>
    <div
      style={withBoldFont({
        marginLeft: 10,
        marginRight,
        fontWeight: 600,
        fontSize: '0.95rem',
        flexWrap: 'wrap',
      })}
    >
      {label}
    </div>
  </div>
);

const SeatMatrixLegend = ({ translator: t }) => (
  <div
    style={{
      width: '80%',
      margin: 'auto',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 30,
      marginBottom: 10,
    }}
  >
    <LegendItem
      color={primaryColor}
      borderColor={primaryColor}
      bgColor={highlightedWidgetBackgroundColor}
      label={t('Selected seat')}
    />
    <LegendItem
      color="gray"
      borderColor={widgetBorderColor}
      bgColor="white"
      label={t('Free seat')}
    />
    <LegendItem
      color="gray"
      borderColor={seatMatrixBusyColor}
      bgColor={seatMatrixBusyColor}
      label={t('Busy seat')}
      marginRight={0}
    />
  </div>
);

export default SeatMatrixLegend;
