import React from 'react';
import { Form, Message } from 'semantic-ui-react';

import Button from '../../01_atoms/Button';
import FormContainer from '../../01_atoms/FormContainer';
import DefaultScreen from '../../02_molecules/DefaultScreen';
import TitledContainer from '../../01_atoms/TitledContainer';
import ResponsiveCentralPaneWrapper from '../../02_molecules/ResponsiveCentralPaneWrapper';

const ForgotPasswordPageUI = ({
  error,
  onBack = () => {},
  email,
  onEmailChange = () => {},
  onResetPassword = () => {},
  translatorForNamespace,
}) => {
  const t = translatorForNamespace('ForgotPasswordPageUI');
  return (
    <DefaultScreen title={t('Reset your password')} onBack={onBack}>
      <ResponsiveCentralPaneWrapper>
        <TitledContainer
          title={t('Reset your password')}
          rightButtonText={t('Back')}
          onRightButtonClick={onBack}
        >
          <FormContainer>
            <Form onSubmit={() => onResetPassword(email)} error={!!error}>
              {!!error && <Message error header={t('Error')} content={error.message} />}

              <Form.Field>
                <Form.Input
                  placeholder={t('E-mail')}
                  value={email}
                  onChange={(e, o) => onEmailChange(o.value)}
                />
              </Form.Field>
              <Button fluid type="submit" primary>
                {t('Reset password')}
              </Button>
            </Form>
          </FormContainer>
        </TitledContainer>
      </ResponsiveCentralPaneWrapper>
    </DefaultScreen>
  );
};

export default ForgotPasswordPageUI;
