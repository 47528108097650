const blackBeans = '#2D0E44';
const snowBis = '#E5E7EF';
const pastelBeans = '#B399D4';

export const lightBackgroundColor = '#FFF';
export const blackTextColor = '#434950';
export const backgroundColor = '#F0F1F1';
export const primaryColor = '#BE1623';
export const secondaryColor = '#2D3748';

export const thirdColor = '#3f51b5';
export const textColor = '#343F43';
export const discreetTextColor = '#A0AEC0';
export const grayElementColor = '#999EA1';
export const widgetBorderColor = '#E2E8F0';
export const widgetBackgroundColor = '#FBFDFF';
export const onDarkTextColor = '#FFFFFF';
export const topBarBgColor = secondaryColor;
export const topBarSubBgColor = '#172236';
export const topBarIdleTabColor = '#BCC0C9';
export const defaultButtonColor = '#E2E8F0';
export const emphasisColor = '#4D95F8';
export const secondaryButtonColor = '#A0AEC0';
export const textHighlightColor = '#FFE84A';
export const dangerColor = '#dd4b39';

export const paneBackgroundColor = '#F6FBFF';
export const paneBorderColor = '#E2E8F0';
export const leftPaneBackgroundColor = '#F6FBFF';

// Seat matrix
export const seatMatrixDefaultFreeColor = widgetBorderColor;
export const seatMatrixDefaultSelectedColor = primaryColor;
export const seatMatrixBusyColor = defaultButtonColor;
export const seatMatrixAltSelectedColor1 = '#F5AB35';
export const seatMatrixAltSelectedColor2 = '#FA5C98';
export const seatMatrixAltSelectedColor3 = '#9B59B6';
export const seatMatrixAltSelectedColor4 = '#FF6C5C';
export const seatMatrixAltFreeColor1 = '#F5AB354F';
export const seatMatrixAltFreeColor2 = '#FA5C984F';
export const seatMatrixAltFreeColor3 = '#9B59B64F';
export const seatMatrixAltFreeColor4 = '#FF6C5C4F';
export const seatMatrixAlternateColors = [
  // Yellow
  {
    freeColor: seatMatrixAltFreeColor1,
    selectedColor: seatMatrixAltSelectedColor1,
  },
  // Pink
  {
    freeColor: seatMatrixAltFreeColor2,
    selectedColor: seatMatrixAltSelectedColor2,
  },
  // Purple
  {
    freeColor: seatMatrixAltFreeColor3,
    selectedColor: seatMatrixAltSelectedColor3,
  },
  // Red
  {
    freeColor: seatMatrixAltFreeColor4,
    selectedColor: seatMatrixAltSelectedColor4,
  },
];

export const highlightedWidgetBackgroundColor = '#FFF8F8';
export const blueLabelBackgroundColor = 'rgba(92, 165, 223, 0.08)';
export const highlightedBlueLabelBackgroundColor = 'rgba(190, 22, 35, 0.08)';
export const blueLabelFontColor = '#5CA5DF';
export const okColor = '#009A34';
