import React from 'react';
import { Redirect } from 'react-router-dom';
import check from 'check-types';

import DefaultScreen from '../../02_molecules/DefaultScreen';
import OrderDetailsWidget from '../../03_organisms/OrderDetailsWidget';

const SinglePaneOrderSetupPage = (props) => {
  const {
    sdk, selectedPassengers, numberOfPassengers, onBack = () => {}, history,
  } = props;
  const {
    i18n: { translatorForNamespace },
  } = sdk;
  const t = translatorForNamespace('CheckoutPageUI');

  if (!check.nonEmptyArray(selectedPassengers) || selectedPassengers.length < numberOfPassengers) {
    return <Redirect to="/book/passenger-details/" />;
  }

  const goTo = (to) => history.push(`/book/${to}`);

  return (
    <DefaultScreen title={t('Your trip details')} onBack={onBack}>
      <OrderDetailsWidget
        {...props}
        onEditPassengers={() => goTo('passenger-details')}
        onEditBillingDetails={() => goTo('billing-details')}
        onEditCovidDetails={() => goTo('covid-info')}
      />
    </DefaultScreen>
  );
};

export default SinglePaneOrderSetupPage;
