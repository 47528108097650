import React from 'react';

import { discreetTextColor } from '../00_environment/Colors';
import { withWidgetTitleFont } from '../00_environment/Fonts';
import Icon from '../01_atoms/Icon';

const SelectedPassengers = ({ translatorForNamespace, passengers = [] }) => {
  const t = translatorForNamespace('SelectedPassengers');

  passengers.sort((a, b) => (a.firstName > b.firstName ? 1 : -1));

  let title = t('No passenger selected');
  if (passengers.length > 0) {
    title = passengers.map((p) => p.firstName).join(', ');
    if (title.includes(',')) {
      const n = title.lastIndexOf(',');
      title = `${title.substr(0, n)} ${t('and')}${title.substr(n + 1)}`;
    }
  }

  let style = withWidgetTitleFont({
    fontSize: '1rem',
    display: 'inline-flex',
    alignItems: 'center',
  });
  if (passengers.length === 0) {
    style = Object.assign(style, { color: discreetTextColor });
  }

  return (
    <div style={style}>
      <div style={{ textAlign: 'left', display: 'inline-block', width: 30 }}>
        <Icon name="user" style={{}} />
      </div>
      {' '}
      {title}
    </div>
  );
};

export default SelectedPassengers;
