// Assets
// FIXME: Find a better way?
import { BrowserRouter, HashRouter } from 'react-router-dom';

import boy from './assets/boy.svg';
import girl from './assets/girl.svg';
import loadingImage from './assets/loading-image.gif';
import tick from './assets/tick.svg';
import bookingPending from './assets/booking-pending.svg';
import tire from './assets/tire.svg';

import decor from './components/01_atoms/Mountains/assets/decor.svg';
import fenetreSlim from './components/01_atoms/Mountains/assets/fenetre-slim.png';

export function getImagesToPreload() {
  console.log('getImagesToPreload() --> ', [decor, fenetreSlim]);
  return [decor, fenetreSlim];
}

// //////////////////////////////////////////////////////////////////////////////

// Environment
export * from './components/00_environment/Colors';
export * from './components/00_environment/Fonts';

// Atoms
export { default as BlockWithLeftIcon } from './components/01_atoms/BlockWithLeftIcon';
export { default as Bottom } from './components/01_atoms/Bottom';
export { default as Button } from './components/01_atoms/Button';
export { default as ClosableInput } from './components/01_atoms/ClosableInput';
export { default as Container } from './components/01_atoms/Container';
export { default as Divider } from './components/01_atoms/Divider';
export { default as Element } from './components/01_atoms/Element';
export { default as ElementGroup } from './components/01_atoms/ElementGroup';
export { default as HighligthedText } from './components/01_atoms/HighligthedText';
export { default as Icon } from './components/01_atoms/Icon';
export { default as KeyValueElement } from './components/01_atoms/KeyValueElement';
export { default as LinkButton } from './components/01_atoms/LinkButton';
export { default as ListSelect } from './components/01_atoms/ListSelect';
export { default as LoadingPlaceHolder } from './components/01_atoms/LoadingPlaceHolder';
export { default as Page } from './components/01_atoms/Page';
export { default as PaymentIcon } from './components/01_atoms/PaymentIcon';
export { default as PlaceHolderElement } from './components/01_atoms/PlaceHolderElement';
export { default as ResponsivePage } from './components/01_atoms/ResponsivePage';
export { default as RightPaneWidgetFrame } from './components/01_atoms/RightPaneWidgetFrame';
export { default as Spacer } from './components/01_atoms/Spacer';
export { default as SquaredText } from './components/01_atoms/SquaredText';
export { default as Subtitle } from './components/01_atoms/Subtitle';
export { default as Title } from './components/01_atoms/Title';
export { default as TitleBlock } from './components/01_atoms/TitleBlock';
export { default as TitledContainer } from './components/01_atoms/TitledContainer';
export { default as Top } from './components/01_atoms/Top';
export { default as TopBar } from './components/01_atoms/TopBar';

// Molecules
export { default as AddPassengerRightPane } from './components/02_molecules/AddPassengerRightPane';
export { default as AddPassengerWidget } from './components/02_molecules/AddPassengerWidget';
export { default as AddressFormComponent } from './components/02_molecules/AddressFormComponent';
export { default as BillingDetailsWidget } from './components/02_molecules/BillingDetailsWidget';
export { default as BookingForm } from './components/02_molecules/BookingForm';
export { default as CitySelectionWidget } from './components/02_molecules/CitySelectionWidget';
export { default as CovidInfoWidget } from './components/02_molecules/CovidInfoWidget';
export { default as DateSelectionComponent } from './components/02_molecules/DateSelectionComponent';
export { default as DateSelectionWidget } from './components/02_molecules/DateSelectionWidget';
export { default as DefaultScreen } from './components/02_molecules/DefaultScreen';
export { default as EditPassengerForm } from './components/02_molecules/EditPassengerForm';
export { default as EditPassengerFormUI } from './components/02_molecules/EditPassengerFormUI';
export { default as HomeTopBar } from './components/02_molecules/HomeTopBar';
export { default as LoadingScreen } from './components/02_molecules/LoadingScreen';
export { default as OrderPriceDetails } from './components/02_molecules/OrderPriceDetails';
export { default as PassengerDetails } from './components/02_molecules/PassengerDetails';
export { default as PlaceHolder } from './components/02_molecules/PlaceHolder';
export { default as PopupField } from './components/02_molecules/PopupField';
export { default as PopupScreen } from './components/02_molecules/PopupScreen';
export { default as ResponsiveCentralPaneWrapper } from './components/02_molecules/ResponsiveCentralPaneWrapper';
export { default as RidesList } from './components/02_molecules/RidesList';
export { default as RidesListItem } from './components/02_molecules/RidesListItem';
export { default as SeatMatrix } from './components/02_molecules/SeatMatrix';
export { default as SeatMatrixFloorSelector } from './components/02_molecules/SeatMatrixFloorSelector';
export { default as SeatMatrixLegend } from './components/02_molecules/SeatMatrixLegend';
export { default as SeatSelectionWidget } from './components/02_molecules/SeatSelectionWidget';
export { default as SelectedPassengers } from './components/02_molecules/SelectedPassengers';
export { default as SplashScreen } from './components/02_molecules/SplashScreen';
export { default as TicketDetails } from './components/02_molecules/TicketDetails';
export { default as TicketDetailsContent } from './components/02_molecules/TicketDetailsContent';
export { default as TripPriceDetails } from './components/02_molecules/TripPriceDetails';
export { default as TripSeatDetails } from './components/02_molecules/TripSeatDetails';

// Organisms
export { default as BoardingPass } from './components/03_organisms/BoardingPass';
export { default as SignInOrSignUpPageUI } from './components/03_organisms/SignInOrSignUpPageUI';

// Pages
export { default as BookingFormPage } from './components/04_pages/book/01_BookingFormPage';
export { default as DateSelectionPopup } from './components/04_pages/book/01b_DateSelectionPopup';
export { default as GenericTripsListPage } from './components/04_pages/book/02_GenericTripsListPage';
export { default as GenericTripsDetailsPage } from './components/04_pages/book/03_GenericTripsDetailsPage';
export { default as GenericTripSeatSelectionPage } from './components/04_pages/book/03b_GenericTripSeatSelectionPage';
export { default as GenericStopsPage } from './components/04_pages/book/03c_GenericStopsPage';
export { default as PassengerDetailsPage } from './components/04_pages/book/04_PassengerDetailsPage';
export { default as PassengerDetailsAddPage } from './components/04_pages/book/04_PassengerDetailsAddPage';
export { default as BillingDetailsPage } from './components/04_pages/book/05_BillingDetailsPage';
export { default as CovidInfoPage } from './components/04_pages/book/06_CovidInfoPage';
export { default as OrderSetupPage } from './components/04_pages/book/07_OrderSetupPage';
export { default as PaymentPage } from './components/04_pages/book/08_PaymentPage';
export { default as ProcessingPaymentPage } from './components/04_pages/book/08b_ProcessingPaymentPage';
export { default as BookingErrorPage } from './components/04_pages/book/09_BookingErrorPage';
export { default as BookingConfirmedPage } from './components/04_pages/book/10_BookingConfirmedPage';
export { default as EmptyOrdersPage } from './components/04_pages/EmptyOrdersPage';
export { default as OrdersPage } from './components/04_pages/OrdersPage';
export { default as TicketPage } from './components/04_pages/TicketPage';
export { default as TicketBoardingPassPage } from './components/04_pages/TicketBoardingPassPage';
export { default as TicketNotFoundPage } from './components/04_pages/TicketNotFoundPage';
export { default as WelcomePage } from './components/04_pages/WelcomePage';
export { default as LoginByPhoneFirstPage } from './components/04_pages/login/by-phone/LoginByPhoneFirstPage';
export { default as LoginByPhoneSecondPage } from './components/04_pages/login/by-phone/LoginByPhoneSecondPage';
export { default as LoginByPhoneThirdPage } from './components/04_pages/login/by-phone/LoginByPhoneThirdPage';
export { default as ForgotPasswordLinkSentPage } from './components/04_pages/login/ForgotPasswordLinkSentPage';
export { default as ForgotPasswordPage } from './components/04_pages/login/ForgotPasswordPage';
export { default as LoginPage } from './components/04_pages/login/LoginPage';
export { default as LoggingInPage } from './components/04_pages/login/LoggingInPage';
export { default as SignUpPage } from './components/04_pages/login/SigningUpPage';
export { default as SignUpOkPage } from './components/04_pages/login/SignUpOkPage';
export { default as SettingsPage } from './components/04_pages/settings/SettingsPage';
export { default as LanguageSettingsPage } from './components/04_pages/settings/LanguageSettingsPage';
export { default as EditPassengersPage } from './components/04_pages/settings/EditPassengersPage';
export { default as EditPassengerPopup } from './components/04_pages/settings/EditPassengerPopup';
export { default as AddPassengerPopup } from './components/04_pages/settings/AddPassengerPopup';
export { default as BetaOnboardingCarousselPage } from './components/04_pages/onboarding/BetaOnboardingCarousselPage';
export { default as BetaOnboardingWaitingPage } from './components/04_pages/onboarding/BetaOnboardingWaitingPage';

// //////////////////////////////////////////////////////////////////////////////

export const assets = {
  boy,
  girl,
  loadingImage,
  tick,
  bookingPending,
  tire,
};

// Export router to avoid getting false errors 'You should not use <Link> outside a <Router>'
const isNative = () => window && window.ReactNativeWebView;
export const Router = isNative() ? HashRouter : BrowserRouter;
