import React from 'react';

import { discreetTextColor } from '../00_environment/Colors';
import Button from '../01_atoms/Button';
import Element from '../01_atoms/Element';
import ElementGroup from '../01_atoms/ElementGroup';

const BillingDetails = ({
  sdk,
  title,
  subtitle,
  elementStyle,
  onEdit,
  onEditLink,
  highlightEditButton = false,
  translator: t = (txt) => txt,
} = {}) => (
  <ElementGroup title={title} subtitle={subtitle}>
    <Element style={elementStyle}>
      {sdk.billingDetails.areBillingDetailsValid() ? (
        <div>
          <div style={{ fontWeight: 'bold', paddingRight: 10 }}>{t('Billing this trip to: ')}</div>
          <div style={{ textAlign: 'justify' }}>
            {`${sdk.billingDetails.getBillingDetails().firstName} ${
              sdk.billingDetails.getBillingDetails().lastName
            }`}
            <br />
            {sdk.types.addressToString(sdk.billingDetails.getBillingDetails().address)}
          </div>
        </div>
      ) : (
        <div style={{ color: discreetTextColor }}>{t('Please enter your billing details.')}</div>
      )}

      {(onEditLink || onEdit) && (
        <Button
          id="edit-billing-details-button"
          to={onEditLink}
          onClick={onEdit}
          fluid
          primary={highlightEditButton}
          style={{ marginTop: 20 }}
        >
          {t('Edit billing information')}
        </Button>
      )}
    </Element>
  </ElementGroup>
);

export default BillingDetails;
