import React from 'react';

const PaymentMethodCard = ({ text, icons = [], highlighted = false }) => (
  <div
    style={{
      backgroundColor: highlighted ? '#ffffff3f' : 'none',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
      width: '100%',
      minHeight: 50,
      padding: '12px 30px',
    }}
  >
    <div
      style={{
        fontSize: '1rem',
        flex: '1',
        marginRight: 30,
        fontWeight: 600,
      }}
    >
      {text}
    </div>
    {icons}
  </div>
);

export default PaymentMethodCard;
