import React from 'react';
import { Responsive } from 'semantic-ui-react';
import LeftPaneWrapper from './LeftPaneWrapper';

const CentralPaneWrapper = ({ isMobile = false, children }) => (
  <div
    style={{
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: isMobile ? 0 : '5vh',
      width: isMobile ? '100%' : '80%',
      maxWidth: Responsive.onlyTablet.maxWidth,
      height: isMobile ? '100%' : undefined,
    }}
  >
    <LeftPaneWrapper fullHeight={isMobile} noSidePadding={isMobile}>
      {children}
    </LeftPaneWrapper>
  </div>
);

export default CentralPaneWrapper;
