import React from 'react';

import RightPaneWidgetFrame from '../01_atoms/RightPaneWidgetFrame';
import PassengerSelectionWidget from './PassengerSelectionWidget';

const PassengersSelectionRightPane = ({ scrollTop = 0, focusedLeftItemId = null, ...props }) => {
  const {
    sdk,
    savedPassengers,
    selectedPassengers,
    numberOfPassengers,
    onPassengerToggle,
    onAddPassenger = () => {},
    onConfirmPassengers: onConfirm = () => {},
  } = props;
  const {
    i18n: { translatorForNamespace },
  } = sdk;
  const t = translatorForNamespace('PassengersSelectionUI');
  const title = t('Passengers in this trip');

  let subtitle = (
    <span>
      {t('We need to know the names of the passengers on this trip')}
      <br />
      {t('Please insert their details below')}
    </span>
  );

  if (savedPassengers.length > 0) {
    subtitle = (
      <span>
        {t('Use this screen to indicate who will be on this trip.')}
        <br />
        {t('{XofY} passengers confirmed', {
          XofY: `${selectedPassengers.length}/${numberOfPassengers}`,
        })}
      </span>
    );
  }

  return (
    <RightPaneWidgetFrame scrollTop={scrollTop} focusedLeftItemId={focusedLeftItemId}>
      <PassengerSelectionWidget
        {...{
          title,
          subtitle,
          translatorForNamespace,

          savedPassengers,
          selectedPassengers,
          numberOfPassengers,

          onPassengerToggle,
          onAddPassenger,
          onConfirm,

          translator: t,
        }}
      />
    </RightPaneWidgetFrame>
  );
};

export default PassengersSelectionRightPane;
