import React from 'react';

import Button from '../../01_atoms/Button';
import Bottom from '../../01_atoms/Bottom';
import Container from '../../01_atoms/Container';
import Element from '../../01_atoms/Element';
import ElementGroup from '../../01_atoms/ElementGroup';
import LoadingPlaceHolder from '../../01_atoms/LoadingPlaceHolder';
import Spacer from '../../01_atoms/Spacer';
import PopupScreen from '../../02_molecules/PopupScreen';

const GenericStopsPageUI = ({
  loading,
  ticket,
  stops,
  onBack = () => {},
  translatorForNamespace,
}) => {
  const t = translatorForNamespace('GenericStopsPageUI');
  let content = null;

  if (loading) {
    content = <LoadingPlaceHolder />;
  } else {
    stops.sort((a, b) => a.sortOrder - b.sortOrder);

    content = (
      <ElementGroup
        title={t('Route used by this bus')}
        subtitle={t(
          "This bus passes through all these cities. It doesn't necessarily stops there.",
        )}
      >
        {stops.map((s) => (
          <Element key={`ticket-stop_${ticket.id}_${s.name}`}>{s.name}</Element>
        ))}
      </ElementGroup>
    );
  }

  return (
    <PopupScreen title={t('Bus route')} onBack={onBack}>
      {content}
      <Spacer />
      <Bottom>
        <Container>
          <Button onClick={onBack} fluid>
            {t('Back')}
          </Button>
        </Container>
      </Bottom>
    </PopupScreen>
  );
};

export default GenericStopsPageUI;
