import React from 'react';
import { Form } from 'semantic-ui-react';

import { withDefaultFont } from '../00_environment/Fonts';
import { COUNTRY_OPTIONS as countryOptions } from '../00_environment/countriesData';

const AddressFormComponent = ({
  title,
  style = {},
  address: inputAddress = {},
  onChange = () => {},
  translatorForNamespace,
}) => {
  const t = translatorForNamespace('AddressForm');

  // Always use Ecuador as default country
  const address = { ...inputAddress };
  if (!address.country) {
    address.country = 'ec';
  }

  return (
    <Form style={style || {}}>
      <Form.Field style={{}}>
        <label style={withDefaultFont({})}>{title}</label>
        <Form.Input
          fluid
          placeholder={t('Address line #1')}
          value={address.line1 || ''}
          onChange={(e, o) => onChange({ ...address, line1: o.value })}
          style={{ marginBottom: 0 }}
        />
        <Form.Input
          fluid
          placeholder={t('Address line #2')}
          value={address.line2 || ''}
          onChange={(e, o) => onChange({ ...address, line2: o.value })}
          style={{
            marginTop: 0,
          }}
        />
        <Form.Input
          fluid
          placeholder={t('City')}
          value={address.city || ''}
          onChange={(e, o) => onChange({ ...address, city: o.value })}
          style={{
            marginTop: 0,
          }}
        />
        <Form.Input
          fluid
          placeholder={t('Zip code')}
          value={address.zip || ''}
          onChange={(e, o) => onChange({ ...address, zip: o.value })}
          style={{
            marginTop: 0,
          }}
        />
        <Form.Dropdown
          placeholder={t('Country')}
          fluid
          search
          selection
          options={countryOptions}
          value={address.country || 'ec'}
          onChange={(e, o) => onChange({ ...address, country: o.value })}
          style={{
            marginTop: 10,
            marginBottom: 10,
          }}
        />
      </Form.Field>
    </Form>
  );
};

export default AddressFormComponent;
