import '../../fonts/open-sans/fonts.css';
import {
  primaryColor,
  textColor,
  discreetTextColor,
  dangerColor,
  blackTextColor,
  blueLabelFontColor,
} from './Colors';

export const withDefaultFont = (style) => ({
  fontFamily: "'Open Sans',Helvetica,Arial,sans-serif",
  fontWeight: 'normal',
  color: blackTextColor,
  fontSize: '1rem',
  lineHeight: '1.4',
  ...style,
});

export const withFormLabelFont = (style) => Object.assign(
  withDefaultFont({
    fontWeight: '300',
    color: discreetTextColor,
  }),
  style,
);

export const withTopBarFont = (style) => Object.assign(
  withDefaultFont({
    fontWeight: 'bold',
    fontSize: '1.2rem',
    lineHeight: '1.2',
  }),
  style,
);

export const withWidgetTitleFont = (style) => Object.assign(
  withDefaultFont({
    fontSize: '1.1rem',
  }),
  style,
);

export const withBoldTitleFont = (style) => Object.assign(
  withDefaultFont({
    fontSize: '1.2rem',
    fontWeight: 'bold',
  }),
  style,
);

export const withWidgetSubtitleFont = (style) => Object.assign(
  withDefaultFont({
    fontSize: '0.8rem',
    lineHeight: '1.4',
    color: discreetTextColor,
    textTransform: 'none',
  }),
  style,
);

export const withPlaceholderFont = (style) => Object.assign(
  withDefaultFont({
    fontSize: '0.9rem',
    lineHeight: '1.4',
    color: discreetTextColor,
    textTransform: 'none',
  }),
  style,
);

export const withDividerFont = (style) => Object.assign(
  withDefaultFont({
    fontSize: '0.9rem',
    color: discreetTextColor,
    textTransform: 'uppercase',
  }),
  style,
);

export const withLinkButtonFont = (style) => Object.assign(
  withDefaultFont({
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '1.2',
    color: primaryColor,
    textTransform: 'uppercase',
  }),
  style,
);

export const withDiscreetLinkButtonFont = (style) => Object.assign(
  withDefaultFont({
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.2',
    color: discreetTextColor,
    textTransform: 'uppercase',
  }),
  style,
);

export const withDangerLinkButtonFont = (style) => Object.assign(
  withDefaultFont({
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '1.2',
    color: dangerColor,
    textTransform: 'uppercase',
  }),
  style,
);

export const withDefaultButtonFont = (style) => Object.assign(
  withDefaultFont({
    fontWeight: 600,
    color: blackTextColor,
    fontSize: '1.1rem',
  }),
  style,
);

export const withBoldFont = (style) => Object.assign(
  withDefaultFont({
    fontWeight: 600,
    color: blackTextColor,
  }),
  style,
);

export const withEmphasisFont = (style) => Object.assign(
  withBoldFont({
    fontWeight: 600,
    color: primaryColor,
    fontSize: '1.15rem',
  }),
  style,
);

export const withSubtitleFont = (style) => Object.assign(
  withDefaultFont({
    fontWeight: '600',
    color: discreetTextColor,
    fontSize: '0.85rem',
  }),
  style,
);

export const withBlueLabelFont = (style) => Object.assign(
  withDefaultFont({
    fontWeight: '600',
    color: blueLabelFontColor,
    fontSize: '0.85rem',
  }),
  style,
);

export const withLightLabelFont = (style) => Object.assign(
  withDefaultFont({
    fontWeight: 'normal',
    color: discreetTextColor,
    fontSize: '0.68rem',
    textTransform: 'uppercase',
  }),
  style,
);

export const withPrimaryButtonFont = (style) => Object.assign(
  withDefaultButtonFont({
    color: 'white',
  }),
  style,
);

export const withPaneTitleFont = (style) => Object.assign(
  withBoldTitleFont({
    fontSize: '1.4rem',
    fontWeight: '700',
    whiteSpace: 'nowrap',
  }),
  style,
);

export const withBlockHeaderRibbonFont = (style) => Object.assign(
  withBoldTitleFont({
    fontSize: '1rem',
    fontWeight: '400',
    whiteSpace: 'nowrap',
    color: 'white',
  }),
  style,
);
