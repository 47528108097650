import React from 'react';

import PopupScreen from '../../02_molecules/PopupScreen';
import AddPassengerWidget from '../../02_molecules/AddPassengerWidget';

const MobileAddPassengerPopup = ({
  sdk,
  onAdd = () => {},
  onBack = () => {},
  translator: t,
  translatorForNamespace,
}) => (
  <PopupScreen title={t('Add passenger')} onBack={onBack}>
    <AddPassengerWidget
      {...{
        sdk,
        onAdd,
        onBack,
        translatorForNamespace,
      }}
      showAllFields={false}
    />
  </PopupScreen>
);
export default MobileAddPassengerPopup;
