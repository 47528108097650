import React from 'react';

import {
  seatMatrixDefaultSelectedColor,
  seatMatrixDefaultFreeColor,
  seatMatrixAlternateColors,
} from '../00_environment/Colors';

import LoadingPlaceHolder from '../01_atoms/LoadingPlaceHolder';
import SeatMatrixFloorSelector from './SeatMatrixFloorSelector';
import SeatMatrix from './SeatMatrix';
import SeatMatrixLegend from './SeatMatrixLegend';

const SeatSelectionWidget = ({
  numberOfFloors,
  selectedFloor,
  onFloorChange,
  seatInfoByPrice,
  seatMatrix,
  selectedSeats,
  seatsPendingHold,
  seatsPendingRelease,
  onSeatToggle,
  numberOfSeatsToSelect,
  seatPrices,
  translator: t,
  loading = true,
}) => {
  if (loading) {
    return (
      <div
        style={{
          minHeight: '50vh',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <LoadingPlaceHolder />
      </div>
    );
  }

  const simplifiedInfoByPrice = (priceInCents) => seatInfoByPrice(priceInCents, {
    seatMatrixDefaultSelectedColor,
    seatMatrixDefaultFreeColor,
    seatMatrixAlternateColors,
  });

  return (
    <div
      style={{
        flex: '1',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {numberOfFloors > 1 && (
        <SeatMatrixFloorSelector
          translator={t}
          numberOfFloors={numberOfFloors}
          selectedFloor={selectedFloor}
          onFloorChange={onFloorChange}
        />
      )}

      <SeatMatrix
        seatInfoByPrice={simplifiedInfoByPrice}
        seatMatrix={seatMatrix}
        selectedSeats={selectedSeats}
        seatsPendingHold={seatsPendingHold}
        seatsPendingRelease={seatsPendingRelease}
        onSeatToggle={onSeatToggle}
        numberOfSeatsToSelect={numberOfSeatsToSelect}
        translator={t}
      />

      <SeatMatrixLegend seatPrices={seatPrices} seatInfoByPrice={simplifiedInfoByPrice} />
    </div>
  );
};

export default SeatSelectionWidget;
