import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import check from 'check-types';
import darkorlight from 'darkorlight';
import { Icon, Responsive } from 'semantic-ui-react';

import {
  topBarBgColor,
  topBarSubBgColor,
  textColor,
  onDarkTextColor,
  topBarIdleTabColor,
} from '../00_environment/Colors';
import { withTopBarFont, withDefaultFont } from '../00_environment/Fonts';
import { displayTopBar } from '../00_environment/Constants';
import Top from './Top';

const color = darkorlight.dark(topBarBgColor) ? onDarkTextColor : textColor;
const subColor = darkorlight.dark(topBarSubBgColor) ? onDarkTextColor : textColor;

const LegacyTopBar = ({
  title,
  leftIcon = null,
  onLeftIconClick = () => {},
  rightIcon = null,
  rightIconLink = null,
  children = null,
  rightChildren = null,
  tabs = null,
  selectedTab = null,
}) => {
  const content = (
    <>
      <div
        style={withTopBarFont({
          backgroundColor: topBarBgColor,
          color,
          margin: 0,
          flex: '1',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'left',
          alignItems: 'center',
          padding: 0,
          position: 'relative',
          border: 'none',
        })}
      >
        {leftIcon && (
          <div style={{ cursor: 'pointer', margin: 15, marginRight: 0 }} onClick={onLeftIconClick}>
            <Icon name={leftIcon} />
          </div>
        )}

        {title && <div style={{ margin: 15, marginLeft: 10 }}>{title}</div>}
        {check.nonEmptyArray(tabs) && (
          <div
            style={withTopBarFont({
              margin: 0,
              padding: 0,
              height: '100%',
              position: 'relative',
              display: 'flex',
              flexDirection: 'row',
            })}
          >
            {tabs.map((t) => {
              if (
                !check.nonEmptyString(t.link)
                || (!check.nonEmptyString(t.name) && !check.nonEmptyString(t.icon))
              ) {
                return null;
              }

              const selected = t.name === selectedTab;

              let LinkComp = Link;
              let linkProps = { to: t.link };

              if (t.link.indexOf('http') === 0) {
                LinkComp = 'a';
                linkProps = { href: t.link };
              }

              return (
                <div
                  key={`tab:${t.name}`}
                  style={withTopBarFont({
                    height: '100%',
                    borderBottom: selected ? `solid 2px ${color}` : 'none',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 15,
                  })}
                >
                  <LinkComp
                    {...linkProps}
                    style={{ color: selected ? color : topBarIdleTabColor, textDecoration: 'none' }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Icon name={t.icon} />
                      {(check.nonEmptyString(t.displayName) || check.nonEmptyString(t.name)) && (
                        <Responsive as="div" minWidth={768} style={{ marginLeft: 10 }}>
                          {t.displayName || t.name}
                        </Responsive>
                      )}
                    </div>
                  </LinkComp>
                </div>
              );
            })}
          </div>
        )}

        {rightChildren && (
          <div
            style={{
              marginLeft: 'auto',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
              ...(rightChildren ? {} : { marginLeft: 'auto' }),
            }}
          >
            {rightChildren}
          </div>
        )}
        {rightIcon && rightIconLink && (
          <div
            style={{
              cursor: 'pointer',
              padding: 5,
              /* paddingRight: 0, */
              ...(rightChildren ? {} : { marginLeft: 'auto' }),
            }}
          >
            <Link to={rightIconLink} style={withTopBarFont({ color, textDecoration: 'none' })}>
              <Icon name={rightIcon} />
            </Link>
          </div>
        )}
      </div>

      {children && (
        <div
          style={withDefaultFont({
            backgroundColor: topBarSubBgColor,
            flex: '1',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
            alignItems: 'center',
            padding: 15,
            color: subColor,
            margin: 0,
          })}
        >
          {children}
        </div>
      )}
    </>
  );

  return (
    <>
      <Top>{content}</Top>
      <div style={{ visibility: 'hidden' }}>{content}</div>
    </>
  );
};

const TopBar = displayTopBar ? LegacyTopBar : ({ children }) => <>{children}</>;

export default TopBar;
