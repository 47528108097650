import React from 'react';
import check from 'check-types';
import roundTo from 'round-to';

import { withPlaceholderFont } from '../00_environment/Fonts';

// Legend to display info about seat pricing when there are multiple seat pricings available
const SeatMatrixLegend = ({ seatPrices, seatInfoByPrice }) => {
  // If there's one and only one pricing, don't display anything
  if (!check.nonEmptyArray(seatPrices) || seatPrices.length === 1) {
    return null;
  }

  // Else, build a legend
  // Note: seatPrices is already sorted by price
  const additionalPrices = Array.from(seatPrices);
  additionalPrices.shift();

  const legendElems = additionalPrices.map((priceInCents) => {
    const { freeColor: bgColor, priceDelta } = seatInfoByPrice(priceInCents);

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          marginRight: 10,
        }}
      >
        <div
          key={`seat-legend_${priceInCents}`}
          style={{
            width: 20,
            height: 20,
            borderRadius: 5,
            backgroundColor: bgColor,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'gray',
            margin: 5,
          }}
        />
        <div style={{}}>
          <span style={{ fontWeight: 600 }}>{`+$${roundTo(priceDelta / 100, 2)}`}</span>
        </div>
      </div>
    );
  });

  return (
    <div
      style={withPlaceholderFont({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 15,
        // marginLeft: 20,
        backgroundColor: 'rgba(33, 48, 77, 0.03)',
        borderRadius: 10,
        padding: 7,
        paddingTop: 3,
        paddingBottom: 3,
        // paddingRight: 15,
      })}
    >
      {legendElems}
    </div>
  );
};

export default SeatMatrixLegend;
