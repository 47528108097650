import React from 'react';

const Container = ({ noSidePadding = false, style, children }) => (
  <div
    className="container"
    style={{
      width: '100%',
      paddingLeft: noSidePadding ? 0 : 25,
      paddingRight: noSidePadding ? 0 : 25,
      ...style,
    }}
  >
    {children}
  </div>
);

export default Container;
