import React from 'react';

import ResponsivePage from '../../01_atoms/ResponsivePage';

import SinglePaneGenericTripsDetailsPage from './03_GenericTripsDetailsPage.mobile';
import DualPaneGenericTripsDetailsPage from './03_GenericTripsDetailsPage.widescreen';

const GenericTripsDetailsPage = ({ ...props }) => (
  <ResponsivePage
    wideScreenComponent={DualPaneGenericTripsDetailsPage}
    mobileComponent={SinglePaneGenericTripsDetailsPage}
    {...props}
  />
);

export default GenericTripsDetailsPage;
