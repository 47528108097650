import React from 'react';

import { withLightLabelFont } from '../00_environment/Fonts';
import { widgetBorderColor } from '../00_environment/Colors';

const LightLabel = ({ style = {}, children }) => (
  <div
    style={withLightLabelFont({
      ...style,
      border: `solid 1px ${widgetBorderColor}`,
      padding: 6,
      paddingTop: 4,
      paddingBottom: 4,
    })}
  >
    {children}
  </div>
);

export default LightLabel;
