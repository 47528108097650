import React from 'react';

import DefaultScreen from '../../02_molecules/DefaultScreen';
import DateSelectionComponent from '../../02_molecules/DateSelectionComponent';

const DateSelectionPopupUI = ({
  title,
  date,
  minDate,
  maxDate,
  onChange = () => {},
  onBack = () => {},
  translatorForNamespace,
  translationManager,
}) => {
  const t = translatorForNamespace('DateSelectionPopupUI');

  return (
    <DefaultScreen title={title} onBack={onBack}>
      <DateSelectionComponent
        {...{
          title: t('Select a date'),
          minDay: minDate,
          maxDay: maxDate,
          selectedDay: date,
          onChange,
          translationManager,
        }}
      />
    </DefaultScreen>
  );
};

export default DateSelectionPopupUI;
