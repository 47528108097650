import React, { Fragment } from 'react';
import { Button as SemanticButton } from 'semantic-ui-react';

const SeatMatrixFloorSelector = ({
  numberOfFloors = 1,
  selectedFloor = 0,
  onFloorChange = () => {},
  translator: t,
}) => {
  const floors = [];
  for (let i = 0; i < numberOfFloors; i += 1) {
    floors.push({
      index: i,
      id: i + 1,
      onClick: () => onFloorChange(i),
    });
  }

  return (
    <SemanticButton.Group style={{ marginBottom: 10 }}>
      {floors.map((f) => (
        <Fragment key={`floor_${f.id}`}>
          <SemanticButton onClick={f.onClick} active={f.index === selectedFloor}>
            {t('Floor ') + f.id}
          </SemanticButton>
          {/* i < floors.length - 1 && <SemanticButton.Or /> */}
        </Fragment>
      ))}
    </SemanticButton.Group>
  );
};

export default SeatMatrixFloorSelector;
