import React from 'react';

import ResponsivePage from '../../01_atoms/ResponsivePage';

import SinglePaneOrderSetupPage from './07_OrderSetupPage.mobile';
import DualPaneOrderSetupPage from './07_OrderSetupPage.widescreen';

const OrderSetupPage = ({ ...props }) => (
  <ResponsivePage
    wideScreenComponent={DualPaneOrderSetupPage}
    mobileComponent={SinglePaneOrderSetupPage}
    {...props}
  />
);

export default OrderSetupPage;
