import React from 'react';
import { ucfirstEachWord } from '@seafront/ucfirst';

import Icon from '../01_atoms/Icon';
import DefaultScreen from '../02_molecules/DefaultScreen';
import TicketWidget from '../02_molecules/TicketWidget';

const MobileTicketPage = ({
  ticket,
  translationManager,
  translatorForNamespace,
  onBack = () => {},
}) => {
  const { ticketDetails } = ticket;
  const dt = ucfirstEachWord(ticketDetails.originTerminal.name);
  const at = ucfirstEachWord(ticketDetails.destinationTerminal.name);
  const title = (
    <div style={{ display: 'inline', marginLeft: 5 }}>
      {dt}
      <Icon name="arrow right" style={{ display: 'inline', marginLeft: 5 }} />
      {at}
    </div>
  );

  return (
    <DefaultScreen title={title} onBack={onBack}>
      <TicketWidget
        ticket={ticket}
        translationManager={translationManager}
        translatorForNamespace={translatorForNamespace}
      />
    </DefaultScreen>
  );
};

export default MobileTicketPage;
