import React from 'react';

import { topBarBgColor } from '../00_environment/Colors';
import { withTopBarFont, withBlockHeaderRibbonFont } from '../00_environment/Fonts';
import Button from './Button';

const BlockWithHeader = ({
  ribbonContent,
  headerContent,
  buttonText = null,
  buttonSubText = null,
  onButtonClick = () => {},
  children,
}) => (
  <div
    style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'white',
    }}
  >
    <div
      style={withTopBarFont({
        padding: '24px 30px',
        backgroundColor: topBarBgColor,
        color: 'white',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
      })}
    >
      {ribbonContent && (
        <div
          style={withBlockHeaderRibbonFont({
            textAlign: 'center',
            marginTop: -4,
            marginBottom: 18,
          })}
        >
          {ribbonContent}
        </div>
      )}
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: buttonText ? 'space-between' : 'center',
          alignItems: 'flex-start',
        }}
      >
        {headerContent}
        {buttonText && (
          <Button
            style={withTopBarFont({
              color: 'white',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              textAlign: 'left',
            })}
            primary
            onClick={onButtonClick}
          >
            <div>{buttonText}</div>
            {buttonSubText && (
              <div style={{ fontSize: '0.9rem', fontWeight: 'normal' }}>{buttonSubText}</div>
            )}
          </Button>
        )}
      </div>
    </div>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 30,
      }}
    >
      {children}
    </div>
  </div>
);

export default BlockWithHeader;
