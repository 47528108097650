import React from 'react';
import { Link } from 'react-router-dom';
import { Button as NativeButton } from 'semantic-ui-react';

import { primaryColor, defaultButtonColor, onDarkTextColor } from '../00_environment/Colors';
import {
  withDefaultFont,
  withDefaultButtonFont,
  withPrimaryButtonFont,
} from '../00_environment/Fonts';
import { roundedElementBorderRadius } from '../00_environment/Constants';

const Button = ({
  primary, style, to, color, onClick, children, ...rest
}) => {
  const basicStyle = {
    borderRadius: roundedElementBorderRadius,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 46,
    backgroundColor: defaultButtonColor,
  };

  let defaultStyle = withDefaultButtonFont(basicStyle);
  if (primary) {
    defaultStyle = withPrimaryButtonFont({ ...basicStyle, backgroundColor: primaryColor });
  } else if (color && color.indexOf('#') === 0) {
    defaultStyle.backgroundColor = color;
  }

  let as;
  if (to && !onClick) {
    as = Link;
  }

  const buttonContent = (
    <NativeButton
      as={as}
      to={to}
      color={color && color.indexOf('#') === -1 ? color : null}
      style={Object.assign(defaultStyle, style)}
      onClick={onClick}
      {...rest}
    >
      {children}
    </NativeButton>
  );
  /*
  if (to && !onClick) {

    buttonContent = <Link to={to}>{buttonContent}</Link>;
  } */

  return buttonContent;
};

export default Button;
