import React from 'react';
import check from 'check-types';
import { Image, Message } from 'semantic-ui-react';

import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import { primaryColor, discreetTextColor, okColor } from '../00_environment/Colors';
import Button from '../01_atoms/Button';
import Icon from '../01_atoms/Icon';
import ElementGroup from '../01_atoms/ElementGroup';
import SquaredText from '../01_atoms/SquaredText';
import KeyValueElement from '../01_atoms/KeyValueElement';
import seat from '../../assets/seat.svg';

const SeatsValidationMark = ({ valid }) => {
  if (!check.boolean(valid)) {
    return (
      <Loader
        type="Puff"
        color={discreetTextColor}
        height={16}
        width={16}
        style={{ marginLeft: 7, display: 'inline', lineHeight: '1rem' }}
      />
    );
  }
  if (valid) {
    return (
      <Icon
        style={{
          color: okColor,
          marginLeft: 7,
          width: 10,
          marginBottom: 2,
        }}
        name="check"
      />
    );
  }

  return (
    <Icon
      style={{
        color: primaryColor,
        marginLeft: 7,
        display: 'inline-block',
        // lineHeight: 16,
        width: 16,
        height: 16,
      }}
      name="close"
    />
  );
};

const TripSeatDetailsTwoWays = ({
  sdk,
  hasReturn = false,
  outboundSeats = [],
  returnSeats = [],
  onEditOutbound = () => {},
  onEditReturn = () => {},
  translator: t,
  style = {},
  id,
}) => {
  const outboundWithErrors = sdk.seats.selectedSeatsWithErrors(false);
  const returnWithErrors = sdk.seats.selectedSeatsWithErrors(true);

  console.log('outboundWithErrors = ', outboundWithErrors);
  console.log('returnWithErrors = ', returnWithErrors);

  const outboundSeatsValid = outboundWithErrors ? check.emptyArray(outboundWithErrors) : null;
  const returnSeatsValid = returnWithErrors ? check.emptyArray(returnWithErrors) : null;

  const invalidSquareStyle = { borderColor: primaryColor, color: primaryColor };

  return (
    <ElementGroup id={id} style={style} title={t('Selected seats')}>
      <KeyValueElement
        header={
          (outboundSeatsValid === false || returnSeatsValid === false) && (
            <Message negative>
              <Message.Header>
                {t('Oh no!')}
                <br />
                {t('These seats are no more available.')}
              </Message.Header>
              <p>{t('Please select other seats to continue.')}</p>
              <Button primary fluid onClick={outboundSeatsValid ? onEditReturn : onEditOutbound}>
                {t('Select other seats')}
              </Button>
            </Message>
          )
        }
        title={(
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <div style={{ textAlign: 'left', display: 'inline-block', width: 30 }}>
              <Image src={seat} style={{ display: 'inline-block', height: '22px' }} />
            </div>
            {' '}
            {t('Outbound')}
            {' '}
            <SeatsValidationMark valid={outboundSeatsValid} />
          </div>
        )}
        value={(
          <>
            {outboundSeats.map((s) => (
              <SquaredText
                key={`placement_out:${s}`}
                style={{
                  width: 24,
                  height: 24,
                  ...(check.array(outboundWithErrors) && outboundWithErrors.includes(s)
                    ? invalidSquareStyle
                    : {}),
                }}
              >
                {s}
              </SquaredText>
            ))}
            <Icon
              style={{ cursor: 'pointer', color: discreetTextColor, marginLeft: 12 }}
              name="pencil"
              onClick={onEditOutbound}
            />
          </>
        )}
      />
      {hasReturn && (
        <KeyValueElement
          title={(
            <span>
              <div style={{ textAlign: 'left', display: 'inline-block', width: 30 }}>
                <Image src={seat} style={{ display: 'inline-block', height: '22px' }} />
              </div>
              {' '}
              {t('Return')}
            </span>
          )}
          value={(
            <>
              <SeatsValidationMark valid={returnSeatsValid} />
              {returnSeats.map((s) => (
                <SquaredText
                  key={`placement_return:${s}`}
                  style={{
                    width: 24,
                    height: 24,
                    ...(check.array(returnWithErrors) && returnWithErrors.includes(s)
                      ? invalidSquareStyle
                      : {}),
                  }}
                >
                  {s}
                </SquaredText>
              ))}
              <Icon
                style={{ cursor: 'pointer', color: discreetTextColor, marginLeft: 12 }}
                name="pencil"
                onClick={onEditReturn}
              />
            </>
          )}
        />
      )}
    </ElementGroup>
  );
};
export default TripSeatDetailsTwoWays;
