import React from 'react';
import Element from './Element';
import { withPlaceholderFont } from '../00_environment/Fonts';

const PlaceHolderElement = ({ children, style = {}, ...rest }) => (
  <Element {...rest} style={Object.assign(withPlaceholderFont({}), style)}>
    <span style={{ marginLeft: 10 }}>{children}</span>
  </Element>
);

export default PlaceHolderElement;
