import React from 'react';
import { Menu } from 'semantic-ui-react';
import { widgetBorderColor, widgetBackgroundColor } from '../00_environment/Colors';
import { withDefaultFont } from '../00_environment/Fonts';
import { roundedElementBorderRadius } from '../00_environment/Constants';
import TitleBlock from './TitleBlock';

const ElementGroup = ({
  title,
  subtitle,
  children,
  slim = false,
  id,
  style,
  backgroundColor = null,
  titlePadding = null,
}) => {
  const parsedStyle = withDefaultFont({
    border: `solid 1.4px ${widgetBorderColor}`,
    borderRadius: roundedElementBorderRadius,
    boxShadow: 'none',
    backgroundColor: backgroundColor || widgetBackgroundColor,
    marginBottom: '3px',
    marginTop: 6,
  });

  if (slim) {
    Object.assign(parsedStyle, {
      marginBottom: 0,
      marginTop: 0,
    });
  }

  return (
    <TitleBlock
      id={id}
      title={title}
      subtitle={subtitle}
      slim={slim}
      style={style}
      padding={titlePadding}
    >
      <Menu fluid vertical style={parsedStyle}>
        {children}
      </Menu>
    </TitleBlock>
  );
};

export default ElementGroup;
