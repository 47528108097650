import React from 'react';
import { Header, Icon } from 'semantic-ui-react';

import { withWidgetTitleFont } from '../../00_environment/Fonts';
import Button from '../../01_atoms/Button';
import FormContainer from '../../01_atoms/FormContainer';
import DefaultScreen from '../../02_molecules/DefaultScreen';
import TitledContainer from '../../01_atoms/TitledContainer';
import ResponsiveCentralPaneWrapper from '../../02_molecules/ResponsiveCentralPaneWrapper';

const ForgotPasswordLinkSentPageUI = ({ translatorForNamespace, onBack = () => {} }) => {
  const t = translatorForNamespace('ForgotPasswordLinkSentPageUI');

  return (
    <DefaultScreen title={t('Reset your password')} onBack={onBack}>
      <ResponsiveCentralPaneWrapper>
        <TitledContainer
          title={t('Reset your password')}
          rightButtonText={t('Back')}
          onRightButtonClick={onBack}
        >
          <FormContainer>
            <div
              style={{
                flex: '1',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Header icon style={withWidgetTitleFont({ marginTop: '5%', marginBottom: '5%' })}>
                <Icon name="check" />
                {t('Done.')}
                <br />
                {t("You'll receive a link shortly.")}
              </Header>
              <Button onClick={onBack} fluid primary>
                {t('Back')}
              </Button>
            </div>
          </FormContainer>
        </TitledContainer>
      </ResponsiveCentralPaneWrapper>
    </DefaultScreen>
  );
};
export default ForgotPasswordLinkSentPageUI;
