import React from 'react';
import { ridesListStyle } from '../00_environment/Styles';
import TitledContainer from '../01_atoms/TitledContainer';
import RidesListItem from './RidesListItem';

const RidesList = ({
  title,
  rides,
  numberOfPassengers = 1,
  onRideClick = () => {},
  onBack = () => {},
  translator: t,
}) => (
  <TitledContainer title={title} rightButtonText={t('Change')} onRightButtonClick={onBack}>
    <div style={ridesListStyle}>
      {rides.map((r) => (
        <RidesListItem
          id={`ride_${r.id}`}
          key={`ride_${r.id}`}
          ride={r}
          numberOfPassengers={numberOfPassengers}
          onRideClick={onRideClick}
        />
      ))}
    </div>
  </TitledContainer>
);

export default RidesList;
