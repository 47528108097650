import React from 'react';
import { Image } from 'semantic-ui-react';

import { withDefaultFont } from '../../00_environment/Fonts';
import Button from '../../01_atoms/Button';
import Bottom from '../../01_atoms/Bottom';
import Container from '../../01_atoms/Container';
import Divider from '../../01_atoms/Divider';
import LinkButton from '../../01_atoms/LinkButton';
import PaymentIcon from '../../01_atoms/PaymentIcon';
import DefaultScreen from '../../02_molecules/DefaultScreen';
// import tire from '../../../assets/tire.svg';
import serverError from '../../../assets/server-error.svg';
import paymentError from '../../../assets/payment-error.svg';

const BookingErrorPageUI = ({
  icon = 'server-error',
  resetSeatsAndRetry = false,
  onResetSeatsAndRetry = () => {},
  message,
  savedCard,
  payWithSavedCardLink,
  payWithNewCardLink,
  showMultiplePaymentOptions = false,
  onBack = () => {},
  translatorForNamespace,
}) => {
  const t = translatorForNamespace('BookingErrorPageUI');
  let payLabel = t('Retry');

  const showSavedCard = !!savedCard && showMultiplePaymentOptions;

  const pic = icon === 'payment-error' ? paymentError : serverError;

  if (showSavedCard) {
    payLabel = (
      <div
        style={{
          display: 'inline-flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <PaymentIcon
          id={savedCard.brand.toLowerCase()}
          style={{ display: 'inline', height: '1rem', marginRight: 5 }}
        />
        {` ${payLabel} ${t('with card')} *${savedCard.last4}`}
      </div>
    );

    // payLabel = ` ${payLabel} ${t('with card')} *${savedCard.last4}`;
  }

  let cta = null;

  if (resetSeatsAndRetry) {
    cta = (
      <Button onClick={onResetSeatsAndRetry} primary fluid>
        {t('Retry with other seats')}
      </Button>
    );
  } else if (showMultiplePaymentOptions) {
    cta = (
      <>
        <Button to={payWithNewCardLink} primary fluid>
          {t('Try with another card')}
        </Button>
        {showSavedCard && (
          <>
            <Divider text={t('or')} />
            <LinkButton to={payWithSavedCardLink}>{payLabel}</LinkButton>
          </>
        )}
      </>
    );
  } else {
    cta = (
      <Button onClick={onBack} primary fluid>
        {t('Retry')}
      </Button>
    );
  }

  return (
    <DefaultScreen title={t('Your order')} onBack={onBack}>
      <div
        style={withDefaultFont({
          flex: '1',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        })}
      >
        <Image src={pic} style={{ width: 120, height: 'auto' }} verticalAlign="middle" />
        <div
          style={{
            marginTop: 30,
            marginBottom: 30,
            textAlign: 'center',
            maxWidth: '350px',
          }}
        >
          {message}
        </div>
      </div>
      <Bottom noSpacer>
        <Container style={{ textAlign: 'center' }}>{cta}</Container>
      </Bottom>
    </DefaultScreen>
  );
};

export default BookingErrorPageUI;
