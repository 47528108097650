import React from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { Form, Message } from 'semantic-ui-react';
import formatNumber from 'format-number';

import { withDefaultFont } from '../00_environment/Fonts';
import BlockWithLeftHeader from '../01_atoms/BlockWithLeftHeader';
import CentralPaneWrapper from '../01_atoms/CentralPaneWrapper';
import Button from '../01_atoms/Button';
import TitledContainer from '../01_atoms/TitledContainer';
import PaymentIcon from '../01_atoms/PaymentIcon';
import PaymentMethodCard from '../01_atoms/PaymentMethodCard';

const formatPrice = (price) => formatNumber({ prefix: '$', round: 2, padRight: 2 })(price);

const PaymentPaneWidget = ({
  translator: t,
  onBack = () => {},
  canSubmit = () => {},
  submit = () => {},
  setState = () => {},

  numberOfPassengers,
  payAmount,
  elementsOptions,
  error,

  isMobile = false,
  stripe,
}) => (
  <CentralPaneWrapper isMobile={isMobile}>
    <TitledContainer
      title={t('Payment details')}
      rightButtonText={t('Back')}
      onRightButtonClick={onBack}
      noSidePadding={isMobile}
      fullHeight={isMobile}
    >
      <BlockWithLeftHeader
        noContentPadding={isMobile}
        style={{ minHeight: '40vh' }}
        ribbonContent={t('{x} ticket{s}', {
          x: numberOfPassengers,
          s: numberOfPassengers > 1 ? 's' : '',
        })}
        headerContent={(
          <>
            <div style={{ fontSize: '2.8rem', fontWeight: '600', marginBottom: 30 }}>
              {formatPrice(payAmount)}
            </div>
            <PaymentMethodCard
              highlighted
              text={t('By credit card')}
              icons={[
                <div>
                  <div key="payment-icons-1">
                    <PaymentIcon id="visa" key="visa" style={{ height: '1.5rem', marginLeft: 5 }} />
                    <PaymentIcon
                      id="mastercard"
                      key="mastercard"
                      style={{ height: '1.5rem', marginLeft: 5 }}
                    />
                    <PaymentIcon id="amex" key="amex" style={{ height: '1.5rem', marginLeft: 5 }} />
                  </div>
                  <div key="payment-icons-2">
                    <PaymentIcon
                      id="diners"
                      key="diners"
                      style={{ height: '1.5rem', marginLeft: 5 }}
                    />
                    <PaymentIcon
                      id="discover"
                      key="discover"
                      style={{ height: '1.5rem', marginLeft: 5 }}
                    />
                    <PaymentIcon
                      id="maestro"
                      key="maestro"
                      style={{ height: '1.5rem', marginLeft: 5 }}
                    />
                  </div>
                </div>,
              ]}
            />
          </>
        )}
      >
        <TitledContainer
          title={t('Enter your credit card details')}
          wrapTitle
          backgroundColor="none"
          fontSize="1.2rem"
        >
          <Form>
            <Form.Field>
              <div
                style={{
                  fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
                  marginTop: 0,
                  marginBottom: 20,
                  outline: '0',
                  WebkitAppearance: 'none',
                  tapHighlightColor: 'rgba(255,255,255,0)',
                  lineHeight: '1.21428571em',
                  padding: '.67857143em 1em',
                  fontSize: '1em',
                  background: '#fff',
                  border: '1px solid rgba(34,36,38,.15)',
                  color: 'rgba(0,0,0,.87)',
                  borderRadius: '.28571429rem',
                  boxShadow: '0 0 0 0 transparent inset',
                  transition: 'color .1s ease,border-color .1s ease',
                }}
              >
                <CardElement
                  onChange={(e) => setState({ cardComplete: e.complete })}
                  {...elementsOptions}
                />
              </div>
            </Form.Field>
            {error && (
              <Message negative>
                <Message.Header>{t('There was an error with your payment')}</Message.Header>
                <p>{error.message}</p>
              </Message>
            )}
            <Button disabled={!canSubmit()} primary={!error} fluid onClick={() => submit(stripe)}>
              {!payAmount && t('Pay now')}
              {payAmount > 0
                && (error
                  ? t('Retry payment of {amount}', {
                    amount: formatPrice(payAmount),
                  })
                  : t('Pay {amount}', { amount: formatPrice(payAmount) }))}
            </Button>
            <div style={withDefaultFont({ marginTop: 15 })}>
              {t('By booking a ticket, you accept our terms of use.')}
            </div>
          </Form>
        </TitledContainer>
      </BlockWithLeftHeader>
    </TitledContainer>
  </CentralPaneWrapper>
);

export default injectStripe(PaymentPaneWidget);
