import React from 'react';
import alipay from 'payment-icons/min/flat/alipay.svg';
import discover from 'payment-icons/min/flat/discover.svg';
import hipercard from 'payment-icons/min/flat/hipercard.svg';
import paypal from 'payment-icons/min/flat/paypal.svg';
import unionpay from 'payment-icons/min/flat/unionpay.svg';
import visa from 'payment-icons/min/flat/visa.svg';
import amex from 'payment-icons/min/flat/amex.svg';
import diners from 'payment-icons/min/flat/diners.svg';
import elo from 'payment-icons/min/flat/elo.svg';
import jcb from 'payment-icons/min/flat/jcb.svg';
import maestro from 'payment-icons/min/flat/maestro.svg';
import mastercard from 'payment-icons/min/flat/mastercard.svg';
import verve from 'payment-icons/min/flat/verve.svg';

import defaultCard from 'payment-icons/min/flat/default.svg';

const cardsByName = {
  alipay,
  discover,
  hipercard,
  paypal,
  unionpay,
  visa,
  amex,
  diners,
  elo,
  jcb,
  maestro,
  mastercard,
  verve,
};

const PaymentIcon = ({ id, style }) => (
  <img
    alt=""
    src={cardsByName[id] || defaultCard}
    style={({
      display: 'inline',
      marginTop: 2,
      ...style,
    })}
  />
);

export default PaymentIcon;
