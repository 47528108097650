import React from 'react';
import FitText from '@plutonium-js/react-fit-text';
import toPx from 'to-px';
import { withPaneTitleFont, withBoldFont } from '../00_environment/Fonts';
import Container from './Container';
import { paneBackgroundColor } from '../00_environment/Colors';

const TitledContainer = ({
  title,
  rightButtonText,
  onRightButtonClick = () => {},
  children,
  backgroundColor,
  fontSize,
  noSidePadding = false,
  fullHeight = false,
  wrapTitle = false,
}) => (
  <Container
    noSidePadding={noSidePadding}
    style={{
      backgroundColor: backgroundColor || paneBackgroundColor,
      minHeight: '100%',
      paddingBottom: 30,
      height: fullHeight ? '100%' : undefined,
    }}
  >
    <div
      id="titled-container-header"
      style={{
        ...withPaneTitleFont({
          marginTop: 15,
          marginBottom: 30,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'baseline',
          paddingLeft: noSidePadding ? 25 : 0, // Keep padding on title even with noSidePadding
          paddingRight: noSidePadding ? 25 : 0, // Keep padding on title even with noSidePadding
        }),
        ...(fontSize ? { fontSize } : {}),
      }}
    >
      <div style={{ flex: '1', overflow: 'hidden' }}>
        {wrapTitle && (
          <span style={{ fontSize: fontSize ? toPx(fontSize) : 22, whiteSpace: 'pre-wrap' }}>
            {title}
          </span>
        )}
        {!wrapTitle && <FitText maxSize={fontSize ? toPx(fontSize) : 22}>{title}</FitText>}
      </div>

      <div
        onClick={onRightButtonClick}
        style={withBoldFont({
          cursor: 'pointer',
          paddingLeft: 20,
        })}
      >
        {rightButtonText}
      </div>
    </div>
    <div>{children}</div>
  </Container>
);

export default TitledContainer;
