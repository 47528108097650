import React, { PureComponent } from 'react';
import formatNumber from 'format-number';
import moment from '../../lib/moment';

import BlueLabel from '../01_atoms/BlueLabel';
import LightLabel from '../01_atoms/LightLabel';
import LightRightArrow from '../01_atoms/LightRightArrow';

import { withBoldFont, withSubtitleFont, withEmphasisFont } from '../00_environment/Fonts';
import {
  highlightedWidgetBackgroundColor,
  primaryColor,
  widgetBorderColor,
  discreetTextColor,
  blackTextColor,
} from '../00_environment/Colors';
import { roundedElementBorderRadius } from '../00_environment/Constants';

const formatPrice = (price) => formatNumber({ prefix: '$', round: 2, padRight: 2 })(price);

const HourLabel = ({ highlighted: h = false, children }) => (
  <span style={withBoldFont(h ? { color: primaryColor } : {})}>{children}</span>
);
const CityLabel = ({ children }) => <span style={withSubtitleFont({})}>{children}</span>;

const PriceLabel = ({ price }) => (
  <span style={withEmphasisFont({ color: primaryColor })}>{formatPrice(price)}</span>
);

class RidesListItem extends PureComponent {
  constructor(...args) {
    super(...args);
    this.state = {
      highlighted: false,
    };
  }

  render() {
    const {
      id,
      ride: r,
      numberOfPassengers = 1,
      onRideClick = () => {},
      translator: t = (txt) => txt,
    } = this.props;
    const { highlighted: mouseOver } = this.state;

    const highlighted = mouseOver || window.location.hash === `#${r.id}`;

    const departure = moment(r.departureTime);
    const arrival = moment(r.arrivalTime);

    const oT = r.originTerminal.name;
    const dT = r.destinationTerminal.name;

    const rideDuration = moment.duration(arrival.diff(departure));
    const formattedDuration = `${
      rideDuration.days() > 0 ? ` ${rideDuration.days()}d` : ''
    }${rideDuration.hours()}h${rideDuration.minutes() > 0 ? ` ${rideDuration.minutes()}m` : ''}`;
    const nDaysAdded = moment
      .duration(moment(arrival.format('YYYY-MM-DD')).diff(moment(departure.format('YYYY-MM-DD'))))
      .days();
    const d = nDaysAdded ? ` (+${nDaysAdded}d)` : '';
    const dh = departure.format('HH:mm');
    const ah = arrival.format('HH:mm');

    const NumberOfPassengersSpan = numberOfPassengers > 1
      ? () => (
        <span style={withBoldFont({ color: discreetTextColor })}>
          {`${numberOfPassengers}x `}
        </span>
      )
      : () => null;

    const FormattedPrice = () => (
      <>
        <NumberOfPassengersSpan />
        <PriceLabel price={r.grossPrice} />
      </>
    );

    const columnStyle = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      height: '50%',
    };
    const firstLineStyle = {};
    const secondLineStyle = { marginTop: 2 };

    return (
      <div
        id={id}
        key={`ride-item-el_${r.id}`}
        onClick={() => {
          onRideClick(r);
        }}
        onMouseOver={() => this.setState({ highlighted: true })}
        onMouseOut={() => this.setState({ highlighted: false })}
        style={{
          cursor: 'pointer',
          backgroundColor: highlighted ? highlightedWidgetBackgroundColor : 'white',
          border: `solid 1px ${highlighted ? primaryColor : widgetBorderColor}`,
          borderRadius: roundedElementBorderRadius,
          paddingTop: 20,
          paddingBottom: 20,
          paddingLeft: 25,
          paddingRight: 25,
          display: 'flex',
          flexDirection: 'row',
          position: 'relative',
          marginTop: 12,
          marginBottom: 12,
        }}
      >
        <div style={{ ...columnStyle }}>
          <div style={firstLineStyle}>
            <HourLabel highlighted={highlighted}>{dh}</HourLabel>
          </div>
          <div style={secondLineStyle}>
            <CityLabel highlighted={highlighted}>{oT}</CityLabel>
          </div>
        </div>
        <div style={{ ...columnStyle }}>
          <div style={firstLineStyle}>
            <LightRightArrow width={30} color={highlighted ? primaryColor : blackTextColor} />
          </div>
          <div style={secondLineStyle}> </div>
        </div>
        <div style={{ ...columnStyle }}>
          <div style={firstLineStyle}>
            <HourLabel highlighted={highlighted}>{ah + d}</HourLabel>
          </div>
          <div style={secondLineStyle}>
            <CityLabel highlighted={highlighted}>{dT}</CityLabel>
          </div>
        </div>
        <div
          style={{
            ...columnStyle,

            flex: '1',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={firstLineStyle}>
            <BlueLabel highlighted={highlighted}>{formattedDuration}</BlueLabel>
          </div>
          <div style={secondLineStyle}> </div>
        </div>
        <div style={{ ...columnStyle, alignItems: 'flex-end' }}>
          <div style={firstLineStyle}>
            <FormattedPrice highlighted={highlighted} />
          </div>
          <div style={secondLineStyle}>
            <LightLabel style={{ marginTop: 2 }}>
              {r ? r.busCategory || (r.apiData ? r.apiData.trp_desc : null) || 'normal' : 'normal'}
            </LightLabel>
          </div>
        </div>
      </div>
    );
  }
}

export default RidesListItem;
