import React from 'react';
import Page from '../01_atoms/Page';
import TopBar from '../01_atoms/TopBar';

const PopupScreen = ({
  title,
  onBack = () => {},
  children,
  topBarChildren = null,
  fullScreen = false,
  leftIcon = null,
}) => (
  <Page fullScreen={fullScreen}>
    <TopBar title={title} leftIcon={!leftIcon ? 'close' : leftIcon} onLeftIconClick={onBack}>
      {topBarChildren}
    </TopBar>
    {children}
  </Page>
);

export default PopupScreen;
