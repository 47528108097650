import React from 'react';
import EditPassengerForm from './EditPassengerForm';
import TitledContainer from '../01_atoms/TitledContainer';

const AddPassengerWidget = ({
  sdk,
  onAdd = () => {},
  onBack = () => {},
  translatorForNamespace,
  showAllFields = false,
}) => {
  const t = translatorForNamespace('AddPassengerPopupUI');

  const config = {
    name: {
      title: t('Passenger name'),
      subtitle: t('Will display on your tickets'),
      show: true,
    },
    phone: {
      title: t('Phone number'),
      subtitle: t('Used to send ticket information'),
      show: true,
    },
    ssn: {
      title: t('Identification document'),
      subtitle: t('Necessary to onboard the bus'),
      show: true,
    },
  };
  if (showAllFields) {
    config.email = { show: true };
    config.address = { show: true };
  }

  return (
    <TitledContainer title={t('Add passenger')}>
      <EditPassengerForm
        noWrap
        {...{
          sdk,
          translatorForNamespace,

          submitButtonLabel: t('Add'),
          onSubmit: onAdd,

          leftButtonLabel: t('Back'),
          onLeftButtonClick: onBack,
          leftButtonColor: null,

          config,
        }}
      />
    </TitledContainer>
  );
};

export default AddPassengerWidget;
