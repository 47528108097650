import React from 'react';
import check from 'check-types';
import { Label } from 'semantic-ui-react';

import { thirdColor } from '../00_environment/Colors';
import ElementGroup from '../01_atoms/ElementGroup';
import Icon from '../01_atoms/Icon';
import Bottom from '../01_atoms/Bottom';
import Button from '../01_atoms/Button';
import Container from '../01_atoms/Container';
import PlaceHolderElement from '../01_atoms/PlaceHolderElement';
import KeyValueElement from '../01_atoms/KeyValueElement';
import TitledContainer from '../01_atoms/TitledContainer';

const EditPassengersWidget = ({
  userPassengerId = null,
  savedPassengers = [],
  // onPassengerDelete = () => {},
  onPassengerEdit = () => {},
  addPassengerLink = '',
  onBack = () => {},
  translator: t,
}) => {
  const saved = savedPassengers || [];
  const addPassengerColor = thirdColor;

  saved.sort((a, b) => (a.firstName > b.firstName ? 1 : -1));

  return (
    <TitledContainer title={t('Edit known passengers')}>
      <ElementGroup
        title={t('List of known passengers')}
        subtitle={t('Book tickets faster by saving you and your friends details here')}
        titlePadding={10}
        backgroundColor="white"
      >
        {!check.nonEmptyArray(saved) && (
          <PlaceHolderElement>{t('No passenger saved yet')}</PlaceHolderElement>
        )}
        {saved.map((p) => {
          let title = `${p.firstName} ${p.lastName}`;
          if (userPassengerId === p.id) {
            title = (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                <span>{title}</span>
                <Label color="purple" horizontal style={{ marginLeft: 15 }}>
                  {t('You')}
                </Label>
              </div>
            );
          }

          return (
            <KeyValueElement
              style={{ cursor: 'pointer' }}
              onClick={() => onPassengerEdit(p)}
              title={
                <span style={{ width: '100%', height: '100%', cursor: 'pointer' }}>{title}</span>
              }
              value={(
                <Icon
                  name="angle right"
                  style={{
                    color: 'gray',
                    cursor: 'pointer',
                    marginLeft: 15,
                    marginBottom: 1,
                  }}
                />
              )}
            />
          );
        })}
      </ElementGroup>

      <Bottom>
        <Container>
          <Button
            to={addPassengerLink}
            fluid
            style={{ marginBottom: 15, color: 'white' }}
            color={addPassengerColor}
          >
            {t('Add passenger')}
          </Button>
        </Container>

        <Container>
          <Button onClick={onBack} fluid>
            {t('Back')}
          </Button>
        </Container>
      </Bottom>
    </TitledContainer>
  );
};

export default EditPassengersWidget;
