/* eslint react/no-did-update-set-state: "off" */

import React, { Component } from 'react';

import { paneBackgroundColor, paneBorderColor } from '../00_environment/Colors';
import { panePadding, roundedElementBorderRadius } from '../00_environment/Constants';

class RightPaneWidgetFrame extends Component {
  constructor(...args) {
    super(...args);

    this.state = {
      leftAnchorPosition: this.getLeftAnchorPosition(),
    };
  }

  componentDidMount() {
    // Update left anchor position after first mount
    // As we need #right-pane-widget-frame_container to be in DOM to calculate it
    this.setState({ leftAnchorPosition: this.getLeftAnchorPosition() });
  }

  componentDidUpdate(prevProps) {
    const { scrollTop = 0, focusedLeftItemId } = this.props;
    const { leftAnchorPosition } = this.state;
    if (prevProps.focusedLeftItemId !== focusedLeftItemId || prevProps.scrollTop !== scrollTop) {
      if (leftAnchorPosition !== this.getLeftAnchorPosition()) {
        this.setState({ leftAnchorPosition: this.getLeftAnchorPosition() });
      }
    }
  }

  getLeftAnchorPosition() {
    const { focusedLeftItemId } = this.props;
    let leftAnchorPosition = null;
    const container = document.getElementById('right-pane-widget-frame_container');

    /*
    console.log('focusedLeftItemId = ', focusedLeftItemId);
    console.log(
      'document.getElementById(focusedLeftItemId) = ',
      document.getElementById(focusedLeftItemId),
    );
    */

    if (focusedLeftItemId && container && document.getElementById(focusedLeftItemId)) {
      const element = document.getElementById(focusedLeftItemId);

      const bounds = element.getBoundingClientRect();
      const containerBounds = container.getBoundingClientRect();
      if (bounds && containerBounds) {
        leftAnchorPosition = (bounds.y || 0) - (containerBounds.y || 0) + Math.round((bounds.height || 0) / 2) - 10;
      }
    }

    return leftAnchorPosition;
  }

  render() {
    const { headerComponent: HeaderComponent, style = {}, children } = this.props;
    const { leftAnchorPosition } = this.state;

    const bgColor = style && style.backgroundColor ? style.backgroundColor : paneBackgroundColor;

    return (
      <div
        id="right-pane-widget-frame_container"
        style={{
          position: 'relative',
          width: 'calc(100% - 15px)', // Move window 15px right to make sure arrow is shown
          marginLeft: 15, // Move window 15px right to make sure arrow is shown
          marginBottom: 100,
          ...style,
        }}
      >
        <div
          style={{
            position: 'relative',
            backgroundColor: bgColor,
            borderRadius: roundedElementBorderRadius, // '.3125em',
            border: `1px solid ${paneBorderColor}`,
            // zIndex: 1,
            minHeight: (leftAnchorPosition || 0) + 50,
          }}
        >
          {HeaderComponent && (
            <div
              style={{
                position: 'relative',
                width: '100%',
                zIndex: 30,
              }}
            >
              <HeaderComponent />
            </div>
          )}
          <div
            style={{
              position: 'relative',
              zIndex: 1,
              padding: panePadding,
            }}
          >
            {children}
          </div>
        </div>

        {/* Left arrow */}
        {leftAnchorPosition && (
          <>
            <div
              style={{
                width: '20px',
                height: '20px',
                position: 'absolute',
                padding: '1px',
                top: leftAnchorPosition,
                left: '-10px',
                zIndex: 0,
                overflow: 'hidden',
                backgroundColor: bgColor,
                border: `2px solid ${paneBorderColor}`,
                boxShadow: 'none',
                borderBottomColor: '#d5d6db',
                borderRadius: '1px',
                content: "' '",
                display: 'block',
                WebkitTransform: 'rotate(45deg)',
                MozTransform: 'rotate(45deg)',
                MsTransform: 'rotate(45deg)',
                OTransform: 'rotate(45deg)',
                transform: 'rotate(45deg)',
              }}
            />
            <div
              style={{
                width: '20px',
                height: '20px',
                border: `solid 1px ${bgColor}`,
                position: 'absolute',
                top: leftAnchorPosition,
                left: '-8px',
                zIndex: 2,
                overflow: 'hidden',
                backgroundColor: bgColor,
                display: 'block',
                WebkitTransform: 'rotate(45deg)',
                MozTransform: 'rotate(45deg)',
                MsTransform: 'rotate(45deg)',
                OTransform: 'rotate(45deg)',
                transform: 'rotate(45deg)',
              }}
            />
          </>
        )}
      </div>
    );
  }
}

export default RightPaneWidgetFrame;
