import React from 'react';
import { Icon } from 'semantic-ui-react';
import BlockWithHeader from '../01_atoms/BlockWithHeader';
import RightPaneWidgetFrame from '../01_atoms/RightPaneWidgetFrame';
import TitledContainer from '../01_atoms/TitledContainer';

import TicketDetails from './TicketDetails';
import TripPriceDetails from './TripPriceDetails';

const TicketDetailsCardWidget = ({
  sdk,
  trip,
  focusedLeftItemId = null,
  onConfirm = () => {},
  nPassengers,
  locale,
  translator: t = (txt) => txt,
  // origin,
  // destination,
  isReturn,
  isOneWayTrip,
  scrollTop = 0,
}) => {
  const title = (
    <div style={{ display: 'inline', marginLeft: 5 }}>
      {/* `${isReturn ? t('Return') : t('Outbound')}: ` */}
      {sdk.terminals.nameFromId(trip.origin)}
      <Icon name="arrow right" style={{ display: 'inline', marginLeft: 5 }} />
      {`${sdk.terminals.nameFromId(trip.destination)}`}
      &nbsp;&nbsp;&nbsp;
    </div>
  );

  return (
    <RightPaneWidgetFrame focusedLeftItemId={focusedLeftItemId} scrollTop={scrollTop}>
      <TitledContainer title={t('Trip details')}>
        <BlockWithHeader
          ribbonContent={title}
          headerContent={(
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                textAlign: 'left',
              }}
            >
              <div
                style={{
                  fontSize: '1.6rem',
                  fontWeight: '600',
                  marginTop: 5,
                  marginBottom: 2,
                }}
              >
                {sdk.routes.humanPricingForRoute(trip, nPassengers, false)}
              </div>
              <div style={{ fontSize: '0.9rem', fontWeight: 'normal' }}>
                {`${nPassengers} ${t('passenger')}${nPassengers > 1 ? 's' : ''}`}
              </div>
            </div>
          )}
          buttonText={t('Continue')}
          buttonSubText={t('to fill out trip details')}
          onButtonClick={onConfirm}
        >
          <TicketDetails
            title={t('Details')}
            topRightLabel={null}
            ticketDetails={trip}
            nPassengers={nPassengers}
            locale={locale}
          />

          <TripPriceDetails
            style={{ marginTop: 30 }}
            trip={trip}
            numberOfPassengers={nPassengers}
            isOneWayTrip={isOneWayTrip}
            translatorForNamespace={sdk.i18n.translatorForNamespace}
          />
        </BlockWithHeader>
      </TitledContainer>
    </RightPaneWidgetFrame>
  );
};

export default TicketDetailsCardWidget;
