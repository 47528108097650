import React, { Component } from 'react';
import jsonStable from 'json-stable-stringify';
import { Form, Dropdown } from 'semantic-ui-react';

import { withDefaultFont } from '../00_environment/Fonts';
import FormElement from '../01_atoms/FormElement';
import FormElementGroup from '../01_atoms/FormElementGroup';
import TitledContainer from '../01_atoms/TitledContainer';

import EditPassengerForm from './EditPassengerForm';

class BillingDetailsWidget extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedPassenger: null };
  }

  onSelectedPassengerChange(selectedPassenger) {
    console.log(`onSelectedPassengerChange(${selectedPassenger})`);
    this.setState({
      selectedPassenger,
    });
  }

  shouldComponentUpdate(prevProps, prevState) {
    if (prevState.selectedPassenger !== this.state.selectedPassenger) {
      return true;
    }

    if (prevProps.setBillingInfo !== this.props.setBillingInfo) {
      console.log('Rendering BillingDetailsWidget -- setBilingInfo changed');
      return true;
    }

    if (prevProps.onBack !== this.props.onBack) {
      console.log('Rendering BillingDetailsWidget -- onBack changed');
      return true;
    }

    if (jsonStable(prevProps.savedPassengers) !== jsonStable(this.props.savedPassengers)) {
      console.log('Rendering BillingDetailsWidget -- savedPassengers changed');
      return true;
    }

    return false;
  }

  render() {
    console.log('Rendering BillingDetailsWidget / IN RENDER');

    const {
      sdk,
      userPassengerId,
      billingPassengerId,
      savedPassengers,
      onBack,
      setBillingInfo = () => {},
      translatorForNamespace,
    } = this.props;
    const { selectedPassenger: sPgr } = this.state;
    const t = translatorForNamespace('CreditCardPaymentPageUI');

    const selectedPassenger = sPgr || billingPassengerId || userPassengerId;
    console.log(`Rendering with selectedPassenger = ${selectedPassenger}`);

    return (
      <TitledContainer title={t('Billing details')}>
        <FormElementGroup title={t('Which person should we bill this trip to?')}>
          <FormElement>
            <Form>
              <Form.Field>
                <Dropdown
                  placeholder={t('Select Passenger')}
                  fluid
                  search
                  selection
                  options={[
                    ...savedPassengers.map((p) => ({
                      key: p.id,
                      value: p.id,
                      icon: 'user',
                      text: `${p.firstName} ${p.lastName}`,
                    })),
                    {
                      key: 'other',
                      value: 'other',
                      icon: 'question',
                      text: t('Bill this trip to someone else'),
                    },
                  ]}
                  onChange={(e, o) => {
                    this.onSelectedPassengerChange(o.value);
                  }}
                  value={selectedPassenger}
                />
              </Form.Field>
            </Form>
          </FormElement>
        </FormElementGroup>
        <EditPassengerForm
          noWrap
          {...{
            sdk,
            translatorForNamespace,

            defaultValue:
              !selectedPassenger || selectedPassenger === 'other'
                ? null
                : savedPassengers.find((p) => p.id === selectedPassenger),
            submitButtonLabel: t('Validate billing info'),
            onSubmit: (...args) => {
              console.log('Calling setBillingInfo with args: ', ...args);
              setBillingInfo(...args);
            },

            leftButtonLabel: t('Back'),
            onLeftButtonClick: onBack,
            leftButtonColor: null,

            validationRequirement: 'extended',

            config: {
              name: {
                title: t('Name'),
                subtitle: '',
                show: !selectedPassenger || selectedPassenger === 'other',
              },
              phone: {
                title: t('Phone number'),
                subtitle: '',
                show: !selectedPassenger || selectedPassenger === 'other',
              },
              ssn: {
                title: t('Identification document'),
                subtitle: '',
                show: !selectedPassenger || selectedPassenger === 'other',
              },

              email: {
                show: true,
              },
              address: {
                show: true,
              },
            },
          }}
        />
      </TitledContainer>
    );
  }
}

export default BillingDetailsWidget;
