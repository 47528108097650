import React from 'react';

const BlockWithLeftIcon = ({
  style = {}, icon, children, ...props
}) => (
  <div
    style={({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      ...style,
    })}
    {...props}
  >
    <img alt="" src={icon} style={{ width: 50, marginRight: 20, marginTop: 0 }} />
    <div style={{ flex: '1', textAlign: 'left' }}>{children}</div>
  </div>
);

export default BlockWithLeftIcon;
