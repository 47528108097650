import React from 'react';
import { Divider as NativeDivider } from 'semantic-ui-react';

import { withDividerFont } from '../00_environment/Fonts';
import Container from './Container';

const Divider = ({ text }) => (
  <Container style={{ marginBottom: 10, marginTop: 20 }}>
    <NativeDivider horizontal style={withDividerFont({})}>
      {text}
    </NativeDivider>
  </Container>
);

export default Divider;
