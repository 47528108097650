import React from 'react';

import ResponsivePage from '../../01_atoms/ResponsivePage';

import SinglePaneEditPassengersPage from './EditPassengersPage.mobile';
import DualPaneEditPassengersPage from './EditPassengersPage.widescreen';

const EditPassengersPage = ({ sdk, ...props }) => {
  const {
    i18n: { translatorForNamespace },
  } = sdk;

  const t = translatorForNamespace('EditPassengersPageUI');

  return (
    <ResponsivePage
      wideScreenComponent={DualPaneEditPassengersPage}
      mobileComponent={SinglePaneEditPassengersPage}
      {...props}
      {...{
        sdk,
        translator: t,
      }}
    />
  );
};

export default EditPassengersPage;
