import React from 'react';
import DefaultScreen from '../../02_molecules/DefaultScreen';
import BillingDetailsWidget from '../../02_molecules/BillingDetailsWidget';

const BillingDetailsPage = ({
  sdk,
  userPassengerId = null,
  savedPassengers = [],
  billingPassengerId = null,
  setBillingInfo = () => {},
  onBack = () => {},
}) => {
  const {
    i18n: { translatorForNamespace },
  } = sdk;
  const t = translatorForNamespace('BillingDetailsPage');
  const title = t('Billing details');

  return (
    <DefaultScreen title={title} onBack={onBack}>
      <BillingDetailsWidget
        sdk={sdk}
        userPassengerId={userPassengerId}
        savedPassengers={savedPassengers}
        billingPassengerId={billingPassengerId}
        setBillingInfo={setBillingInfo}
        translatorForNamespace={sdk.i18n.translatorForNamespace}
        onBack={onBack}
      />
    </DefaultScreen>
  );
};

export default BillingDetailsPage;
