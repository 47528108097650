import React, { Component } from 'react';
import check from 'check-types';

import FormElement from '../01_atoms/FormElement';
import FormElementGroup from '../01_atoms/FormElementGroup';
import Button from '../01_atoms/Button';
import TitledContainer from '../01_atoms/TitledContainer';
import AddressFormComponent from './AddressFormComponent';

const emptyAddress = () => ({
  line1: '',
  line2: '',
  city: '',
  zip: '',
  country: '',
});

class CovidInfoWidget extends Component {
  constructor(...args) {
    super(...args);

    this.state = {
      originAddress: emptyAddress(),
      destinationAddress: emptyAddress(),
    };
  }

  componentDidMount() {
    // Apply default value on mount if any
    const { defaultValue } = this.props;

    if (check.nonEmptyObject(defaultValue)) {
      this.setState({
        originAddress: defaultValue.originAddress || emptyAddress(),
        destinationAddress: defaultValue.destinationAddress || emptyAddress(),
      });
    }
  }

  render() {
    const { validateData = () => true, onSubmit = () => {}, translatorForNamespace } = this.props;
    const { originAddress, destinationAddress } = this.state;
    const t = translatorForNamespace('CovidInfoWidget');

    return (
      <TitledContainer title={t('Covid information')}>
        <FormElementGroup title={t('Your Origin Address')}>
          <FormElement>
            <AddressFormComponent
              address={originAddress}
              onChange={(d) => this.setState({
                originAddress: d,
              })}
              translatorForNamespace={translatorForNamespace}
            />
          </FormElement>
        </FormElementGroup>

        <FormElementGroup title={t('Your Destination Address')}>
          <FormElement>
            <AddressFormComponent
              address={destinationAddress}
              onChange={(d) => this.setState({
                destinationAddress: d,
              })}
              translatorForNamespace={translatorForNamespace}
            />
          </FormElement>
        </FormElementGroup>

        <Button
          style={{ marginTop: 25 }}
          disabled={!validateData(this.state)}
          primary
          fluid
          onClick={() => onSubmit(this.state)}
        >
          {t('Confirm details')}
        </Button>
      </TitledContainer>
    );
  }
}

export default CovidInfoWidget;
