import React, { Component } from 'react';

import Bottom from '../01_atoms/Bottom';
import Button from '../01_atoms/Button';
import Container from '../01_atoms/Container';
import TitledContainer from '../01_atoms/TitledContainer';
import EditPassengerFormUI from './EditPassengerFormUI';

class EditPassengerForm extends Component {
  constructor(...args) {
    super(...args);

    const { sdk } = this.props;
    this.state = sdk.passengers.emptyPassenger();
  }

  componentDidMount() {
    // console.log('EditPassengerForm MOUNT');
    const { sdk, defaultValue } = this.props;
    this.setState({ id: null, ...(defaultValue || sdk.passengers.emptyPassenger()) });
  }

  componentDidUpdate(prevProps) {
    // console.log('EditPassengerForm UPDATE');
    const { defaultValue } = this.props;
    if (defaultValue !== prevProps.defaultValue) {
      // console.log('EditPassengerForm UPDATE SHOULD MOUNT');
      this.componentDidMount();
    }
  }

  onChange(key, value) {
    // console.log(`[EditPassengerForm] onChange(${key},${value})`);
    this.setState({ [key]: value });
  }

  render() {
    // console.log('Rendering EditPassengerForm');

    const {
      sdk,
      submitButtonLabel = null,
      onSubmit = () => {},
      leftButtonLabel = null,
      onLeftButtonClick,
      leftButtonColor = null,
      translatorForNamespace,
      config = {},
      validationRequirement = 'basic',
      noWrap = false,
    } = this.props;

    const t = translatorForNamespace('EditPassengerFormUI');

    // FIXME: Smarter validation flexibility? (validating the exact fields selected ?)
    const validationFunc = validationRequirement === 'basic'
      ? sdk.passengers.validateBasicPassengerInfo
      : sdk.passengers.validateBillingPassengerInfo;

    const validation = validationFunc(this.state);
    const hasErrors = (...err) => {
      if (err.length > 1) {
        return err.some((e) => hasErrors(e));
      }
      return validation.errors.includes(err[0]);
    };

    const content = (
      <>
        <EditPassengerFormUI
          {...{
            value: this.state,
            onChange: (newData) => this.setState(newData),
            hasErrors,
            translatorForNamespace,
            config,
          }}
        />
        <Bottom>
          <Container style={{ display: 'flex' }}>
            {leftButtonLabel && onLeftButtonClick && (
              <Button
                color={leftButtonColor || null}
                onClick={onLeftButtonClick}
                style={{ flex: '1', margin: 5 }}
              >
                {leftButtonLabel}
              </Button>
            )}

            <Button
              onClick={() => onSubmit(this.state)}
              style={{ flex: '1', margin: 5 }}
              primary
              disabled={!validation.isValid}
            >
              {submitButtonLabel || t('Save')}
            </Button>
          </Container>
        </Bottom>
      </>
    );

    if (noWrap) {
      return content;
    }

    return <TitledContainer title={t('Edit passenger')}>{content}</TitledContainer>;
  }
}

export default EditPassengerForm;
