/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Form, Dropdown } from 'semantic-ui-react';
import check from 'check-types';

import moment from '../../lib/moment';
import { withFormLabelFont } from '../00_environment/Fonts';
import Button from '../01_atoms/Button';
import ClosableInput from '../01_atoms/ClosableInput';
import TitledContainer from '../01_atoms/TitledContainer';
import NumberPicker from '../../vendors/semantic-ui-react-numberpicker';

const BookingForm = ({
  formData: formFields,
  onChange = () => {},
  onSubmit = () => {},
  translationManager,
  translator: t = (txt) => txt,
  dropDownSearch = () => {},
  departures,
  arrivals,
  dualPaneMode = false,
  onOriginFieldFocus = () => {},
  onDestinationFieldFocus = () => {},
  onOutboundDateFieldFocus = () => {},
  onReturnDateFieldFocus = () => {},
  onFieldClear = () => {},
  disableReturnField = false,
  departureSearch = '',
  setDepartureSearch = () => {},
  arrivalSearch = '',
  setArrivalSearch = () => {},
}) => {
  const {
    selectedDeparture, selectedArrival, numberOfPassengers, outDate, returnDate,
  } = formFields;

  let departureName = null;
  if (selectedDeparture && check.nonEmptyArray(departures)) {
    const d = departures.find((dep) => dep.value === selectedDeparture);
    if (d) {
      departureName = d.text;
    }
  }

  let arrivalName = null;
  if (selectedArrival && check.nonEmptyArray(arrivals)) {
    const a = arrivals.find((ar) => ar.value === selectedArrival);
    if (a) {
      arrivalName = a.text;
    }
  }

  return (
    <TitledContainer title={t('Find your trip')}>
      <Form
        style={{
          backgroundColor: 'none',
          width: '100%',
          marginTop: '5%',
          marginBottom: '5%',
        }}
      >
        <Form.Field>
          <label style={withFormLabelFont({})}>{t('From')}</label>
          {dualPaneMode ? (
            <ClosableInput
              id="booking-form_from-field"
              key="booking-form_from-field"
              value={departureName || departureSearch}
              placeholder={t('From')}
              onClick={() => onOriginFieldFocus('booking-form_from-field')}
              onClear={() => {
                onChange(
                  Object.assign(formFields, {
                    selectedDeparture: null,
                  }),
                );
                onFieldClear('selectedDeparture');
                setDepartureSearch('');
              }}
              onChange={(e)=> {
                // Clear selected departure if any
                if (selectedDeparture) {
                  onChange(
                    Object.assign(formFields, {
                      selectedDeparture: null,
                    }),
                  );
                }
                // Change search
                setDepartureSearch(e.target.value);
              }}
            />
          ) : (
            <Dropdown
              id="booking-form_from-field"
              value={selectedDeparture}
              placeholder={t('From')}
              fluid
              clearable
              closeOnBlur
              search={dropDownSearch}
              closeOnEscape
              selection
              options={departures}
              onChange={(e, o) => onChange(Object.assign(formFields, { selectedDeparture: o.value }))}
            />
          )}
        </Form.Field>

        <Form.Field>
          <label style={withFormLabelFont({})}>{t('To')}</label>
          {dualPaneMode ? (
            <ClosableInput
              id="booking-form_to-field"
              value={arrivalName || arrivalSearch}
              placeholder={t('To')}
              onClick={() => onDestinationFieldFocus('booking-form_to-field')}
              onClear={() => {
                onChange(
                  Object.assign(formFields, {
                    selectedArrival: null,
                  }),
                );
                onFieldClear('selectedArrival');
                setArrivalSearch('');
              }}
              onChange={(e)=> {
                // Clear selected departure if any
                if (selectedArrival) {
                  onChange(
                    Object.assign(formFields, {
                      selectedArrival: null,
                    }),
                  );
                }
                // Change search
                setArrivalSearch(e.target.value);
              }}
            />
          ) : (
            <Dropdown
              id="booking-form_to-field"
              value={selectedArrival}
              placeholder={t('To')}
              fluid
              clearable
              closeOnBlur
              closeOnEscape
              selection
              options={arrivals}
              onChange={(e, o) => onChange(Object.assign(formFields, { selectedArrival: o.value }))}
              search={dropDownSearch}
            />
          )}
        </Form.Field>

        <Form.Field>
          <label style={withFormLabelFont({})}>{t('Out date')}</label>

          <ClosableInput
            id="booking-form_outDate-field"
            value={
              outDate
                ? moment(outDate, 'YYYY-MM-DD')
                  .locale(translationManager.getCurrentLanguage())
                  .format('ll')
                : null
            }
            placeholder={t('Departure date')}
            valueLink={dualPaneMode ? null : '/book/details/departure-date'}
            onClick={
              dualPaneMode
                ? () => onOutboundDateFieldFocus('booking-form_outDate-field')
                : undefined
            }
            onClear={() => {
              onChange(
                Object.assign(formFields, {
                  outDate: null,
                  returnDate: null,
                }),
              );
              onFieldClear('outDate');
            }}
          />
        </Form.Field>

        <Form.Field>
          <label style={withFormLabelFont({})}>{t('Return date')}</label>

          <ClosableInput
            id="booking-form_returnDate-field"
            disabled={disableReturnField}
            value={
              returnDate && !disableReturnField
                ? moment(returnDate, 'YYYY-MM-DD')
                  .locale(translationManager.getCurrentLanguage())
                  .format('ll')
                : null
            }
            placeholder={
              disableReturnField ? t('No return possible for this route') : t('No return')
            }
            valueLink={dualPaneMode ? null : '/book/details/return-date'}
            onClick={
              dualPaneMode
                ? () => onReturnDateFieldFocus('booking-form_returnDate-field')
                : undefined
            }
            onClear={() => {
              onChange(Object.assign(formFields, { returnDate: null }));
              onFieldClear('returnDate');
            }}
          />
        </Form.Field>

        <Form.Field>
          <label style={withFormLabelFont({})}>{t('Number of passengers')}</label>
          <NumberPicker
            id="booking-form_numberOfPassengers-field"
            min={1}
            name="numberPicker"
            value={numberOfPassengers}
            onChange={(up) => onChange(Object.assign(formFields, { numberOfPassengers: up.value }))}
          />
        </Form.Field>
        <Button
          type="submit"
          primary
          fluid
          disabled={!(selectedDeparture && selectedArrival && numberOfPassengers && outDate)}
          onClick={() => onSubmit(formFields)}
          style={{ marginTop: 30 }}
        >
          {t('Search')}
        </Button>
      </Form>
    </TitledContainer>
  );
};

export default BookingForm;
