import React, { useState } from 'react';

import Page from '../../01_atoms/Page';
import CitySelectionWidget from '../../02_molecules/CitySelectionWidget';
import DualPanePageWithSelector from '../../02_molecules/DualPanePageWithSelector';
import HomeTopBar from '../../02_molecules/HomeTopBar';
import BookingForm from '../../02_molecules/BookingForm';
import DateSelectionWidget from '../../02_molecules/DateSelectionWidget';

const LeftComponent = ({ showOnRight, rightComponentByName, ...otherProps }) => (
  <BookingForm
    key='booking-form'
    onOriginFieldFocus={(id) => showOnRight('origin-selector', id)}
    onDestinationFieldFocus={(id) => showOnRight('destination-selector', id)}
    onOutboundDateFieldFocus={(id) => showOnRight('outbound-date-selector', id)}
    onReturnDateFieldFocus={(id) => showOnRight('return-date-selector', id)}
    onFieldClear={() => showOnRight(null)}

    {...otherProps}
  />
)

const DualPaneBookingFormPage = ({
  formFields,
  onChange,
  onSubmit,
  translationManager,
  translatorForNamespace,
  dropDownSearch,
  departures,
  arrivals,
  t,

  minDepartureDay,
  minReturnDay,
  maxBookingDay,
  disableReturnField,
  isIdle = false,
}) => {
  const {
    selectedDeparture, selectedArrival, outDate, returnDate,
  } = formFields;

  const [departureSearch, setDepartureSearch] = useState('');
  const [arrivalSearch, setArrivalSearch] = useState('');

  const rightComponentByName = {
    'origin-selector': ({ scrollTop, showOnRight, focusedLeftItemId = null }) => (
      <CitySelectionWidget
        {...{
          title: t('Select a departure terminal'),
          items: dropDownSearch(departures, departureSearch),
          selectedItem: selectedDeparture,
          onSelect: (s) => {
            showOnRight(null);
            onChange({ selectedDeparture: s });
            setDepartureSearch('');
          },
          elementStyle: {},
          translator: t,
          focusedLeftItemId,
        }}
        scrollTop={scrollTop}
      />
    ),
    'destination-selector': ({ scrollTop, showOnRight, focusedLeftItemId = null }) => (
      <CitySelectionWidget
        {...{
          title: t('Select a destination terminal'),
          items: dropDownSearch(arrivals, arrivalSearch),
          selectedItem: selectedArrival,
          onSelect: (s) => {
            showOnRight(null);
            onChange({ selectedArrival: s });
            setArrivalSearch('');
          },
          elementStyle: {},
          translator: t,
          focusedLeftItemId,
        }}
        scrollTop={scrollTop}
      />
    ),
    'outbound-date-selector': ({ scrollTop, showOnRight, focusedLeftItemId = null }) => (
      <DateSelectionWidget
        title={t('Departure date')}
        selectedDay={outDate}
        minDay={minDepartureDay}
        maxDay={maxBookingDay}
        onChange={(d) => {
          showOnRight(null);
          onChange({ outDate: d });
        }}
        translationManager={translationManager}
        focusedLeftItemId={focusedLeftItemId}
        scrollTop={scrollTop}
      />
    ),

    'return-date-selector': ({ scrollTop, showOnRight, focusedLeftItemId = null }) => (
      <DateSelectionWidget
        title={t('Return date')}
        selectedDay={returnDate}
        minDay={minReturnDay}
        maxDay={maxBookingDay}
        onChange={(d) => {
          showOnRight(null);
          onChange({ returnDate: d });
        }}
        translationManager={translationManager}
        focusedLeftItemId={focusedLeftItemId}
        scrollTop={scrollTop}
      />
    ),
  };

  // Add background pic when integrated to reinadelcamino.ec homepage
  let additionalProps = {};
  if (isIdle) {
    additionalProps = {
      style: {
        backgroundImage:
          'url(https://reinadelcamino.ec/wp-content/uploads/2019/07/reinadelcamino.jpg?id=4509)',
        backgroundColor: '#f2f5fa',
        backgroundPosition: 'bottom right',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    };
  }
  return (
    <Page>
      <HomeTopBar translatorForNamespace={translatorForNamespace} />
      <DualPanePageWithSelector
        leftComponent={LeftComponent} 
        rightComponentByName={rightComponentByName}
        {...additionalProps}

        formData={formFields}
        onChange={onChange}
        onSubmit={onSubmit}
        translationManager={translationManager}
        translator={t}
        dropDownSearch={dropDownSearch}
        departures={departures}
        arrivals={arrivals}
        dualPaneMode
        disableReturnField={disableReturnField}
        isIdle={isIdle}
        departureSearch={departureSearch}
        arrivalSearch={arrivalSearch}
        setDepartureSearch={setDepartureSearch}
        setArrivalSearch={setArrivalSearch}
      />
    </Page>
  );
};

export default DualPaneBookingFormPage;
