import React from 'react';
import { Form } from 'semantic-ui-react';

import Button from '../../../01_atoms/Button';
import DefaultScreen from '../../../02_molecules/DefaultScreen';
import FormContainer from '../../../01_atoms/FormContainer';
import TitledContainer from '../../../01_atoms/TitledContainer';
import ResponsiveCentralPaneWrapper from '../../../02_molecules/ResponsiveCentralPaneWrapper';

const LoginByPhoneThirdPage = ({ error, onNext = () => {}, translatorForNamespace }) => {
  const t2 = translatorForNamespace('LoginPageUI');
  const title = t2('Login');
  return (
    <DefaultScreen title={title}>
      <ResponsiveCentralPaneWrapper>
        <TitledContainer title={title}>
          <FormContainer>
            <Form onSubmit={onNext} error={!!error}>
              Success!
              <Button fluid type="submit" primary>
                {title}
              </Button>
            </Form>
          </FormContainer>
        </TitledContainer>
      </ResponsiveCentralPaneWrapper>
    </DefaultScreen>
  );
};

export default LoginByPhoneThirdPage;
