import React from 'react';
import { Image } from 'semantic-ui-react';

import { appName, appWebsite } from '../../00_environment/Constants';
import { withDangerLinkButtonFont } from '../../00_environment/Fonts';
import Page from '../../01_atoms/Page';
import Bottom from '../../01_atoms/Bottom';
import Container from '../../01_atoms/Container';
import Button from '../../01_atoms/Button';
import Title from '../../01_atoms/Title';

import whatsAppLogo from '../../../assets/whatsapp.svg';
import logo from '../../../assets/logo';

const BetaOnboardingWaitingPageUI = ({ translatorForNamespace, onLogout }) => {
  const t = translatorForNamespace('BetaOnboardingWaitingPageUI');

  return (
    <Page>
      <div
        style={{
          flex: '1',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Image width={64} src={logo} style={{ margin: 20 }} />
        <Title
          style={{
            textAlign: 'center',
            lineHeight: '1.4',
            width: '80vw',
          }}
        >
          {t('Estas a un paso de descubrir')}
          <br />
          {t('la ')}
          <strong>{t('mejor forma')}</strong>
          {t(' de comprar')}
          <br />
          {t('tus boletos de bus')}
        </Title>
        <Title
          style={{
            textAlign: 'center',
            lineHeight: '1.4',
            width: '80vw',
            marginTop: 20,
            fontWeight: 300,
          }}
        >
          {t('Mucha gente esta pidiendo inscribirse a {appName}.', { appName })}
          <br />
          {t('Por favor, espere tu turno para ingresar')}
        </Title>
        {/*
        <Header style={withBoldTitleFont({ textAlign: 'center' })}>
          {t('Hay {x} personas', { x: 203 })}
          <br />
          <span
            style={{
              fontWeight: 300,
              fontSize: '1rem',
            }}
          >
            {t('delante tuyo en la fila')}
          </span>
        </Header>
        <Title
          style={{
            textAlign: 'center',
            lineHeight: '1.4',
            width: '80vw',
            marginTop: 5,
          }}
        >
          <strong>{t('{x} personas', { x: 2039 })}</strong>
          <br />
          <span
            style={{
              fontWeight: 300,
              fontSize: '1rem',
            }}
          >
            {t('son detras tuyo')}
          </span>
        </Title>

        */}

        <Title
          style={{
            textAlign: 'center',
            lineHeight: '1.4',
            width: '80vw',
            marginTop: 30,
            fontSize: '1rem',
          }}
        >
          {`Comparte ${appName} con tus amigos para ingresar mas rapido!`}
        </Title>
        <Button
          color="#01e675"
          style={{
            marginTop: 10,
            height: 40,
            borderRadius: 5,
            padding: 0,
            paddingLeft: 10,
            paddingRight: 10,
          }}
          onClick={() => {
            window.location.href = `whatsapp://send?text=${encodeURIComponent(
              `Mira: ${appName} es una app para comprar boletos de bus desde tu movil.\nNo puedo esperar!\n\n${appWebsite}`,
            )}`;
          }}
        >
          <Image src={whatsAppLogo} avatar />
          {t('Comparte {appName} en WhatsApp', { appName })}
        </Button>
      </div>
      <Bottom>
        <Container style={{ textAlign: 'center' }}>
          <div
            style={withDangerLinkButtonFont({
              textDecoration: 'none',
              cursor: 'pointer',
            })}
            onClick={() => {
              // reactSwipeEl.next();
              onLogout();
            }}
          >
            {t('Log out')}
          </div>
        </Container>
      </Bottom>
    </Page>
  );
};

export default BetaOnboardingWaitingPageUI;
