import React from 'react';

import RightPaneWidgetFrame from '../01_atoms/RightPaneWidgetFrame';
import BillingDetailsWidget from './BillingDetailsWidget';

const BillingDetailsRightPane = ({
  sdk,
  onBillingDetailsValidated = () => {},
  focusedLeftItemId = null,
}) => (
  <RightPaneWidgetFrame focusedLeftItemId={focusedLeftItemId}>
    <BillingDetailsWidget
      sdk={sdk}
      userPassengerId={sdk.passengers.getUserPassengerId()}
      savedPassengers={sdk.passengers.getAllSavedPassengers()}
      billingPassengerId={sdk.billingDetails.getBillingPassengerId()}
      setBillingInfo={async (data) => {
        sdk.billingDetails.setBillingDetails(data);
        onBillingDetailsValidated(null);
      }}
      translatorForNamespace={sdk.i18n.translatorForNamespace}
    />
  </RightPaneWidgetFrame>
);

export default BillingDetailsRightPane;
