import React from 'react';
import { Segment, Icon } from 'semantic-ui-react';
import check from 'check-types';
import { primaryColor } from '../00_environment/Colors';

const ListSelect = ({
  items = {},
  selectedItem = null,
  onSelect = () => {},
  translator: t = (txt) => txt,
}) => {
  if (!check.nonEmptyArray(items)) {
    return <Segment placeholder>{t('No choices available')}</Segment>;
  }

  const notSelectedMark = (
    <div
      style={{
        width: 20,
      }}
    />
  );

  const selectedMark = (
    <div
      style={{
        width: 20,
        height: '100%',
        textAlign: 'left',
        color: primaryColor,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <Icon name="check" size="small" />
    </div>
  );

  return (
    <Segment.Group>
      {items.map((item) => (
        <Segment
          key={item.key}
          onClick={() => onSelect(item.value)}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          {item.value === selectedItem ? selectedMark : notSelectedMark}
          <span>{item.text || item.name || item.value}</span>
        </Segment>
      ))}
    </Segment.Group>
  );
};

export default ListSelect;
