import React from 'react';
import { textColor } from '../00_environment/Colors';
import { withDefaultFont } from '../00_environment/Fonts';
import Title from './Title';
import Subtitle from './Subtitle';

const TitleBlock = ({
  title,
  subtitle,
  children,
  slim = false,
  id,
  style = {},
  padding = null,
}) => (
  <div
    id={id}
    style={Object.assign(
      withDefaultFont({
        marginTop: slim ? 10 : 15,
        color: textColor,
      }),
      style,
    )}
  >
    {title && (
      <Title
        style={
          padding !== null
            ? {
              paddingLeft: padding,
              paddingRight: padding,
            }
            : {}
        }
      >
        {title}
      </Title>
    )}
    {subtitle && (
      <Subtitle
        style={
          padding !== null
            ? {
              paddingLeft: padding,
              paddingRight: padding,
            }
            : {}
        }
      >
        {subtitle}
      </Subtitle>
    )}
    {children}
  </div>
);

export default TitleBlock;
