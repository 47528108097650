import React from 'react';
import check from 'check-types';
import Loader from 'react-loader-spinner';
import toPx from 'to-px';
import { hexToRgba, rgbaToHex } from 'hex-and-rgba';

import {
  seatMatrixBusyColor,
  widgetBorderColor,
  blackTextColor,
  primaryColor,
  highlightedWidgetBackgroundColor,
  discreetTextColor,
} from '../00_environment/Colors';

import { withBoldFont, withDefaultFont } from '../00_environment/Fonts';

const bgColorFromFullColor = (fullColor) => {
  const [r, g, b] = hexToRgba(fullColor);
  return rgbaToHex(r, g, b, 0.06);
};

const SeatRowComponent = ({
  seats,
  width,
  height,
  margin,
  selectedSeats = [],
  seatsPendingHold = [],
  seatsPendingRelease = [],
  onSeatToggle = null,
  numberOfSeatsToSelect = 0,
  seatInfoByPrice,
}) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 'fit-content',
    }}
  >
    {seats.map((s, i) => {
      const seatInfo = seatInfoByPrice(s.priceInCents);
      const seatFree = s.exists && s.available && !!s.number;
      // Reina del Camino doesn't give number for non available seats
      const visibility = s.exists /* && !!s.number */ ? 'visible' : 'hidden';
      const showRightSpace = (index) => index % 2 === 1 && index + 1 < seats.length;
      const seatSelected = selectedSeats && selectedSeats.includes(s.number);
      const seatChangePending = (seatsPendingHold && seatsPendingHold.includes(s.number))
        || (seatsPendingRelease && seatsPendingRelease.includes(s.number));
      let fontWeight = 'bold';

      let seatColor = 'white';
      let seatBorderColor = seatInfo.freeColor;

      let color = 'gray';
      let cursor = onSeatToggle ? 'pointer' : 'inherit';

      const selectable = check.function(onSeatToggle)
        && !seatChangePending
        && ((seatFree && selectedSeats.length + seatsPendingHold.length < numberOfSeatsToSelect)
          || selectedSeats.includes(s.number));

      if (!seatFree) {
        seatColor = seatMatrixBusyColor;
        seatBorderColor = seatMatrixBusyColor;
        // color = blackTextColor;
        fontWeight = 'bold';
      }
      if (seatSelected && !seatChangePending) {
        color = seatInfo.selectedColor;
        seatBorderColor = color;
        seatColor = bgColorFromFullColor(color);

        // seatColor = seatInfo.selectedColor;
        // seatColor = highlightedWidgetBackgroundColor;
        // seatBorderColor = primaryColor;
        fontWeight = 'bold';
      }
      if (onSeatToggle && !selectable) {
        cursor = 'not-allowed';
      }
      return (
        <>
          <div
            key={`seat_${i}_${s.number}`}
            style={{
              margin,
              width,
              height,
              borderRadius: 5,
              border: `solid 1.5px ${seatBorderColor}`,
              backgroundColor: seatColor,
              visibility,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color,
              cursor,
              fontWeight,
            }}
            onClick={
              selectable
                ? () => {
                  onSeatToggle(s.number);
                }
                : undefined
            }
          >
            {seatChangePending ? (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    marginTop: '10%',
                    width: '50%',
                    height: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Loader type="Puff" width="100%" height="100%" color={primaryColor} />
                </div>
              </div>
            ) : (
              s.number || 'x'
            )}
          </div>
          {showRightSpace(i) && (
            <div
              key={`seat_${i}_space`}
              style={{
                margin,
                marginLeft: 0,
                marginRight: 0,
                width,
                height,
                borderRadius: 5,
                backgroundColor: seatColor,
                visibility: 'hidden',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: seatFree ? 'gray' : 'white',
              }}
            >
              {s.number}
            </div>
          )}
        </>
      );
    })}
  </div>
);
const SeatMatrix = ({
  seatInfoByPrice,
  seatMatrix,
  selectedSeats = [],
  seatsPendingHold = [],
  seatsPendingRelease = [],
  onSeatToggle = null,
  numberOfSeatsToSelect = 0,
  translator: t,
}) => {
  const maxWidth = 50;
  const width = Math.min(
    Math.round(toPx(`${Math.round((0.7 * 100) / (1 * seatMatrix.length))}vh`)),
    maxWidth,
  );
  const height = Math.round((4 * width) / 5);
  const margin = Math.round((1 * height) / 8);
  return (
    <div
      style={{
        padding: margin,
        margin: 15,
        backgroundColor: 'white',
        border: `solid 1.5px ${widgetBorderColor}`,
        borderRadius: '10px',
        width: 'fit-content',
        fontSize: '1.1rem',
        fontWeight: 'bold',
      }}
    >
      <div
        style={withDefaultFont({
          textAlign: 'center',
          color: discreetTextColor,
          marginTop: '5%',
          marginBottom: '5%',
          fontWeight: 600,
          fontSize: '1.05rem',
        })}
      >
        {t('Bus front')}
      </div>
      {seatMatrix.map((row, i) => (
        <SeatRowComponent
          key={`row_${i}`}
          seatInfoByPrice={seatInfoByPrice}
          seats={row}
          width={width}
          height={height}
          margin={margin}
          selectedSeats={selectedSeats}
          seatsPendingHold={seatsPendingHold}
          seatsPendingRelease={seatsPendingRelease}
          onSeatToggle={onSeatToggle}
          numberOfSeatsToSelect={numberOfSeatsToSelect}
        />
      ))}
    </div>
  );
};

export default SeatMatrix;
