import React from 'react';

import { appName } from '../../00_environment/Constants';
import Button from '../../01_atoms/Button';
import Bottom from '../../01_atoms/Bottom';
import Container from '../../01_atoms/Container';
import Element from '../../01_atoms/Element';
import ElementGroup from '../../01_atoms/ElementGroup';
import BlockWithLeftIcon from '../../01_atoms/BlockWithLeftIcon';
import PopupScreen from '../../02_molecules/PopupScreen';
import TitledContainer from '../../01_atoms/TitledContainer';
import ResponsiveCentralPaneWrapper from '../../02_molecules/ResponsiveCentralPaneWrapper';
import tickPic from '../../../assets/tick.svg';

const SignUpOkPageUI = ({ nextLabel, nextLink, translatorForNamespace }) => {
  const t = translatorForNamespace('SignUpOkPageUI');
  return (
    <PopupScreen title={t('Sign up')}>
      <ResponsiveCentralPaneWrapper>
        <TitledContainer title={t('Sign up')}>
          {/* <PlaceHolder text={t('Good to go!')} icon="check" /> */}
          <ElementGroup title={t('Account created')}>
            <Element>
              <BlockWithLeftIcon icon={tickPic}>
                <strong>{t('Welcome to {appName}!', { appName })}</strong>
                <br />
                <br />
                {t('Your account has been created successfully.')}
                <br />
                <br />
                {t('Start ordering your bus tickets right now!')}
              </BlockWithLeftIcon>
            </Element>
          </ElementGroup>
          <Bottom>
            <Container>
              <Button to={nextLink} primary fluid>
                {nextLabel}
              </Button>
            </Container>
          </Bottom>
        </TitledContainer>
      </ResponsiveCentralPaneWrapper>
    </PopupScreen>
  );
};

export default SignUpOkPageUI;
