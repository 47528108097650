import React from 'react';

import Bottom from '../01_atoms/Bottom';
import Button from '../01_atoms/Button';
import Container from '../01_atoms/Container';
import TitleBlock from '../01_atoms/TitleBlock';
import SeatMatrixAvailabilityLegend from '../01_atoms/SeatMatrixAvailabilityLegend';
import SeatSelectionWidget from '../02_molecules/SeatSelectionWidget';

const withTranslatedPropsNames = (WrappedComponent) => (props) => {
  const { numberOfPassengers: numberOfSeatsToSelect, trip, selectedTrip } = props;
  return (
    <WrappedComponent
      {...props}
      {...{ numberOfSeatsToSelect, selectedTrip: trip || selectedTrip }}
    />
  );
};

const withSeatSelectorProps = (WrappedComponent) => React.memo((props) => {
  const { sdk } = props;
  const ComponentWithSeatSelectorProps = sdk.seats.withSeatSelectorProps(WrappedComponent);

  console.log('Sending props to withSeatSelectorProps() --> ', props);

  return <ComponentWithSeatSelectorProps {...props} />;
});

// We cache the return value of withSeatSelectorProps() to avoid
// unmounting/remounting <WithSeatSelectorProps /> in loop
const wrappedCache = new Map();
const SeatSelector = React.memo(({ sdk, wrappedComponent, ...otherProps }) => {
  console.log('Rendering: SeatSelector');

  let Wrapped = null;
  if (wrappedCache.has(wrappedComponent)) {
    Wrapped = wrappedCache.get(wrappedComponent);
  } else {
    Wrapped = sdk.seats.withSeatSelectorProps(wrappedComponent);
    wrappedCache.set(wrappedComponent, Wrapped);
  }

  return <Wrapped key="wrapped-main-seats-sector-widget" sdk={sdk} {...otherProps} />;
});

const MainSeatsSelectorWidget = ({
  numberOfFloors,
  selectedFloor,
  onFloorChange,
  seatInfoByPrice,
  seatMatrix,
  selectedSeats,
  seatsPendingHold,
  seatsPendingRelease,
  areSeatsPendingValidation = false,
  onSeatToggle,
  numberOfSeatsToSelect,
  seatPrices,
  loading,
  seatsLeft,

  onConfirm = () => {},

  sdk: {
    i18n: { translatorForNamespace },
  },
}) => {
  console.log('Rendering: MainSeatsSelectorWidget');

  const t = translatorForNamespace('GenericTripSeatSelectionPageUI');

  let title = t('{n} seat{s} left to select', { n: seatsLeft, s: seatsLeft > 1 ? 's' : '' });
  let subtitle = t('Click on an empty seat to select it');
  if (seatsLeft === 0) {
    title = t('All seats selected');
    subtitle = t('You can unselect seats by clicking on them');
  }

  return (
    <>
      <TitleBlock title={title} subtitle={subtitle} />
      <SeatMatrixAvailabilityLegend translator={t} />
      <SeatSelectionWidget
        {...{
          numberOfFloors,
          selectedFloor,
          onFloorChange,
          seatInfoByPrice,
          seatMatrix,
          selectedSeats,
          seatsPendingHold,
          seatsPendingRelease,
          onSeatToggle,
          numberOfSeatsToSelect,
          seatPrices,
          translator: t,
          loading,
        }}
      />
      <Bottom noSpacer>
        <Container>
          <Button
            onClick={() => {
              onConfirm();
            }}
            fluid
            primary
            disabled={areSeatsPendingValidation || seatsLeft !== 0}
          >
            {areSeatsPendingValidation ? t('Checking seats availability...') : t('Book seats')}
          </Button>
        </Container>
      </Bottom>
    </>
  );
};

const WrappedMainSeatsSelectorWidget = (props) => (
  <SeatSelector wrappedComponent={MainSeatsSelectorWidget} {...props} />
);

export default WrappedMainSeatsSelectorWidget;
