import React from 'react';

import ResponsivePage from '../01_atoms/ResponsivePage';

import SinglePaneOrdersPage from './OrdersPage.mobile';
import DualPaneOrdersPage from './OrdersPage.widescreen';

const OrdersPage = ({ translatorForNamespace, ...props }) => {
  const t = translatorForNamespace('BookingFormPageUI');

  return (
    <ResponsivePage
      wideScreenComponent={DualPaneOrdersPage}
      mobileComponent={SinglePaneOrdersPage}
      {...props}
      {...{
        translatorForNamespace,
        t,
      }}
    />
  );
};

export default OrdersPage;
