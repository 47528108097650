import React from 'react';

import { primaryColor, widgetBorderColor } from '../00_environment/Colors';
import ElementGroup from '../01_atoms/ElementGroup';
import Element from '../01_atoms/Element';
import Icon from '../01_atoms/Icon';
import TitledContainer from '../01_atoms/TitledContainer';

const LanguageSettingsWidget = ({
  lang,
  supportedLanguages,
  onLangChange = () => {},
  onBack = () => {},
  translator: t,
}) => (
  <TitledContainer title={t('Language settings')}>
    <ElementGroup
      title={t('Select your preferred language')}
      titlePadding={10}
      backgroundColor="white"
    >
      {Object.keys(supportedLanguages).map((langKey) => (
        <Element
          onClick={() => {
            onLangChange(langKey);
            onBack();
          }}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <Icon
            name={lang === langKey ? 'check' : 'circle outline'}
            style={{
              visibility: 'visible', // lang === langKey ? 'visible' : 'hidden',
              color: lang === langKey ? primaryColor : widgetBorderColor,
              display: 'inline',
              marginRight: 15,
              marginBottom: 2,
            }}
          />
          <span>{supportedLanguages[langKey]}</span>
        </Element>
      ))}
    </ElementGroup>
  </TitledContainer>
);

export default LanguageSettingsWidget;
