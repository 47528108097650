import React from 'react';
import check from 'check-types';
import { Icon } from 'semantic-ui-react';
import moment from '../../../lib/moment';

import { withDefaultButtonFont } from '../../00_environment/Fonts';
import Button from '../../01_atoms/Button';
import LoadingPlaceHolder from '../../01_atoms/LoadingPlaceHolder';

import DefaultScreen from '../../02_molecules/DefaultScreen';
import RidesList from '../../02_molecules/RidesList';
import PlaceHolder from '../../02_molecules/PlaceHolder';

const GenericTripsListPage = (props) => {
  const {
    sdk,
    origin,
    destination,
    date,
    /* selectedTrip = null, */
    rides,
    isReturn,
    onTripSelect = () => {},
    onBack = () => {},
    numberOfPassengers,
  } = props;

  const {
    i18n: { translationManager, translatorForNamespace },
  } = sdk;

  const t = translatorForNamespace('GenericTripListPageUI');

  let content = null;

  // Set moment locale
  moment.locale(translationManager.getCurrentLanguage());

  const title = (
    <div style={{ display: 'inline', marginLeft: 5 }}>
      {`${isReturn ? t('Return') : t('Outbound')}: `}
      {sdk.terminals.nameFromId(origin)}
      <Icon name="arrow right" style={{ display: 'inline', marginLeft: 5 }} />
      {sdk.terminals.nameFromId(destination)}
    </div>
  );

  if (!rides) {
    content = <LoadingPlaceHolder />;
  } else if (check.emptyArray(rides)) {
    content = (
      <PlaceHolder
        title={t('No rides found for this trip')}
        icon="bus"
        content={(
          <Button onClick={onBack}>
            <Icon
              name="arrow left"
              style={{
                display: 'inline',
                lineHeight: withDefaultButtonFont({}).fontSize,
                marginRight: 5,
              }}
            />
            <span>{t('Try other dates')}</span>
          </Button>
        )}
      />
    );
  } else {
    // Display them
    content = (
      <RidesList
        rides={rides}
        numberOfPassengers={numberOfPassengers}
        onRideClick={onTripSelect}
        translator={t}
        title={title}
        onBack={onBack}
      />
    );
  }

  return (
    <DefaultScreen
      title={title}
      onBack={onBack}
      topBarRightChildren={
        <span style={{ marginRight: 20 }}>{moment(date).format('DD MMM YYYY')}</span>
      }
    >
      {content}
    </DefaultScreen>
  );
};

export default GenericTripsListPage;
