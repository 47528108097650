import React, { Component } from 'react';
import { paneMarginTop } from '../00_environment/Constants';
import { backgroundColor } from '../00_environment/Colors';
import { withDefaultFont } from '../00_environment/Fonts';
import LeftPaneWrapper from './LeftPaneWrapper';

class DualPanePage extends Component {
  constructor(...args) {
    super(...args);

    this.state = {
      scrollTop: 0,
    };
  }

  render() {
    const {
      leftComponent: LeftComponent,
      rightComponent: RightComponent,
      backgroundPicture = null,
      style,
      ...props
    } = this.props;

    const { scrollTop } = this.state;

    const wrapperStyle = {
      ...withDefaultFont({
        width: '100%',
        flex: '1',
        margin: 0,
        padding: 4,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'top',
        backgroundColor: backgroundPicture ? null : backgroundColor,
        // position: 'fixed',
        // overflowY: 'scroll',
      }),
      ...(backgroundPicture
        ? {
          backgroundImage: `url(${backgroundPicture})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }
        : {}),

      ...(style || {}),
    };

    console.log('Wrapper style: ', wrapperStyle);

    return (
      <>
        <div
          id="dual-pane-app-wrapper"
          onScroll={/* (e) => this.setState({ scrollTop: e.target.scrollTop }) */ null}
          style={wrapperStyle}
        >
          <div
            id="app-left-pane"
            style={{
              width: '40%',
              maxWidth: 480,
              marginTop: paneMarginTop,
              marginRight: 15,
              position: 'relative',
            }}
          >
            <LeftPaneWrapper>{LeftComponent && <LeftComponent {...props} />}</LeftPaneWrapper>
          </div>
          <div
            id="app-right-pane"
            style={{
              width: '40%',
              maxWidth: 480,
              marginTop: paneMarginTop,
              marginLeft: 15,
              minHeight: 100,
            }}
          >
            {RightComponent && <RightComponent scrollTop={scrollTop} {...props} />}
          </div>
        </div>
        {/*
          <div
            id="app-right-pane"
            style={withDefaultFont({
              position: 'fixed',
              width: '40%',
              left: '50%',
              // top: paneMarginTop,
              // maxHeight: '90%',
              maxWidth: 480,
              marginTop: paneMarginTop,
              marginLeft: 15,
              overflowY: 'visible',
            })}
          >
            {RightComponent && <RightComponent scrollTop={scrollTop} {...props} />}
          </div>
          */}
      </>
    );
  }
}

export default DualPanePage;
