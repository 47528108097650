import React from 'react';

import RightPaneWidgetFrame from '../01_atoms/RightPaneWidgetFrame';
import CovidInfoWidget from './CovidInfoWidget';

const CovidFormRightPane = ({
  scrollTop = 0,
  focusedLeftItemId = null,
  defaultValue,
  validateData = () => true,
  onSubmit = () => {},
  translatorForNamespace,
}) => (
  <RightPaneWidgetFrame scrollTop={scrollTop} focusedLeftItemId={focusedLeftItemId}>
    <CovidInfoWidget
      {...{
        defaultValue,
        validateData,
        onSubmit,
        translatorForNamespace,
      }}
    />
  </RightPaneWidgetFrame>
);

export default CovidFormRightPane;
