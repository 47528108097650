import React from 'react';

import DualPanePage from '../../01_atoms/DualPanePage';
import Page from '../../01_atoms/Page';
import TopBar from '../../01_atoms/TopBar';
import SettingsWidget from '../../03_organisms/SettingsWidget';

const DualPaneSettingsPage = ({
  loggedIn,
  selectedLanguage,
  version,
  card,
  savedPassengers = [],
  onEditPassengers = () => {},
  onConnect = () => {},
  // onLangChange = () => {},
  onLogOut = () => {},
  onBack = () => {},
  onDeleteCard = () => {},
  onDebugToggle = () => {},
  onLanguageEdit = () => {},
  isDebugOn = false,
  debugChannel = '<unset>',
  userEmail,
  userPassenger,
  onUserPassengerEdit = () => {},
  translatorForNamespace,
  translator: t,
}) => (
  <Page>
    <TopBar
      title={t('Settings')}
      leftIcon={onBack ? 'arrow left' : null}
      onLeftIconClick={onBack}
    />

    <DualPanePage
      leftComponent={SettingsWidget}
      rightComponent={null}
      {...{
        loggedIn,
        selectedLanguage,
        version,
        card,
        savedPassengers,
        onEditPassengers,
        onConnect,
        // onLangChange,
        onLogOut,
        onDeleteCard,
        onDebugToggle,
        onLanguageEdit,
        isDebugOn,
        debugChannel,
        userEmail,
        userPassenger,
        onUserPassengerEdit,
        translatorForNamespace,
      }}
    />
  </Page>
);
export default DualPaneSettingsPage;
