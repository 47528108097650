import React from 'react';
import moment from 'moment';
import MobileCalendar, { setMomentLocale } from '@seafront/react-scroll-calendar';
import '@seafront/react-scroll-calendar/build/react-scroll-calendar.css';

import Element from '../01_atoms/Element';
import ElementGroup from '../01_atoms/ElementGroup';
import TitledContainer from '../01_atoms/TitledContainer';

const DateSelectionComponent = ({
  title,
  minDay,
  maxDay,
  selectedDay,
  onChange,
  translationManager,
}) => {
  const lang = translationManager.getCurrentLanguage();

  // Set moment locale for MobileCalendar
  setMomentLocale(lang);
  moment.locale(lang);

  return (
    <TitledContainer title={title}>
      <ElementGroup style={{ marginTop: 0 }} backgroundColor="white">
        <Element>
          <MobileCalendar
            onSelect={({ startDate }) => onChange(startDate.format('YYYY-MM-DD'))}
            minDate={minDay ? moment(minDay, 'YYYY-MM-DD').locale(lang) : undefined}
            selectedDate={selectedDay ? moment(selectedDay, 'YYYY-MM-DD').locale(lang) : null}
            maxDate={maxDay ? moment(maxDay, 'YYYY-MM-DD').locale(lang) : undefined}
            weekStartsOnMonday={lang !== 'en'}
          />
        </Element>
      </ElementGroup>
    </TitledContainer>
  );
};

export default DateSelectionComponent;
