import React from 'react';
import { Form, Input } from 'semantic-ui-react';
import check from 'check-types';

import { COUNTRY_OPTIONS as countryOptions } from '../00_environment/countriesData';
import FormElementGroup from '../01_atoms/FormElementGroup';
import FormElement from '../01_atoms/FormElement';
import PhoneInput from '../01_atoms/PhoneInput';
import AddressFormComponent from './AddressFormComponent';

const EditPassengerFormUI = ({
  value = {},
  onChange = () => {},
  hasErrors = (/* ...fields */) => false,
  translatorForNamespace,
  config: rawConfig = {},
} = {}) => {
  // console.log('Rendering EditPassengerFormUI');

  const {
    firstName = '',
    lastName = '',
    ssn = '',
    country = 'EC',
    phone = '',
    email = '',
    address = {},
  } = value;

  const t = translatorForNamespace('EditPassengerForm');

  // Generate config from rawConfig
  const defaultItemConfig = () => ({ title: null, subtitle: null, show: true });
  const config = {
    name: defaultItemConfig(),
    email: { ...defaultItemConfig(), show: false },
    phone: defaultItemConfig(),
    ssn: defaultItemConfig(),
    address: { ...defaultItemConfig(), show: false },
  };
  if (check.nonEmptyObject(rawConfig)) {
    Object.keys(config).forEach((key) => {
      config[key] = { ...config[key], ...(rawConfig[key] || {}) };
    });
  }

  const applyChange = (change = {}) => onChange({ ...value, ...change });

  return (
    <>
      {config.name.show && (
        <FormElementGroup
          title={config.name.title || t('Passenger name')}
          subtitle={config.name.subtitle || t('Will display on your tickets')}
        >
          <FormElement>
            <Form.Group widths="equal">
              <Form.Input
                fluid
                placeholder={t('First name')}
                value={firstName || ''}
                onChange={(e, o) => applyChange({ firstName: o.value })}
                style={{ marginTop: 10, marginBottom: 10 }}
              />
              <Form.Input
                fluid
                placeholder={t('Last name')}
                value={lastName || ''}
                onChange={(e, o) => applyChange({ lastName: o.value })}
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                }}
              />
            </Form.Group>
          </FormElement>
        </FormElementGroup>
      )}

      {config.email.show && (
        <FormElementGroup
          title={config.email.title || t('E-mail')}
          subtitle={config.email.subtitle || null}
          noPadding
        >
          <FormElement>
            <Form.Group widths="equal">
              <Form.Input
                fluid
                placeholder={t('E-mail')}
                value={email || ''}
                onChange={(e, o) => applyChange({ email: o.value })}
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                }}
              />
            </Form.Group>
          </FormElement>
        </FormElementGroup>
      )}

      {config.phone.show && (
        <FormElementGroup
          title={config.phone.title || t('Phone number')}
          subtitle={config.phone.subtitle || t('Used to send ticket information')}
        >
          <FormElement>
            <Form.Group widths="equal">
              <PhoneInput
                placeholder={t('Mobile phone')}
                defaultCountry="ec"
                value={phone}
                onChange={(v, { countryCode }) => {
                  if (!check.nonEmptyString(country)) {
                    applyChange({ phone: v, country: countryCode });
                  } else {
                    applyChange({ phone: v });
                  }
                }}
                inputStyle={{
                  width: '100%',
                  height: 38,
                  marginTop: 10,
                  marginBottom: 10,
                  borderColor: '#22242626',
                  borderRadius: '5px',
                }}
              />
            </Form.Group>
          </FormElement>
        </FormElementGroup>
      )}

      {config.ssn.show && (
        <FormElementGroup
          title={config.ssn.title || t('Identification document')}
          subtitle={config.ssn.subtitle || t('Necessary to onboard the bus')}
        >
          <FormElement>
            <Form.Dropdown
              placeholder={t('Country')}
              fluid
              search
              selection
              options={countryOptions}
              value={country || 'ec'}
              onChange={(e, o) => applyChange({ country: o.value })}
              style={{
                marginTop: 10,
                marginBottom: 10,
              }}
            />
            <Input
              error={check.nonEmptyString(ssn) && hasErrors('ssn', 'cedula')}
              fluid
              placeholder={!country || country === 'ec' ? t('Cedula') : t('Passport number')}
              value={ssn || ''}
              onChange={(e, o) => applyChange({ ssn: o.value })}
              style={{
                marginTop: 10,
                marginBottom: 10,
              }}
            />
          </FormElement>
        </FormElementGroup>
      )}

      {config.address.show && (
        <FormElementGroup
          title={config.address.title || t('Address')}
          subtitle={config.address.subtitle || null}
        >
          <FormElement>
            <AddressFormComponent
              {...{
                title: null,
                style: {},
                address,
                onChange: (v) => applyChange({ address: v }),
                translatorForNamespace,
              }}
            />
          </FormElement>
        </FormElementGroup>
      )}
    </>
  );
};

export default EditPassengerFormUI;
