import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, List } from 'semantic-ui-react';

import { paneBackgroundColor, widgetBorderColor } from '../../00_environment/Colors';
import { roundedElementBorderRadius } from '../../00_environment/Constants';
import { withPaneTitleFont, withDefaultFont } from '../../00_environment/Fonts';
import Button from '../../01_atoms/Button';
import ResponsiveCentralPaneWrapper from '../../02_molecules/ResponsiveCentralPaneWrapper';
import Container from '../../01_atoms/Container';
import TitledContainer from '../../01_atoms/TitledContainer';

import DefaultScreen from '../../02_molecules/DefaultScreen';
import tickPic from '../../../assets/tick.svg';
import bookingPending from '../../../assets/booking-pending.svg';

const BookingConfirmedUI = ({
  pending = false,
  /* outboundTrip, returnTrip, */ createAccountLink,
  viewTicketsLink,
  loggedIn,
  translatorForNamespace,
}) => {
  const t = translatorForNamespace('BookingConfirmedUI');

  return (
    <DefaultScreen title={pending ? t('Order in process') : t('Order confirmed')}>
      <ResponsiveCentralPaneWrapper>
        <Container
          style={{
            backgroundColor: paneBackgroundColor,
            minHeight: '100%',
            paddingBottom: 30,
            textAlign: 'center',
          }}
        >
          <img
            alt=""
            src={pending ? bookingPending : tickPic}
            style={{ width: 80, marginRight: 20, marginTop: 30 }}
          />
          <div style={withPaneTitleFont({ marginTop: 20, whiteSpace: 'normal' })}>
            {pending ? t('Payment successful') : t('Order successful')}
          </div>
          {pending && (
            <>
              <div
                style={withPaneTitleFont({
                  fontWeight: 600,
                  fontSize: '1.1rem',
                  marginTop: 20,
                  whiteSpace: 'normal',
                })}
              >
                {t('We are now confirming your order with the Bus company.')}
              </div>
              <div
                style={withDefaultFont({ maxWidth: 350, marginLeft: 'auto', marginRight: 'auto' })}
              >
                {t('This process can take a few hours.')}
                {` ${t(
                  "We'll send you an e-mail with your bus tickets once your order is confirmed.",
                )}`}
                {' '}
                {t('Stay tuned!')}
              </div>
            </>
          )}
          {!pending && (
            <>
              <div
                style={withPaneTitleFont({
                  fontWeight: 600,
                  fontSize: '1.1rem',
                  marginTop: 20,
                  whiteSpace: 'normal',
                })}
              >
                {t("We've sent you an email with your tickets")}
              </div>
              <div
                style={withDefaultFont({ maxWidth: 350, marginLeft: 'auto', marginRight: 'auto' })}
              >
                {t('Thanks for booking your bus trip online!')}
              </div>
            </>
          )}

          <Link to={viewTicketsLink}>
            <div
              style={withDefaultFont({
                cursor: 'pointer',
                marginTop: 20,
              })}
            >
              <span style={{ textDecoration: 'underline', marginRight: 2 }}>
                {`${pending ? t('View my order') : t('View my tickets')}`}
              </span>
              <Icon name="arrow right" />
            </div>
          </Link>

          {!loggedIn && (
            <div
              style={withDefaultFont({
                marginTop: 40,
                backgroundColor: 'white',
                border: `solid 1px ${widgetBorderColor}`,
                borderRadius: roundedElementBorderRadius,
                textAlign: 'center',
                maxWidth: 450,
                marginLeft: 'auto',
                marginRight: 'auto',
              })}
            >
              <TitledContainer title={t('Order even faster next time!')} backgroundColor="white">
                <div
                  id="benefits-list"
                  style={{
                    maxWidth: 'max-content',
                    textAlign: 'left',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    left: '-5%',
                    right: '-5%',
                    position: 'relative',
                  }}
                >
                  <List>
                    <List.Item icon="checkmark" content={t('Save your passenger details')} />
                    <List.Item icon="checkmark" content={t('Pay in one click')} />
                    <List.Item icon="checkmark" content={t('Easily find your previous orders')} />
                  </List>
                </div>
                <Button style={{ marginTop: 40 }} to={createAccountLink} primary fluid={false}>
                  {t('Create your free account')}
                </Button>
              </TitledContainer>
            </div>
          )}
        </Container>
      </ResponsiveCentralPaneWrapper>
    </DefaultScreen>
  );
};

export default BookingConfirmedUI;
