import React from 'react';

import Button from '../01_atoms/Button';
import Element from '../01_atoms/Element';
import ElementGroup from '../01_atoms/ElementGroup';
import SelectedPassengers from './SelectedPassengers';

const PassengerDetails = ({
  title,
  subtitle,
  elementStyle,
  onEdit,
  onEditLink,
  highlightEditButton = false,
  passengers = [],
  translator: t = (txt) => txt,
  ...otherProps
} = {}) => (
  <ElementGroup title={title} subtitle={subtitle}>
    <Element style={elementStyle}>
      <SelectedPassengers passengers={passengers} {...otherProps} />

      {(onEditLink || onEdit) && (
        <Button
          id="edit-passengers-button"
          to={onEditLink}
          onClick={onEdit}
          fluid
          primary={highlightEditButton}
          style={{ marginTop: 20 }}
        >
          {passengers.length === 0 ? t('Select passengers') : t('Change passengers')}
        </Button>
      )}
    </Element>
  </ElementGroup>
);

export default PassengerDetails;
