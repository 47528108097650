import React from 'react';
import Icon from '../../01_atoms/Icon';
import DefaultScreen from '../../02_molecules/DefaultScreen';
import PassengerSelectionWidget from '../../02_molecules/PassengerSelectionWidget';

const PassengersSelectionUI = ({
  sdk,

  numberOfPassengers = 0,
  savedPassengers = [],
  selectedPassengers = [],

  onBack = () => {},
  onPassengerToggle = () => {},
  onAddPassenger = () => {},
  onConfirm = () => {},
}) => {
  const {
    i18n: { translatorForNamespace },
  } = sdk;
  const t = translatorForNamespace('PassengersSelectionUI');
  const title = t('Passengers in this trip');

  let subtitle = (
    <span>
      {t('We need to know the names of the passengers on this trip')}
      <br />
      {t('Please insert their details below')}
    </span>
  );

  if (savedPassengers.length > 0) {
    subtitle = (
      <span>
        {t('Use this screen to indicate who will be on this trip.')}
        <br />
        {t('{XofY} passengers confirmed', {
          XofY: `${selectedPassengers.length}/${numberOfPassengers}`,
        })}
      </span>
    );
  }

  const topBarRightChildren = (
    <div style={{ display: 'inline-flex', marginRight: 10 }}>
      <span>{`${selectedPassengers.length}/${numberOfPassengers}`}</span>
      <Icon style={{ marginLeft: 10 }} name="user" />
    </div>
  );

  return (
    <DefaultScreen
      title={t('Select passengers')}
      onBack={onBack}
      topBarRightChildren={topBarRightChildren}
    >
      <PassengerSelectionWidget
        {...{
          title,
          subtitle,
          translatorForNamespace,

          savedPassengers,
          selectedPassengers,
          numberOfPassengers,

          onPassengerToggle,
          onAddPassenger,
          onConfirm,

          translator: t,
        }}
      />
    </DefaultScreen>
  );
};

export default PassengersSelectionUI;
