import React, { Component } from 'react';
import check from 'check-types';
import { Icon } from 'semantic-ui-react';
import moment from '../../../lib/moment';
import isNative from '../../../lib/is-native';

import { withDefaultButtonFont } from '../../00_environment/Fonts';
import Button from '../../01_atoms/Button';
import DualPanePage from '../../01_atoms/DualPanePage';
import LoadingPlaceHolder from '../../01_atoms/LoadingPlaceHolder';

import DefaultScreen from '../../02_molecules/DefaultScreen';
import RidesList from '../../02_molecules/RidesList';
import PlaceHolder from '../../02_molecules/PlaceHolder';
import TicketDetailsCardWidget from '../../02_molecules/TicketDetailsCardWidget';

export default class DualPaneGenericTripsListPage extends Component {
  constructor(...args) {
    super(...args);

    this.state = {
      selectedTrip: null,
    };
  }

  componentDidMount() {
    if (!isNative() && check.nonEmptyString(window.location.hash)) {
      const id = window.location.hash.substr(1);
      if (check.nonEmptyString(id)) {
        const { rides } = this.props;
        const selectedRide = rides.find((r) => r.id === id);
        if (selectedRide) {
          this.setState({ selectedTrip: selectedRide });
        }
      }
    }
  }

  render() {
    const {
      sdk,
      origin,
      destination,
      date,
      /* selectedTrip = null, */
      rides,
      isReturn,
      onTripSelect = () => {},
      onBack = () => {},
      numberOfPassengers,
      isOneWayTrip,
    } = this.props;

    const { selectedTrip = null } = this.state;

    const {
      i18n: { translationManager, translatorForNamespace },
    } = sdk;

    const t = translatorForNamespace('GenericTripListPageUI');

    const title = (
      <div style={{ display: 'inline', marginLeft: 5 }}>
        {`${isReturn ? t('Return') : t('Outbound')}: `}
        {sdk.terminals.nameFromId(origin)}
        <Icon name="arrow right" style={{ display: 'inline', marginLeft: 5 }} />
        {`${sdk.terminals.nameFromId(destination)}`}
        &nbsp;&nbsp;&nbsp;
      </div>
    );

    let content = null;

    // Set moment locale
    moment.locale(translationManager.getCurrentLanguage());

    if (!rides) {
      content = <LoadingPlaceHolder />;
    } else if (check.emptyArray(rides)) {
      content = (
        <PlaceHolder
          title={t('No rides found for this trip')}
          icon="bus"
          content={(
            <Button onClick={onBack}>
              <Icon
                name="arrow left"
                style={{
                  display: 'inline',
                  lineHeight: withDefaultButtonFont({}).fontSize,
                  marginRight: 5,
                }}
              />
              <span>{t('Try other dates')}</span>
            </Button>
          )}
        />
      );
    } else {
      // Display them
      content = (
        <DualPanePage
          leftComponent={() => (
            <RidesList
              rides={rides}
              numberOfPassengers={numberOfPassengers}
              onRideClick={(trip) => {
                if (!isNative()) {
                  window.location.hash = trip.id;
                }

                this.setState({ selectedTrip: trip });
              }}
              translator={t}
              title={title}
              onBack={onBack}
            />
          )}
          rightComponent={
            selectedTrip
              ? ({ scrollTop }) => (
                <TicketDetailsCardWidget
                  sdk={sdk}
                  trip={selectedTrip}
                  focusedLeftItemId={`ride_${selectedTrip.id}`}
                  onConfirm={() => onTripSelect(selectedTrip)}
                  nPassengers={numberOfPassengers}
                  translator={t}
                  locale={translationManager.getCurrentLanguage()}
                  origin={origin}
                  destination={destination}
                  isReturn={isReturn}
                  isOneWayTrip={isOneWayTrip}
                  scrollTop={scrollTop}
                />
              )
              : null
          }
        />
      );
    }

    return (
      <DefaultScreen
        title={title}
        onBack={onBack}
        topBarRightChildren={
          <span style={{ marginRight: 20 }}>{moment(date).format('DD MMM YYYY')}</span>
        }
      >
        {content}
      </DefaultScreen>
    );
  }
}
