import React from 'react';

const Top = ({ children }) => (
  <div
    style={{
      width: '100%',
      // maxWidth: '900px',
      margin: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: 0,
      boxShadow: '0px -1px 6px 2px rgba(0,0,0,0.5)',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flex: '1',
      zIndex: 99999,
    }}
  >
    {children}
  </div>
);

export default Top;
