import React from 'react';

import { roundedElementBorderRadius } from '../00_environment/Constants';
import { topBarBgColor, widgetBorderColor } from '../00_environment/Colors';
import { withTopBarFont, withBlockHeaderRibbonFont } from '../00_environment/Fonts';

const BlockWithLeftHeader = ({
  ribbonContent,
  headerContent,
  style = {},
  noContentPadding = false,
  children,
}) => (
  <div
    style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      backgroundColor: 'white',
      ...style,
    }}
  >
    <div
      style={withTopBarFont({
        flexGrow: '1',
        flexShrink: '0',
        padding: '24px 0px',
        paddingBottom: 0,
        backgroundColor: topBarBgColor,
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        maxWidth: '100vw',
      })}
    >
      {ribbonContent && (
        <div
          style={withBlockHeaderRibbonFont({
            textAlign: 'center',
            marginTop: -4,
            marginBottom: 4,
          })}
        >
          {ribbonContent}
        </div>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {headerContent}
      </div>
    </div>
    <div
      style={{
        display: 'flex',
        flexGrow: '2',
        flexDirection: 'column',
        padding: noContentPadding ? 0 : 24,
        paddingTop: 24,
        paddingBottom: 24,
        border: `solid 1px ${widgetBorderColor}`,
        borderRadius: `0 ${roundedElementBorderRadius} ${roundedElementBorderRadius} 0`,
        maxWidth: '100vw',
      }}
    >
      {children}
    </div>
  </div>
);

export default BlockWithLeftHeader;
