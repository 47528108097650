import React from 'react';

import ResponsivePage from '../../01_atoms/ResponsivePage';

import SinglePaneSettingsPage from './SettingsPage.mobile';
import DualPaneSettingsPage from './SettingsPage.widescreen';

const SettingsPage = ({ translatorForNamespace, ...props }) => {
  const t = translatorForNamespace('SettingsPageUI');

  return (
    <ResponsivePage
      wideScreenComponent={DualPaneSettingsPage}
      mobileComponent={SinglePaneSettingsPage}
      {...props}
      {...{
        translatorForNamespace,
        translator: t,
      }}
    />
  );
};

export default SettingsPage;
