import React from 'react';
import { Link } from 'react-router-dom';
import { Input } from 'semantic-ui-react';
import Icon from './Icon';
import { widgetBorderColor } from '../00_environment/Colors';
import { roundedElementBorderRadius } from '../00_environment/Constants';

const ClosableInput = ({
  id,
  value,
  placeholder,
  valueLink = null,
  disabled = false,
  onClick = () => {},
  onClear = () => {},
  onChange = null,
}) => {
  const Clickable = !disabled && valueLink
    ? ({ style, children }) => (
      <Link to={valueLink} style={style}>
        {children}
      </Link>
    )
    : ({ style, children }) => (
      <div onClick={disabled ? () => {} : onClick} style={style}>
        {children}
      </div>
    );

  let inputComponent = (
    <Clickable
        style={{
          display: 'flex',
          alignItems: 'center',
          flex: '1',
          height: '100%',
          paddingLeft: 15,
          color: value ? 'rgba(0,0,0,.87)' : 'gray',
        }}
      >
        {value || placeholder}
      </Clickable>
  );

  if (onChange) {
    inputComponent = (
      <Input
        style={{
          display: 'flex',
          alignItems: 'center',
          flex: '1',
          height: '100%',
          paddingLeft: 15,
          color: value ? 'rgba(0,0,0,.87)' : 'gray',
        }}
        onFocus={disabled ? () => {} : onClick}
        transparent
        value={value} onChange={onChange} placeholder={placeholder} 
      />
    )
  }


  return (
    <div
      id={id}
      style={{
        cursor: 'pointer',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 38,
        backgroundColor: 'white',
        borderRadius: roundedElementBorderRadius,
        paddingLeft: 0,
        paddingRight: 0,
        marginBottom: 14,
        color: 'rgba(0,0,0,.87)',
        border: `solid 1px ${widgetBorderColor}`,
        ...(disabled
          ? {
            opacity: 0.6,
            cursor: 'not-allowed',
          }
          : {}),
      }}
    >
      {inputComponent}
      <div
        style={{
          height: '100%',
          paddingLeft: 20,
          paddingRight: 7,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          fontSize: '0.85em',
          cursor: 'pointer',
        }}
        onClick={value ? onClear : () => {}}
      >
        {value && <Icon name="close" color="grey" />}
      </div>
    </div>
  );
};

export default ClosableInput;
