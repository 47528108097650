import React from 'react';
import check from 'check-types';
import formatNumber from 'format-number';
import BuseaApi from '@seafront/busea.api';

import { serviceFeeName } from '../00_environment/Constants';
import ElementGroup from '../01_atoms/ElementGroup';
import KeyValueElement from '../01_atoms/KeyValueElement';

const formatPrice = (price) => formatNumber({ prefix: '$', round: 2, padRight: 2 })(price);

const TripPriceDetails = ({
  title,
  trip,
  numberOfPassengers,
  isOneWayTrip,
  translatorForNamespace,
  style = {},
}) => {
  const t = translatorForNamespace('OrderOrTripPriceDetails');
  const tripPricing = BuseaApi.getTripPricing(trip, numberOfPassengers, !isOneWayTrip);

  return (
    <ElementGroup style={style} title={title || t('Price details')}>
      <KeyValueElement slim title={t('Ticket price')} value={formatPrice(tripPricing.grossPrice)} />
      {check.positive(tripPricing.companyFee) && (
        <KeyValueElement
          slim
          grayed
          title={t('Company booking service')}
          value={formatPrice(tripPricing.companyFee)}
        />
      )}
      <KeyValueElement
        slim
        grayed
        title={t(serviceFeeName)}
        value={formatPrice(tripPricing.buseaFee)}
      />
      <KeyValueElement
        slim
        grayed
        title={t('Credit card payment fees')}
        value={formatPrice(tripPricing.paymentFee)}
      />
      <KeyValueElement
        title={<b>{t('TOTAL')}</b>}
        value={<b>{formatPrice(tripPricing.total)}</b>}
      />
    </ElementGroup>
  );
};

export default TripPriceDetails;
