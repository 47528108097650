import React from 'react';

import PlaceHolder from '../02_molecules/PlaceHolder';
import DefaultScreen from '../02_molecules/DefaultScreen';

const TicketNotFoundPageUI = ({ translatorForNamespace, onBack = () => {} }) => {
  const t = translatorForNamespace('TicketNotFoundPageUI');
  return (
    <DefaultScreen title={t('Ticket not found')} onBack={onBack}>
      <PlaceHolder icon="ticket" title={t('Ticket not found')} />
    </DefaultScreen>
  );
};

export default TicketNotFoundPageUI;
