import React from 'react';
import { Menu } from 'semantic-ui-react';
import { widgetBorderColor, widgetBackgroundColor } from '../00_environment/Colors';
import { withDefaultFont } from '../00_environment/Fonts';
import { roundedElementBorderRadius } from '../00_environment/Constants';
import TitleBlock from './TitleBlock';

const FormElementGroup = ({
  title,
  subtitle,
  children,
  slim = false,
  id,
  style,
  backgroundColor = null,
}) => {
  const parsedStyle = withDefaultFont({
    backgroundColor: backgroundColor || 'none',
    marginBottom: 30,
    marginTop: 6,
  });

  if (slim) {
    Object.assign(parsedStyle, {
      marginBottom: 0,
      marginTop: 0,
    });
  }

  return (
    <TitleBlock id={id} title={title} subtitle={subtitle} slim={slim} style={style} padding={10}>
      <div style={parsedStyle}>{children}</div>
    </TitleBlock>
  );
};

export default FormElementGroup;
