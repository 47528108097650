import React from 'react';

import DualPanePage from '../../01_atoms/DualPanePage';
import Page from '../../01_atoms/Page';
import TopBar from '../../01_atoms/TopBar';
import SettingsWidget from '../../03_organisms/SettingsWidget';
import AddPassengerRightPane from '../../02_molecules/AddPassengerRightPane';

const DualPaneAddPassengerPopup = ({ translator: t, onBack = () => {}, ...props }) => (
  <Page>
    <TopBar
      title={t('Add passenger')}
      leftIcon={onBack ? 'arrow left' : null}
      onLeftIconClick={onBack}
    />

    <DualPanePage
      leftComponent={SettingsWidget}
      rightComponent={({ sdk, onAdd = () => {}, translatorForNamespace }) => (
        <AddPassengerRightPane
          {...{
            sdk,
            onAdd,
            onBack,
            translatorForNamespace,
          }}
          showAllFields={false}
        />
      )}
      {...{
        translator: t,
        onBack,
        ...props,
      }}
    />
  </Page>
);
export default DualPaneAddPassengerPopup;
