import React from 'react';
import DualPaneOrderSetupPage from './07_OrderSetupPage.widescreen';

const DualPanePassengerDetailsPage = (props) => (
  <DualPaneOrderSetupPage
    defaultRightComponent="passenger-details"
    defaultFocusedLeftItemId="edit-passengers-button"
    {...props}
  />
);

export default DualPanePassengerDetailsPage;
