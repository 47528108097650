import React from 'react';
import DualPaneOrderSetupPage from './07_OrderSetupPage.widescreen';

const DualPaneBillingDetailsPage = (props) => (
  <DualPaneOrderSetupPage
    defaultRightComponent="billing-details"
    defaultFocusedLeftItemId="edit-billing-details-button"
    {...props}
  />
);

export default DualPaneBillingDetailsPage;
