import React from 'react';

import ResponsivePage from '../../01_atoms/ResponsivePage';

import SinglePaneBillingDetailsPage from './05_BillingDetailsPage.mobile';
import DualPaneBillingDetailsPage from './05_BillingDetailsPage.widescreen';

const BillingDetailsPage = ({ ...props }) => (
  <ResponsivePage
    wideScreenComponent={DualPaneBillingDetailsPage}
    mobileComponent={SinglePaneBillingDetailsPage}
    {...props}
  />
);

export default BillingDetailsPage;
