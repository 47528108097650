import React from 'react';
import DefaultScreen from '../../02_molecules/DefaultScreen';
import CovidInfoWidget from '../../02_molecules/CovidInfoWidget';

const CovidInfoPage = ({ sdk, onBack = () => {}, ...props }) => {
  const {
    i18n: { translatorForNamespace },
  } = sdk;
  const t = translatorForNamespace('CovidInfoPage');

  return (
    <DefaultScreen title={t('Covid information')} onBack={onBack}>
      <CovidInfoWidget translatorForNamespace={translatorForNamespace} sdk={sdk} {...props} />
    </DefaultScreen>
  );
};

export default CovidInfoPage;
