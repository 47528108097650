import React from 'react';

import Page from '../01_atoms/Page';
import HomeTopBar from '../02_molecules/HomeTopBar';
import OrdersListWidget from '../03_organisms/OrdersListWidget';

const OrdersPage = ({
  tickets,
  isFiltered,
  onUnfilter,
  translationManager,
  translatorForNamespace,
}) => {
  const t = translatorForNamespace('OrdersPageUI');
  return (
    <Page>
      <HomeTopBar translatorForNamespace={translatorForNamespace} selectedTab="orders" />
      <OrdersListWidget
        {...{
          tickets,
          isFiltered,
          onUnfilter,
          translationManager,
          translatorForNamespace,
          translator: t,
        }}
      />
    </Page>
  );
};
export default OrdersPage;
