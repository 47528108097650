import React from 'react';
import { Form, Message } from 'semantic-ui-react';

import Button from '../../../01_atoms/Button';
import DefaultScreen from '../../../02_molecules/DefaultScreen';
import FormContainer from '../../../01_atoms/FormContainer';
import TitledContainer from '../../../01_atoms/TitledContainer';
import ResponsiveCentralPaneWrapper from '../../../02_molecules/ResponsiveCentralPaneWrapper';

const LoginByPhoneFirstPage = ({
  error,
  phone,
  loading,
  onPhoneChange = () => {},
  onSubmit = () => {},
  onBack = () => {},
  translatorForNamespace,
}) => {
  const t = translatorForNamespace('SignInOrSignUpPageUI');
  const t2 = translatorForNamespace('LoginPageUI');
  const title = t2('Login');
  return (
    <DefaultScreen title={title} onBack={onBack}>
      <ResponsiveCentralPaneWrapper>
        <TitledContainer title={title} rightButtonText={t('Back')} onRightButtonClick={onBack}>
          <div id="firebaseui-auth-container" />
          {/*
            <FormContainer>
              <Form onSubmit={onSubmit} error={!!error}>
                {!!error && <Message error header={t('Error')} content={error} />}

                <Form.Field>
                  <Form.Input
                    key="LoginByPhone_1"
                    name="phone"
                    type="tel"
                    placeholder={t('Phone number')}
                    value={phone || ''}
                    onChange={(e, o) => onPhoneChange(o.value)}
                    disabled={loading}
                  />
                </Form.Field>
                <Button id="phone-sign-in-button" fluid primary>
                  {title}
                </Button>
              </Form>
            </FormContainer>
            */}
        </TitledContainer>
      </ResponsiveCentralPaneWrapper>
    </DefaultScreen>
  );
};

export default LoginByPhoneFirstPage;
