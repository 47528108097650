import React from 'react';
import { Responsive } from 'semantic-ui-react';

import ResponsivePage from '../../01_atoms/ResponsivePage';

import SinglePaneBookingFormPage from './01_BookingFormPage.mobile';
import DualPaneBookingFormPage from './01_BookingFormPage.widescreen';

const BookingFormPage = ({ translatorForNamespace, isIdle = false, ...props }) => {
  const t = translatorForNamespace('BookingFormPageUI');

  return (
    <ResponsivePage
      wideScreenComponent={DualPaneBookingFormPage}
      mobileComponent={SinglePaneBookingFormPage}
      isIdle={isIdle}
      {...props}
      {...{
        translatorForNamespace,
        t,
      }}
      {...(isIdle
        ? {
          // Reduce responsive threshold if we're inside an iFrame
          threshold: 770,
        }
        : {})}
    />
  );
};

export default BookingFormPage;
