import React, { PureComponent } from 'react';
import { Responsive } from 'semantic-ui-react';

class ResponsivePage extends PureComponent {
  constructor(...args) {
    super(...args);
    this.state = {
      showWidescreen: this.widthIsWideScreen(window.innerWidth),
      key: `responsive_${Math.random()}`,
    };

    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions() {
    this.setState({
      showWidescreen: this.widthIsWideScreen(window.innerWidth),
    });
  }

  widthIsWideScreen(width) {
    const { threshold = null } = this.props;
    const t = threshold || Responsive.onlyTablet.maxWidth;

    return width > t;
  }

  render() {
    const {
      mobileComponent: MobileComponent,
      wideScreenComponent: WideScreenComponent,
      threshold,
      ...props
    } = this.props;
    const { showWidescreen, key } = this.state;

    if (showWidescreen) {
      return <WideScreenComponent key={key} isMobile={false} {...props} />;
    }

    return <MobileComponent key={key} isMobile {...props} />;
  }
}

export default ResponsivePage;
