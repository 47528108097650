import React from 'react';

import ResponsivePage from '../../01_atoms/ResponsivePage';

import SinglePaneLanguageSettingsPage from './LanguageSettingsPage.mobile';
import DualPaneLanguageSettingsPage from './LanguageSettingsPage.widescreen';

const LanguageSettingsPage = ({ sdk, ...props }) => {
  const {
    i18n: { translatorForNamespace },
  } = sdk;

  const t = translatorForNamespace('LanguageSettingsPageUI');

  return (
    <ResponsivePage
      wideScreenComponent={DualPaneLanguageSettingsPage}
      mobileComponent={SinglePaneLanguageSettingsPage}
      {...props}
      {...{
        sdk,
        translator: t,
      }}
    />
  );
};

export default LanguageSettingsPage;
