import React from 'react';

import DefaultScreen from '../../02_molecules/DefaultScreen';
import SettingsWidget from '../../03_organisms/SettingsWidget';

const MobileSettingsPage = ({
  loggedIn,
  selectedLanguage,
  version,
  card,
  savedPassengers = [],
  onEditPassengers = () => {},
  onConnect = () => {},
  // onLangChange = () => {},
  onLogOut = () => {},
  onBack = () => {},
  onDeleteCard = () => {},
  onDebugToggle = () => {},
  onLanguageEdit = () => {},
  isDebugOn = false,
  debugChannel = '<unset>',
  userEmail,
  userPassenger,
  onUserPassengerEdit = () => {},
  translatorForNamespace,
  translator: t,
}) => (
  <DefaultScreen title={t('Settings')} onBack={onBack}>
    <SettingsWidget
      {...{
        loggedIn,
        selectedLanguage,
        version,
        card,
        savedPassengers,
        onEditPassengers,
        onConnect,
        // onLangChange,
        onLogOut,
        onDeleteCard,
        onDebugToggle,
        onLanguageEdit,
        isDebugOn,
        debugChannel,
        userEmail,
        userPassenger,
        onUserPassengerEdit,
        translatorForNamespace,
      }}
    />
  </DefaultScreen>
);
export default MobileSettingsPage;
