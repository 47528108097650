import React from 'react';

import LoadingScreen from '../../02_molecules/LoadingScreen';

const ProcessingPayementLoaderPageUI = ({ translatorForNamespace }) => {
  const t = translatorForNamespace('ProcessingPayementLoaderPageUI');
  return <LoadingScreen text={t('Processing your order')} />;
};

export default ProcessingPayementLoaderPageUI;
