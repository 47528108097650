import React from 'react';
import { Form, Message } from 'semantic-ui-react';

import { withWidgetSubtitleFont } from '../../../00_environment/Fonts';
import Button from '../../../01_atoms/Button';
import LinkButton from '../../../01_atoms/LinkButton';
import DefaultScreen from '../../../02_molecules/DefaultScreen';
import FormContainer from '../../../01_atoms/FormContainer';
import TitledContainer from '../../../01_atoms/TitledContainer';
import ResponsiveCentralPaneWrapper from '../../../02_molecules/ResponsiveCentralPaneWrapper';

const LoginByPhoneSecondPage = ({
  error,
  phone,
  code,
  loading,
  onCodeChange = () => {},
  onEditPhone = () => {},
  onSubmit = () => {},
  onBack = () => {},
  translatorForNamespace,
}) => {
  const t = translatorForNamespace('SignInOrSignUpPageUI');
  const t2 = translatorForNamespace('LoginPageUI');
  const title = t2('Login');
  return (
    <DefaultScreen title={title} onBack={onBack}>
      <ResponsiveCentralPaneWrapper>
        <TitledContainer title={title} rightButtonText={t('Back')} onRightButtonClick={onBack}>
          <FormContainer>
            <Form onSubmit={onSubmit} error={!!error}>
              {!!error && <Message error header={t('Error')} content={error} />}

              <Form.Field>
                <Form.Input
                  key="LoginByPhone_2"
                  name="phone"
                  type="text"
                  placeholder={t('Confirmation code')}
                  value={code || ''}
                  onChange={(e, o) => onCodeChange(o.value)}
                  disabled={loading}
                />
              </Form.Field>
              <Button fluid type="submit" primary>
                {t('Confirm phone number')}
              </Button>
            </Form>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                marginTop: 10,
              }}
            >
              <LinkButton
                onClick={onEditPhone}
                style={withWidgetSubtitleFont({ fontSize: '0.9rem' })}
              >
                {t('Wrong phone number?')}
              </LinkButton>
            </div>
          </FormContainer>
        </TitledContainer>
      </ResponsiveCentralPaneWrapper>
    </DefaultScreen>
  );
};

export default LoginByPhoneSecondPage;
