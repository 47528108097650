import React from 'react';

import LoadingScreen from '../../02_molecules/LoadingScreen';

const LogginInPageUI = ({ translatorForNamespace }) => {
  const t = translatorForNamespace('LogginInPageUI');
  return <LoadingScreen text={t('Signing you in')} />;
};

export default LogginInPageUI;
