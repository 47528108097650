import React from 'react';
import MobileStoreButton from 'react-mobile-store-button';

import Button from '../01_atoms/Button';
import Bottom from '../01_atoms/Bottom';
import Page from '../01_atoms/Page';
import PopupScreen from '../02_molecules/PopupScreen';
import TitledContainer from '../01_atoms/TitledContainer';
import ResponsiveCentralPaneWrapper from '../02_molecules/ResponsiveCentralPaneWrapper';
import BoardingPass from '../03_organisms/BoardingPass';

const TicketBoardingPassPageUI = ({
  ticket,
  qrCodeData,
  onBack,
  passengerIndex,
  translatorForNamespace,

  inPopup = true,
  showCarouselControls = true,
  showAppStoreButtons = false,
  appleStoreLink = null,
  googlePlayLink = null,
}) => {
  const t = translatorForNamespace('TicketBoardingPassPageUI');

  const ContainerComponent = inPopup ? PopupScreen : Page;

  return (
    <ContainerComponent title={t('Boarding Pass')} onBack={onBack}>
      <ResponsiveCentralPaneWrapper>
        <TitledContainer
          title={t('Boarding pass')}
          rightButtonText={onBack && t('Back to ticket details')}
          onRightButtonClick={onBack && onBack}
        >
          <BoardingPass
            t={t}
            ticket={ticket}
            passengerIndex={passengerIndex}
            backgroundColor="#fff"
            qrCodeData={qrCodeData}
          />

          <Bottom>
            {showCarouselControls && ticket.ticketDetails.passengers.length > 1 && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                }}
              >
                <Button
                  disabled={passengerIndex === 0}
                  to={`/tickets/${ticket.id}/boarding-pass/${Math.max(0, passengerIndex - 1)}/`}
                >
                  ←
                </Button>
                <Button
                  disabled={passengerIndex === ticket.ticketDetails.passengers.length - 1}
                  to={`/tickets/${ticket.id}/boarding-pass/${passengerIndex + 1}/`}
                >
                  →
                </Button>
              </div>
            )}

            {showAppStoreButtons && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  bottom: '10px',
                  width: '100%',
                }}
              >
                <MobileStoreButton
                  store="ios"
                  url={appleStoreLink}
                  linkProps={{ title: 'iOS Store Button' }}
                  height={43}
                  width={170}
                />
                <MobileStoreButton
                  store="android"
                  url={googlePlayLink}
                  linkProps={{ title: 'Android Store Button' }}
                  height={65}
                  width={170}
                />
              </div>
            )}
          </Bottom>
        </TitledContainer>
      </ResponsiveCentralPaneWrapper>
    </ContainerComponent>
  );
};

export default TicketBoardingPassPageUI;
