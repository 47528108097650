import React from 'react';
import check from 'check-types';
import { Message } from 'semantic-ui-react';
import { ucfirstEachWord } from '@seafront/ucfirst';
import BuseaApi from '@seafront/busea.api';
import formatNumber from 'format-number';

import { appName } from '../00_environment/Constants';
import { grayElementColor, discreetTextColor } from '../00_environment/Colors';
import Element from '../01_atoms/Element';
import ElementGroup from '../01_atoms/ElementGroup';
import Container from '../01_atoms/Container';
import Bottom from '../01_atoms/Bottom';
import Button from '../01_atoms/Button';
import HighligthedText from '../01_atoms/HighligthedText';
import Icon from '../01_atoms/Icon';
import BlockWithHeader from '../01_atoms/BlockWithHeader';
import TitledContainer from '../01_atoms/TitledContainer';
import PassengerDetails from './PassengerDetails';
import TicketDetails from './TicketDetails';
import TripPriceDetails from './TripPriceDetails';
import TripSeatDetails from './TripSeatDetails';

const formatPrice = (price) => formatNumber({ prefix: '$', round: 2, padRight: 2 })(price);

const TicketWidget = ({ ticket, translationManager, translatorForNamespace }) => {
  const t = translatorForNamespace('TicketDetailsPageUI');

  const {
    ticketDetails,
    statusDetails: { booked = true, cancelled = false },
  } = ticket;

  const dt = ucfirstEachWord(ticketDetails.originTerminal.name);
  const at = ucfirstEachWord(ticketDetails.destinationTerminal.name);
  const title = (
    <div style={{ display: 'inline', marginLeft: 5 }}>
      {dt}
      <Icon name="arrow right" style={{ display: 'inline', marginLeft: 5 }} />
      {at}
    </div>
  );

  const numberOfPassengers = ticket.ticketDetails.passengers.length;
  const tripPricing = BuseaApi.getTripPricing(
    ticket.ticketDetails,
    numberOfPassengers,
    ticket.ticketDetails.twoWaysTrip,
  );
  const readablePrice = formatPrice(tripPricing.total);

  return (
    <TitledContainer title={t('Trip details')}>
      <BlockWithHeader
        ribbonContent={title}
        headerContent={(
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
              textAlign: 'left',
            }}
          >
            <div
              style={{
                fontSize: '1.6rem',
                fontWeight: '600',
                marginTop: 5,
                marginBottom: 2,
              }}
            >
              {readablePrice}
            </div>
            <div style={{ fontSize: '0.9rem', fontWeight: 'normal' }}>
              {`${numberOfPassengers} ${t('passenger')}${numberOfPassengers > 1 ? 's' : ''}`}
            </div>
          </div>
        )}
      >
        {!booked && !cancelled && (
          <div
            style={{
              margin: -5,
              marginTop: -2,
              marginBottom: 0,
            }}
          >
            <Message warning icon style={{ paddingTop: 30, paddingBottom: 30 }}>
              <Icon name="wait" style={{ fontSize: '32px' }} />
              {/*  <Image avatar src={bookingPending} style={{ marginRight: 10 }} /> */}

              <Message.Content style={{ fontSize: '0.9rem' }}>
                <Message.Header style={{ fontSize: '1.1rem' }}>
                  {t('Order in process')}
                </Message.Header>
                {t('We are contacting the company to book your trip.')}

                {t("You'll receive an email once your trip is confirmed.")}
              </Message.Content>
            </Message>
          </div>
        )}

        {cancelled && (
          <div
            style={{
              margin: -5,
              marginTop: -2,
              marginBottom: 0,
            }}
          >
            <Message negative icon style={{ paddingTop: 30, paddingBottom: 30 }}>
              <Icon name="cancel" style={{ fontSize: '32px' }} />
              {/*  <Image avatar src={bookingPending} style={{ marginRight: 10 }} /> */}

              <Message.Content style={{ fontSize: '0.9rem' }}>
                <Message.Header style={{ fontSize: '1.1rem' }}>
                  {t('This order has been cancelled')}
                </Message.Header>
                {t('Details on this page are only informative.')}
              </Message.Content>
            </Message>
          </div>
        )}

        <TicketDetails
          title={t('Your trip')}
          ticketDetails={ticket.ticketDetails}
          nPassengers={
            check.nonEmptyArray(ticket.ticketDetails.passengers)
              ? ticket.ticketDetails.passengers.length
              : 1
          }
          locale={translationManager.getCurrentLanguage()}
        />

        <PassengerDetails
          title={t('Passengers')}
          translatorForNamespace={translatorForNamespace}
          passengers={ticket.ticketDetails.passengers}
        />

        <TripSeatDetails
          selectedSeats={(ticket.ticketDetails.passengers || []).map((p) => p.seat)}
          numberOfPassengers={(ticket.ticketDetails.passengers || []).length}
          translator={t}
        />

        <TripPriceDetails
          trip={ticket.ticketDetails}
          numberOfPassengers={ticket.ticketDetails.passengers.length}
          isOneWayTrip={!ticket.ticketDetails.twoWaysTrip}
          translatorForNamespace={translatorForNamespace}
        />

        {/*  <ElementGroup title="Route details">
        <Element>
          <Button to={`/tickets/${ticket.id}/stops/`} fluid>
            Show route
          </Button>
        </Element>
      </ElementGroup>
      */}

        <ElementGroup>
          <Element>
            {t('Tickets are nominative.')}
            <br />
            {t("You'll need to present your")}
            {' '}
            <HighligthedText>{t('cedula or passport')}</HighligthedText>
            {' '}
            {t('along with your ticket before boarding.')}
          </Element>

          <Element>
            {t("You'll receive your tickets")}
            {' '}
            <HighligthedText>{`${t('by e-mail')}.`}</HighligthedText>
            <br />
            {t('You can present your tickets either on the {appName} app or printed on paper.', {
              appName,
            })}
          </Element>
        </ElementGroup>

        <Bottom>
          <Container>
            {booked && !cancelled && (
              <Button to={`/tickets/${ticket.id}/boarding-pass/`} fluid primary>
                {t('Show ticket')}
              </Button>
            )}
            {!booked && !cancelled && (
              <div
                style={{
                  backgroundColor: 'none',
                  border: `dashed 2px ${grayElementColor}`,
                  color: discreetTextColor,
                  fontWeight: 'normal',
                  fontSize: '1rem',
                  lineHeight: '1.4',
                  minHeight: '1em',
                  verticalAlign: 'baseline',
                  margin: '0 .25em 0 0',
                  padding: '0.8em 1.5em 0.8em',
                  textAlign: 'center',
                }}
                fluid
                primary
              >
                {t('Ticket not available yet')}
              </div>
            )}
          </Container>
        </Bottom>
      </BlockWithHeader>
    </TitledContainer>
  );
};

export default TicketWidget;
