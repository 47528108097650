import React from "react";
import check from "check-types";

import { withPlaceholderFont } from "../00_environment/Fonts";
import ElementGroup from "../01_atoms/ElementGroup";
import Bottom from "../01_atoms/Bottom";
import Container from "../01_atoms/Container";
import Button from "../01_atoms/Button";
import KeyValueElement from "../01_atoms/KeyValueElement";
import PlaceHolderElement from "../01_atoms/PlaceHolderElement";
import Icon from "../01_atoms/Icon";
import PaymentIcon from "../01_atoms/PaymentIcon";
import TitledContainer from "../01_atoms/TitledContainer";

const SettingsPageUI = ({
  loggedIn,
  selectedLanguage,
  version,
  card,
  savedPassengers = [],
  onEditPassengers = () => {},
  onConnect = () => {},
  // onLangChange = () => {},
  onLogOut = () => {},
  onBack = () => {},
  onDeleteCard = () => {},
  onDebugToggle = () => {},
  onLanguageEdit = () => {},
  isDebugOn = false,
  debugChannel = "<unset>",
  userEmail,
  userPassenger,
  onUserPassengerEdit = () => {},
  translatorForNamespace,
}) => {
  const t = translatorForNamespace("SettingsPageUI");

  let passengerSectionTitle = t("No passenger saved");
  if (savedPassengers.length === 1) {
    passengerSectionTitle = t("1 passenger saved");
  } else if (savedPassengers.length > 1) {
    passengerSectionTitle = t("{x} passengers saved", {
      x: savedPassengers.length,
    });
  }

  return (
    <TitledContainer title={t("Settings")}>
      <ElementGroup
        title={t("About you")}
        titlePadding={10}
        backgroundColor="white"
      >
        {loggedIn && check.nonEmptyString(userEmail) && (
          <KeyValueElement
            title={t("E-mail:")}
            value={<span style={{ color: "gray" }}>{userEmail}</span>}
          />
        )}
        <KeyValueElement
          title={t("Passenger information:")}
          onClick={onUserPassengerEdit}
          value={
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <span style={{ color: "gray" }}>
                {userPassenger
                  ? `${userPassenger.firstName} ${userPassenger.lastName} (${userPassenger.ssn})`
                  : t("Add")}
              </span>
              <Icon
                name="angle right"
                style={{
                  color: "gray",
                  cursor: "pointer",
                  marginLeft: 15,
                  marginBottom: 1,
                }}
              />
            </div>
          }
        />
        <KeyValueElement
          onClick={onLanguageEdit}
          title={t("Language:")}
          value={
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <span style={{ color: "gray" }}>{selectedLanguage}</span>
              <Icon
                name="angle right"
                style={{
                  color: "gray",
                  cursor: "pointer",
                  marginLeft: 15,
                  marginBottom: 1,
                }}
              />
            </div>
          }
        />
      </ElementGroup>
      <ElementGroup
        title={t("Your payment information")}
        titlePadding={10}
        backgroundColor="white"
      >
        {!card && (
          <PlaceHolderElement>
            {t("No credit or debit card saved")}
          </PlaceHolderElement>
        )}
        {card && (
          <KeyValueElement
            title={
              <div
                style={withPlaceholderFont({
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                })}
              >
                <PaymentIcon
                  id={card.brand.toLowerCase()}
                  style={{
                    display: "inline",
                    height: "1.2rem",
                    marginRight: 10,
                  }}
                />
                {`•••• •••• •••• ${card.last4}`}
              </div>
            }
            value={
              <Icon
                name="close"
                style={{
                  color: "gray",
                  cursor: "pointer",
                }}
                onClick={onDeleteCard}
              />
            }
          />
        )}
      </ElementGroup>
      <ElementGroup
        title={t("Passenger information")}
        subtitles={t("Super useful to avoid entering data twice")}
        titlePadding={10}
        backgroundColor="white"
      >
        {/* (
        <PlaceHolderElement>No passenger saved yet</PlaceHolderElement>
      ) */}

        <KeyValueElement
          onClick={onEditPassengers}
          title={passengerSectionTitle}
          value={
            <Icon
              name="angle right"
              style={{
                color: "gray",
                cursor: "pointer",
              }}
            />
          }
        />
      </ElementGroup>
      <ElementGroup
        title={t("App information")}
        titlePadding={10}
        backgroundColor="white"
      >
        <KeyValueElement
          title={t("Version:")}
          value={<span style={{ color: "gray" }}>{version}</span>}
        />
        {/*
          <KeyValueElement
            title={t('Debug channel:')}
            value={<span style={{ color: 'gray' }}>{isDebugOn ? debugChannel : t('Disabled')}</span>}
            onClick={onDebugToggle}
          />
          */}

        {/*
      <Element>
        Version:
        {version}
      </Element>
      */}
      </ElementGroup>
      <Bottom>
        <Container>
          <Button
            fluid
            primary={!loggedIn}
            color={loggedIn ? "google plus" : null}
            onClick={loggedIn ? onLogOut : onConnect}
          >
            {loggedIn ? t("Log out") : t("Log in")}
          </Button>
        </Container>
      </Bottom>
    </TitledContainer>
  );
};

export default SettingsPageUI;
