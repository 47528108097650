import React from 'react';
import { Header, Image } from 'semantic-ui-react';

import { appName } from '../00_environment/Constants';
import { withDefaultFont, withBoldTitleFont } from '../00_environment/Fonts';
import Page from '../01_atoms/Page';
import Bottom from '../01_atoms/Bottom';
import Container from '../01_atoms/Container';
import Button from '../01_atoms/Button';
import LinkButton from '../01_atoms/LinkButton';
import logo from '../../assets/logo';

const WelcomePageUI = ({
  translatorForNamespace, connectLink, registerLink, bookLink,
}) => {
  const t = translatorForNamespace('WelcomePageUI');
  return (
    <Page>
      <div
        style={{
          flex: '1',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Image src={logo} size="small" verticalAlign="middle" />
        <Header style={withBoldTitleFont({})}>
          {t('Welcome to the {appName} app', { appName })}
        </Header>
        <div style={withDefaultFont({ textAlign: 'center' })}>
          {t("Let's book a trip right now!")}
        </div>
      </div>
      <Bottom>
        <Container
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: '5%',
          }}
        >
          <Button to={bookLink} primary fluid style={{ marginRight: '2.5%' }}>
            {t('Book a trip')}
          </Button>
          <Button
            to={connectLink}
            fluid
            style={{
              marginLeft: '2.5%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {t('Log in')}
          </Button>
        </Container>

        <Container style={{ textAlign: 'center' }}>
          <LinkButton to={registerLink}>{t('Create an account')}</LinkButton>
        </Container>
      </Bottom>
    </Page>
  );
};

export default WelcomePageUI;
