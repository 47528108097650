import React from 'react';

import ResponsivePage from '../../01_atoms/ResponsivePage';

import SinglePanePassengerDetailsAddPage from '../settings/AddPassengerPopup.mobile';
import DualPanePassengerDetailsAddPage from './04_PassengerDetailsAddPage.widescreen';

const PassengerDetailsAddPage = ({ ...props }) => {
  const {
    sdk: {
      i18n: { translatorForNamespace },
    },
  } = props;

  const t = translatorForNamespace('AddPassengerPopupUI');

  return (
    <ResponsivePage
      wideScreenComponent={DualPanePassengerDetailsAddPage}
      mobileComponent={SinglePanePassengerDetailsAddPage}
      {...props}
      translator={t}
      translatorForNamespace={translatorForNamespace}
    />
  );
};

export default PassengerDetailsAddPage;
