import React from 'react';
import Element from './Element';
import Title from './Title';
import Subtitle from './Subtitle';
import { discreetTextColor } from '../00_environment/Colors';

const KeyValueElement = ({
  title,
  subtitle,
  value,
  children,
  slim = false,
  style,
  grayed,
  header = null,
  ...rest
}) => (
  <Element {...rest} style={style} slim={slim}>
    {header}
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: slim ? 0 : 5,
        marginBottom: children ? 15 : slim ? 0 : 5,
      }}
    >
      {(title || subtitle) && (
        <div style={{ flex: '1' }}>
          {title && (
            <Title
              style={({
                paddingLeft: 2,
                fontSize: '1rem',
                ...(grayed ? { color: discreetTextColor } : {}),
              })}
            >
              {title}
            </Title>
          )}
          {subtitle && (
            <Subtitle
              style={({ paddingLeft: 2, ...(grayed ? { color: discreetTextColor } : {}) })}
            >
              {subtitle}
            </Subtitle>
          )}
        </div>
      )}

      {value && (
        <div
          style={({ fontSize: '1rem', ...(grayed ? { color: discreetTextColor } : {}) })}
        >
          {value}
        </div>
      )}
    </div>

    {children}
  </Element>
);

export default KeyValueElement;
