import React from 'react';

import DualPanePage from '../../01_atoms/DualPanePage';
import Page from '../../01_atoms/Page';
import TopBar from '../../01_atoms/TopBar';
import EditOnePassengerRightPane from '../../03_organisms/EditOnePassengerRightPane';
import SettingsWidget from '../../03_organisms/SettingsWidget';

const DualPaneEditPassengerPopup = ({
  translator: t,
  onBack = () => {},
  onEdit = () => {},
  onDelete = () => {},
  passenger = {},
  ...props
}) => (
  <Page>
    <TopBar
      title={t('Edit passenger')}
      leftIcon={onBack ? 'arrow left' : null}
      onLeftIconClick={onBack}
    />

    <DualPanePage
      leftComponent={SettingsWidget}
      rightComponent={({ sdk, translatorForNamespace }) => (
        <EditOnePassengerRightPane
          {...{
            sdk,
            translatorForNamespace,

            defaultValue: passenger,

            submitButtonLabel: t('Save'),
            onSubmit: onEdit,

            leftButtonLabel: t('Delete'),
            onLeftButtonClick: onDelete,
            leftButtonColor: 'google plus',

            config: {
              name: {
                title: t('Passenger name'),
                subtitle: t('Will display on your tickets'),
                show: true,
              },
              phone: {
                title: t('Phone number'),
                subtitle: t('Used to send ticket information'),
                show: true,
              },
              ssn: {
                title: t('Identification document'),
                subtitle: t('Necessary to onboard the bus'),
                show: true,
              },

              email: {
                show: true,
              },
              address: {
                show: true,
              },
            },
          }}
        />
      )}
      {...{
        translator: t,
        onBack,
        ...props,
      }}
    />
  </Page>
);
export default DualPaneEditPassengerPopup;
