import React, { Component } from 'react';
import check from 'check-types';
import animateScrollTo from 'animated-scroll-to';

import DualPanePage from '../01_atoms/DualPanePage';
import isNative from '../../lib/is-native';

export default class DualPanePageWithSelector extends Component {
  constructor(...args) {
    super(...args);

    this.state = {
      shownOnRight: null,
      focusedLeftItemId: null,
    };

    this.showOnRight = this.showOnRight.bind(this);
  }

  componentDidMount() {
    if (!isNative() && check.nonEmptyString(window.location.hash)) {
      // Show component mentionned in URL hash if any
      const hash = window.location.hash.substr(1);
      const [id, focusedLeftItemId = null] = hash.split('/');
      const { rightComponentByName } = this.props;
      const { shownOnRight } = this.state;

      // Don't do anything if there's already something shown on right pane
      if (shownOnRight) {
        return;
      }

      // Else, if hash represents an existing component, select it
      if (check.nonEmptyString(id) && rightComponentByName && rightComponentByName[id]) {
        this.setState({
          shownOnRight: id,
          focusedLeftItemId: check.nonEmptyString(focusedLeftItemId) ? focusedLeftItemId : null,
        });
      }
    } else {
      this.selectDefaultComponentIfAppropriate();
    }
  }

  componentDidUpdate(prevProps) {
    const { defaultRightComponent, defaultFocusedLeftItemId } = this.props;

    if (
      prevProps.defaultRightComponent !== defaultRightComponent
      || prevProps.defaultFocusedLeftItemId !== defaultFocusedLeftItemId
    ) {
      this.selectDefaultComponentIfAppropriate();
    }
  }

  getRightComponent() {
    const { rightComponentByName } = this.props;
    const { shownOnRight } = this.state;

    if (
      !check.nonEmptyObject(rightComponentByName)
      || !check.nonEmptyString(shownOnRight)
      || !rightComponentByName[shownOnRight]
    ) {
      return () => null;
    }

    return rightComponentByName[shownOnRight];
  }

  selectDefaultComponentIfAppropriate() {
    const { defaultRightComponent, defaultFocusedLeftItemId, rightComponentByName } = this.props;
    if (
      check.nonEmptyString(defaultRightComponent)
      && rightComponentByName
      && rightComponentByName[defaultRightComponent]
    ) {
      this.setState({
        shownOnRight: defaultRightComponent,
        focusedLeftItemId: check.nonEmptyString(defaultFocusedLeftItemId)
          ? defaultFocusedLeftItemId
          : null,
      });
    }
  }

  showOnRight(componentName, focusedLeftItemId = null) {
    const { focusedLeftItemId: prevFocusedLeftItemId } = this.state;
    if (!componentName && check.nonEmptyString(prevFocusedLeftItemId)) {
      const to = prevFocusedLeftItemId;

      const h = window.document.getElementById(to).getBoundingClientRect().top;
      animateScrollTo([null, h], {
        elementToScroll: window.document.getElementById('dual-pane-app-wrapper'),
      });
    }

    if (!isNative()) {
      // window.location.hash = `${componentName || ''}/${focusedLeftItemId || ''}`;
    }

    this.setState({ shownOnRight: componentName, focusedLeftItemId });
  }

  render() {
    const { leftComponent: LeftComponent, rightComponentByName, ...props } = this.props;
    const { shownOnRight = null, focusedLeftItemId = null } = this.state;

    const RightComponent = this.getRightComponent();

    return (
      <DualPanePage
        showOnRight={this.showOnRight}
        focusedLeftItemId={focusedLeftItemId}
        currentRightPaneComponentName={shownOnRight}
        {...props}
        leftComponent={LeftComponent}
        rightComponent={RightComponent}
      />
    );
  }
}
