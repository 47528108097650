import React from 'react';

import ResponsivePage from '../../01_atoms/ResponsivePage';

import SinglePaneEditPassengerPopup from './EditPassengerPopup.mobile';
import DualPaneEditPassengerPopup from './EditPassengerPopup.widescreen';

const EditPassengerPopup = ({ sdk, ...props }) => {
  const {
    i18n: { translatorForNamespace },
  } = sdk;

  const t = translatorForNamespace('AddPassengerPopupUI');

  return (
    <ResponsivePage
      wideScreenComponent={DualPaneEditPassengerPopup}
      mobileComponent={SinglePaneEditPassengerPopup}
      {...props}
      {...{
        sdk,
        translator: t,
      }}
    />
  );
};

export default EditPassengerPopup;
