import React from 'react';

import ResponsivePage from '../../01_atoms/ResponsivePage';

import SinglePaneCovidInfoPage from './06_CovidInfoPage.mobile';
import DualPaneCovidInfoPage from './06_CovidInfoPage.widescreen';

const BillingDetailsPage = ({ ...props }) => (
  <ResponsivePage
    wideScreenComponent={DualPaneCovidInfoPage}
    mobileComponent={SinglePaneCovidInfoPage}
    {...props}
  />
);

export default BillingDetailsPage;
