import React from 'react';
import check from 'check-types';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import moment from '../../lib/moment';

import Button from '../01_atoms/Button';
import Container from '../01_atoms/Container';
import Bottom from '../01_atoms/Bottom';
import TitledContainer from '../01_atoms/TitledContainer';

import TicketDetails from '../02_molecules/TicketDetails';

const OrdersListWidget = ({
  tickets,
  isFiltered = false,
  onUnfilter = () => {},
  translationManager,
  translator: t,
  translatorForNamespace,
}) => {
  // Sort tickets by descending departing order
  tickets.sort(
    (a, b) => moment(b.ticketDetails.departureTime).valueOf()
      - moment(a.ticketDetails.departureTime).valueOf(),
  );

  return (
    <TitledContainer title={t('Your next trips')}>
      {tickets.map((ticket) => {
        let topRightLabel = null;
        const hasExpired = moment(ticket.ticketDetails.arrivalTime).valueOf() < moment().add(1, 'hours').valueOf();

        const {
          statusDetails: { booked = true, cancelled = false },
        } = ticket;
        if (cancelled) {
          topRightLabel = {
            color: 'red',
            content: (
              <>
                <Icon name="cancel" />
                {t('Order cancelled')}
              </>
            ),
          };
        } else if (!booked) {
          topRightLabel = {
            color: 'orange',
            content: (
              <>
                <Icon name="wait" />
                {t('Order in process')}
              </>
            ),
          };
        } else {
          topRightLabel = {
            color: 'green',
            content: (
              <>
                <Icon name="check" />
                {t('Order confirmed')}
              </>
            ),
          };
        }

        let style = {};
        if (hasExpired) {
          style = { opacity: '0.5' };
        }

        return (
          <Link to={`/tickets/${ticket.id}`}>
            <TicketDetails
              id={`ticket-thumb_${ticket.id}`}
              key={`ticket-thumb_${ticket.id}`}
              elementStyle={style}
              topRightLabel={topRightLabel}
              ticketDetails={ticket.ticketDetails}
              nPassengers={
                check.nonEmptyArray(ticket.ticketDetails.passengers)
                  ? ticket.ticketDetails.passengers.length
                  : 1
              }
              expired={hasExpired}
              locale={translationManager.getCurrentLanguage()}
              titlePadding={10}
              backgroundColor="white"
            />
          </Link>
        );
      })}

      {isFiltered && (
        <Bottom>
          <Container>
            <Button fluid onClick={onUnfilter}>
              {t('Show all tickets')}
            </Button>
          </Container>
        </Bottom>
      )}
    </TitledContainer>
  );
};

export default OrdersListWidget;
