import React from 'react';

import RightPaneWidgetFrame from '../01_atoms/RightPaneWidgetFrame';
import AddPassengerWidget from './AddPassengerWidget';

const AddPassengerRightPane = ({
  scrollTop = 0,
  focusedLeftItemId = null,
  sdk,
  onAdd = () => {},
  onBack = () => {},
  translatorForNamespace,
}) => (
  <RightPaneWidgetFrame scrollTop={scrollTop} focusedLeftItemId={focusedLeftItemId}>
    <AddPassengerWidget
      sdk={sdk}
      onAdd={onAdd}
      onBack={onBack}
      translatorForNamespace={translatorForNamespace}
    />
  </RightPaneWidgetFrame>
);

export default AddPassengerRightPane;
