import React from 'react';
import BarLoader from 'react-spinners/BarLoader';
import { Image } from 'semantic-ui-react';
import { withWidgetSubtitleFont } from '../00_environment/Fonts';
import logo from '../../assets/logo';

const LoadingScreen = ({ text }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100vh',
    }}
  >
    <div
      style={{
        width: '100vw',
        height: '60vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Image src={logo} size="small" verticalAlign="middle" />
    </div>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '20%',
        //   width: '100vw',
      }}
    >
      <span style={withWidgetSubtitleFont({ fontSize: '16px' })}>{`${text}...`}</span>
      <BarLoader
        css={{ margin: 10, width: '100%', maxWidth: 200 }}
        color="#722B7C" // "#5DA895"
        loading
      />
    </div>
  </div>
);

export default LoadingScreen;
