import React from 'react';

import ResponsivePage from '../../01_atoms/ResponsivePage';

import SinglePaneGenericTripSeatSelectionPage from './03b_GenericTripSeatSelectionPage.mobile';
import DualPaneGenericTripsDetailsPage from './03_GenericTripsDetailsPage.widescreen';

const GenericTripSeatSelectionPage = ({ ...props }) => (
  <ResponsivePage
    wideScreenComponent={DualPaneGenericTripsDetailsPage}
    mobileComponent={SinglePaneGenericTripSeatSelectionPage}
    {...props}
  />
);

export default GenericTripSeatSelectionPage;
