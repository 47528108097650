import React from 'react';

import TitledContainer from '../../01_atoms/TitledContainer';
import DefaultScreen from '../../02_molecules/DefaultScreen';
import DualPanePageWithSelector from '../../02_molecules/DualPanePageWithSelector';
import BillingDetailsRightPane from '../../02_molecules/BillingDetailsRightPane';
import CovidFormRightPane from '../../02_molecules/CovidFormRightPane';
import PassengersSelectionRightPane from '../../02_molecules/PassengersSelectionRightPane';
import AddPassengerRightPane from '../../02_molecules/AddPassengerRightPane';

import OrderDetailsWidget from '../../03_organisms/OrderDetailsWidget';

const PassengersDetailsPanel = ({
  goToFunc, showOnRight, focusedLeftItemId, sdk,
}) => {
  const goTo = goToFunc(showOnRight);
  return (
    <PassengersSelectionRightPane
      sdk={sdk}
      savedPassengers={sdk.passengers.getAllSavedPassengers()}
      selectedPassengers={sdk.passengers.getPassengersSelectedForNextTrip()}
      numberOfPassengers={sdk.bookingProcess.getNumberOfPassengersForCurrentOrder()}
      onPassengerToggle={(d) => sdk.passengers.togglePassengerForNextTrip(d)}
      onAddPassenger={() => {
        goTo('passenger-details/add', 'edit-passengers-button');
      }}
      onConfirmPassengers={() => {
        showOnRight(null);
      }}
      showOnRight={showOnRight}
      focusedLeftItemId={focusedLeftItemId}
    />
  );
};

const AddPassengerPanel = ({
  goToFunc,
  showOnRight,
  scrollTop = 0,
  focusedLeftItemId,
  sdk,
  translatorForNamespace,
}) => {
  const goTo = goToFunc(showOnRight);
  return (
    <AddPassengerRightPane
      {...{
        showOnRight,
        translatorForNamespace,
        scrollTop,
        focusedLeftItemId,
        sdk,

        onAdd: async (p) => {
          // Create user
          await sdk.passengers.createPassengerSelectIt(p);

          // Go back
          goTo('passenger-details', 'edit-passengers-button');
        },
        onBack: () => goTo('passenger-details', 'edit-passengers-button'),
      }}
    />
  );
};

const BillingDetailsPanel = ({ sdk, showOnRight, focusedLeftItemId }) => (
  <BillingDetailsRightPane
    sdk={sdk}
    onBillingDetailsValidated={showOnRight}
    focusedLeftItemId={focusedLeftItemId}
  />
);

const CovidInfoPanel = ({
  showOnRight,
  focusedLeftItemId,
  sdk,
  translatorForNamespace,
  history,
}) => (
  <CovidFormRightPane
    defaultValue={sdk.covid19.getCovidInfo()}
    validateData={(data) => sdk.covid19.isCovid19InfoValid(data)}
    onSubmit={async (covidInfo) => {
      await sdk.covid19.setCovidInfo(covidInfo);
      // showOnRight(null);
      sdk.bookingProcess.goForward(history);
    }}
    translatorForNamespace={translatorForNamespace}
    showOnRight={showOnRight}
    focusedLeftItemId={focusedLeftItemId}
  />
);

const LeftComponent = ({
  goToFunc,
  showOnRight,
  currentRightPaneComponentName,
  sdk,
  onBack,
  t,
  history,
}) => {
  const goTo = goToFunc(showOnRight);

  return (
    <TitledContainer
      title={t('Trip details')}
      rightButtonText={t('Back')}
      onRightButtonClick={onBack}
    >
      <OrderDetailsWidget
        sdk={sdk}
        numberOfPassengers={sdk.bookingProcess.getNumberOfPassengersForCurrentOrder()}
        selectedPassengers={sdk.passengers.getPassengersSelectedForNextTrip()}
        userSavedCard={sdk.userSettings.getUserSavedCard()}
        payLink="/book/checkout/pay/"
        passengerId={sdk.billingDetails.getBillingPassengerId()}
        payAmount={sdk.bookingProcess.getCurrentOrderAmount()}
        outboundTrip={sdk.bookingProcess.getSelectedOutboundTrip()}
        returnTrip={sdk.bookingProcess.getSelectedReturnTrip()}
        onEditPassengers={() => goTo('passenger-details', 'edit-passengers-button')}
        onEditBillingDetails={() => goTo('billing-details', 'edit-billing-details-button')}
        onEditCovidDetails={() => goTo('covid-info', 'edit-covid-details-button')}
        dontHighlight={!!currentRightPaneComponentName}
        history={history}
      />
    </TitledContainer>
  );
};

// Define here to keep same Object between renders
// and avoid harmful component remounting
const rightComponentByName = {
  'passenger-details': PassengersDetailsPanel,
  'passenger-details/add': AddPassengerPanel,
  'billing-details': BillingDetailsPanel,
  'covid-info': CovidInfoPanel,
};

const GenericTripDetailsPageUI = (props) => {
  const {
    onBack = () => {},
    sdk,
    history,
    defaultRightComponent,
    defaultFocusedLeftItemId,
  } = props;
  const {
    i18n: { translatorForNamespace },
  } = sdk;
  const t = translatorForNamespace('CheckoutPageUI');

  const goToFunc = (showOnRight) => (elem, leftElem) => {
    const url = `/book/${elem}`;

    if (window.location.pathname.includes(url)) {
      showOnRight(elem, leftElem);
    } else {
      history.push(url);
    }
  };

  return (
    <DefaultScreen title={t('Ready?')} onBack={onBack}>
      <DualPanePageWithSelector
        goToFunc={goToFunc}
        onBack={onBack}
        sdk={sdk}
        t={t}
        history={history}
        translatorForNamespace={translatorForNamespace}
        defaultRightComponent={defaultRightComponent}
        defaultFocusedLeftItemId={defaultFocusedLeftItemId}
        leftComponent={LeftComponent}
        rightComponentByName={rightComponentByName}
      />
    </DefaultScreen>
  );
};

export default GenericTripDetailsPageUI;
