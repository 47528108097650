import React from 'react';

import DualPanePage from '../../01_atoms/DualPanePage';
import Page from '../../01_atoms/Page';
import TopBar from '../../01_atoms/TopBar';
import EditPassengersRightPane from '../../03_organisms/EditPassengersRightPane';
import SettingsWidget from '../../03_organisms/SettingsWidget';

const DualPaneSettingsPage = ({ translator: t, onBack = () => {}, ...props }) => (
  <Page>
    <TopBar
      title={t('Edit known passengers')}
      leftIcon={onBack ? 'arrow left' : null}
      onLeftIconClick={onBack}
    />

    <DualPanePage
      leftComponent={SettingsWidget}
      rightComponent={EditPassengersRightPane}
      {...{
        translator: t,
        onBack,
        ...props,
      }}
    />
  </Page>
);
export default DualPaneSettingsPage;
