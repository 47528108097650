import React from 'react';
import formatNumber from 'format-number';
import check from 'check-types';
import BuseaApi from '@seafront/busea.api';

import { serviceFeeName } from '../00_environment/Constants';
import ElementGroup from '../01_atoms/ElementGroup';
import KeyValueElement from '../01_atoms/KeyValueElement';

const formatPrice = (price) => formatNumber({ prefix: '$', round: 2, padRight: 2 })(price);

const OrderPriceDetails = ({
  title,
  outboundTrip,
  returnTrip,
  nPassengers,
  translatorForNamespace,
}) => {
  const t = translatorForNamespace('OrderOrTripPriceDetails');
  const orderPricing = BuseaApi.getOrderPricing(outboundTrip, returnTrip, nPassengers);
  const { outboundTripPricing, returnTripPricing } = orderPricing;

  return (
    <ElementGroup title={title || t('Price details')}>
      <KeyValueElement
        slim
        title={returnTrip ? t('Outbound ticket price') : t('Ticket price')}
        value={formatPrice(outboundTripPricing.grossPrice)}
      />
      {returnTrip && (
        <KeyValueElement
          slim
          title={t('Return ticket price')}
          value={formatPrice(returnTripPricing.grossPrice)}
        />
      )}

      {check.positive(orderPricing.companyFee) && (
        <KeyValueElement
          slim
          grayed
          title={t('Company booking service')}
          value={formatPrice(orderPricing.companyFee)}
        />
      )}
      <KeyValueElement
        slim
        grayed
        title={t(serviceFeeName)}
        value={formatPrice(orderPricing.buseaFee)}
      />
      <KeyValueElement
        slim
        grayed
        title={t('Credit card payment fees')}
        value={formatPrice(orderPricing.paymentFee)}
      />
      <KeyValueElement
        title={<b>{t('TOTAL')}</b>}
        value={<b>{formatPrice(orderPricing.total)}</b>}
      />
    </ElementGroup>
  );
};

export default OrderPriceDetails;
