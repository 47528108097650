import React from 'react';

import ListSelect from '../01_atoms/ListSelect';
import Element from '../01_atoms/Element';
import ElementGroup from '../01_atoms/ElementGroup';
import RightPaneWidgetFrame from '../01_atoms/RightPaneWidgetFrame';
import TitledContainer from '../01_atoms/TitledContainer';

const CitySelectionWidget = ({
  title,
  items = {},
  selectedItem = null,
  onSelect = () => {},
  elementStyle = {},
  translator: t = (txt) => txt,
  focusedLeftItemId = null,
  scrollTop = 0,
}) => (
  <RightPaneWidgetFrame scrollTop={scrollTop} focusedLeftItemId={focusedLeftItemId}>
    <TitledContainer title={title}>
      <ListSelect
        {...{
          title,
          items,
          selectedItem,
          onSelect,
          translator: t,
        }}
      />
    </TitledContainer>
  </RightPaneWidgetFrame>
);

export default CitySelectionWidget;
