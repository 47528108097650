import React from 'react';

import ResponsivePage from '../../01_atoms/ResponsivePage';

import SinglePanePassengerDetailsPage from './04_PassengerDetailsPage.mobile';
import DualPanePassengerDetailsPage from './04_PassengerDetailsPage.widescreen';

const PassengerDetailsPage = ({ ...props }) => (
  <ResponsivePage
    wideScreenComponent={DualPanePassengerDetailsPage}
    mobileComponent={SinglePanePassengerDetailsPage}
    {...props}
  />
);

export default PassengerDetailsPage;
