import React from 'react';

import ResponsivePage from '../01_atoms/ResponsivePage';
import CentralPaneWrapper from '../01_atoms/CentralPaneWrapper';

const ResponsiveCentralPaneWrapper = (props) => (
  <ResponsivePage
    wideScreenComponent={CentralPaneWrapper}
    mobileComponent={CentralPaneWrapper}
    {...props}
  />
);

export default ResponsiveCentralPaneWrapper;
