import React from 'react';

import RightPaneWidgetFrame from '../01_atoms/RightPaneWidgetFrame';
import DateSelectionComponent from './DateSelectionComponent';

const DateSelectionWidget = ({ scrollTop = 0, focusedLeftItemId = null, ...props }) => (
  <RightPaneWidgetFrame scrollTop={scrollTop} focusedLeftItemId={focusedLeftItemId}>
    <DateSelectionComponent {...props} />
  </RightPaneWidgetFrame>
);

export default DateSelectionWidget;
