import React from 'react';

import RightPaneWidgetFrame from '../01_atoms/RightPaneWidgetFrame';
import EditPassengersWidget from './EditPassengersWidget';

const EditPassengersRightPane = ({ focusedLeftItemId, ...otherProps }) => (
  <RightPaneWidgetFrame focusedLeftItemId={focusedLeftItemId}>
    <EditPassengersWidget {...otherProps} />
  </RightPaneWidgetFrame>
);

export default EditPassengersRightPane;
