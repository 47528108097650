import React from 'react';

import ResponsivePage from '../01_atoms/ResponsivePage';

import SinglePaneTicketPage from './TicketPage.mobile';
import DualPaneTicketPage from './OrdersPage.widescreen';

const TicketPage = ({ translatorForNamespace, ...props }) => {
  const t = translatorForNamespace('BookingFormPageUI');

  return (
    <ResponsivePage
      wideScreenComponent={DualPaneTicketPage}
      mobileComponent={SinglePaneTicketPage}
      {...props}
      {...{
        translatorForNamespace,
        t,
      }}
    />
  );
};

export default TicketPage;
