import React from 'react';
import formatNumber from 'format-number';

import { appName, askForCovidInfo } from '../00_environment/Constants';
import Container from '../01_atoms/Container';
import BlockWithHeader from '../01_atoms/BlockWithHeader';
import Bottom from '../01_atoms/Bottom';
import Button from '../01_atoms/Button';
import Divider from '../01_atoms/Divider';
import ElementGroup from '../01_atoms/ElementGroup';
import Element from '../01_atoms/Element';
import HighligthedText from '../01_atoms/HighligthedText';
import Icon from '../01_atoms/Icon';
import LinkButton from '../01_atoms/LinkButton';
import PaymentIcon from '../01_atoms/PaymentIcon';

import BillingDetails from '../02_molecules/BillingDetails';
import CovidDetails from '../02_molecules/CovidDetails';
import OrderPriceDetails from '../02_molecules/OrderPriceDetails';
import PassengerDetails from '../02_molecules/PassengerDetails';
import TicketDetails from '../02_molecules/TicketDetails';
import TripSeatDetailsTwoWays from '../02_molecules/TripSeatDetailsTwoWays';

const formatPrice = (price) => formatNumber({ prefix: '$', round: 2, padRight: 2 })(price);

const OrderDetailsWidget = ({
  sdk,
  history,
  numberOfPassengers,
  selectedPassengers,
  userSavedCard,
  payLink,
  passengerId,
  outboundTrip,
  returnTrip,
  payAmount,

  onEditPassengers,
  onEditBillingDetails,
  onEditCovidDetails,
}) => {
  const {
    i18n: { translationManager, translatorForNamespace },
  } = sdk;
  const t = translatorForNamespace('CheckoutPageUI');
  let payLabel = t('Pay {amount}', { amount: formatPrice(payAmount) });

  const showSavedCard = !!userSavedCard && !!passengerId;

  if (showSavedCard) {
    payLabel = (
      <div
        style={{
          display: 'inline-flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <PaymentIcon
          id={userSavedCard.brand.toLowerCase()}
          style={{ display: 'inline', height: '1rem', marginRight: 5 }}
        />
        {` ${payLabel} `}
        {`${t('with card')} *${userSavedCard.last4}`}
      </div>
    );
  }

  const outboundTicketTitle = (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <span>{`${returnTrip ? t('Forward trip') : t('One-way trip')}`}</span>
      <span>
        {(numberOfPassengers > 1 ? `${numberOfPassengers}x ` : '')
          + formatPrice(outboundTrip.grossPrice)}
      </span>
    </div>
  );

  const returnTicketTitle = returnTrip ? (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <span>{t('Return trip')}</span>
      <span>
        {(numberOfPassengers > 1 ? `${numberOfPassengers}x ` : '')
          + formatPrice(returnTrip.grossPrice)}
      </span>
    </div>
  ) : (
    ''
  );

  let highlight = null;
  if (
    sdk.covid19.isCovid19InfoFilled()
    && sdk.billingDetails.areBillingDetailsValid()
    && sdk.passengers.allPassengersSelectedAndValid()
    && sdk.seats.allSeatsSelectedAndValid(true)
  ) {
    highlight = 'Checkout';
  } else if (
    sdk.covid19.isCovid19InfoFilled()
    && sdk.billingDetails.areBillingDetailsValid()
    && sdk.passengers.allPassengersSelectedAndValid()
  ) {
    highlight = 'Seats';
  } else if (
    sdk.billingDetails.areBillingDetailsValid()
    && sdk.passengers.allPassengersSelectedAndValid()
  ) {
    highlight = 'CovidDetails';
  } else if (sdk.passengers.allPassengersSelectedAndValid()) {
    highlight = 'BillingDetails';
  } else {
    highlight = 'PassengerDetails';
  }

  const title = (
    <div style={{ display: 'inline', marginLeft: 5 }}>
      {/* `${isReturn ? t('Return') : t('Outbound')}: ` */}
      {sdk.terminals.nameFromId(outboundTrip.origin)}
      <Icon name="arrow right" style={{ display: 'inline', marginLeft: 5 }} />
      {`${sdk.terminals.nameFromId(outboundTrip.destination)}`}
      &nbsp;&nbsp;&nbsp;
    </div>
  );

  const outboundSeats = sdk.seats.getSelectedSeats(false);
  const returnSeats = sdk.seats.getSelectedSeats(true);

  return (
    <BlockWithHeader
      ribbonContent={title}
      headerContent={(
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            textAlign: 'left',
          }}
        >
          <div
            style={{
              fontSize: '1.6rem',
              fontWeight: '600',
              marginTop: 5,
              marginBottom: 2,
            }}
          >
            {
              formatPrice(payAmount)
              /* sdk.routes.humanPricingForRoute(outboundTrip, numberOfPassengers, false) */
            }
          </div>
          <div style={{ fontSize: '0.9rem', fontWeight: 'normal' }}>
            {`${numberOfPassengers} ${t('passenger')}${numberOfPassengers > 1 ? 's' : ''}`}
          </div>
        </div>
      )}
    >
      <TicketDetails
        title={outboundTicketTitle}
        ticketDetails={outboundTrip}
        nPassengers={numberOfPassengers}
        locale={translationManager.getCurrentLanguage()}
      />

      {returnTrip && (
        <TicketDetails
          title={returnTicketTitle}
          ticketDetails={returnTrip}
          nPassengers={numberOfPassengers}
          locale={translationManager.getCurrentLanguage()}
        />
      )}

      <OrderPriceDetails
        outboundTrip={outboundTrip}
        returnTrip={returnTrip}
        nPassengers={numberOfPassengers}
        translatorForNamespace={translatorForNamespace}
      />

      <PassengerDetails
        title={t('Passengers')}
        translatorForNamespace={translatorForNamespace}
        passengers={selectedPassengers}
        translator={t}
        onEdit={onEditPassengers}
        highlightEditButton={highlight === 'PassengerDetails'}
      />

      <TripSeatDetailsTwoWays
        sdk={sdk}
        hasReturn={!!returnTrip}
        outboundSeats={outboundSeats}
        returnSeats={returnSeats}
        onEditOutbound={() => {
          history.push('/book/out/select/seats/');
        }}
        onEditReturn={() => {
          history.push('/book/return/select/seats/');
        }}
        translator={t}
        id="seats-summary"
      />

      <BillingDetails
        sdk={sdk}
        title={t('Billing details')}
        translatorForNamespace={translatorForNamespace}
        translator={t}
        highlightEditButton={highlight === 'BillingDetails'}
        onEdit={onEditBillingDetails}
      />

      {askForCovidInfo && (
        <CovidDetails
          sdk={sdk}
          title={t('Covid19-related information')}
          translatorForNamespace={translatorForNamespace}
          translator={t}
          onEdit={onEditCovidDetails}
          highlightEditButton={highlight === 'CovidDetails'}
        />
      )}

      <ElementGroup>
        <Element>
          {t('Tickets are nominative.')}
          <br />
          {t("You'll need to present your")}
          {' '}
          <HighligthedText>{t('cedula or passport')}</HighligthedText>
          {' '}
          {t('along with your ticket before boarding.')}
        </Element>

        <Element>
          {t("You'll receive your tickets")}
          {' '}
          <HighligthedText>{`${t('by e-mail')}.`}</HighligthedText>
          <br />
          {t('You can present your tickets either on the {appName} app or printed on paper.', {
            appName,
          })}
        </Element>

        <Element>
          {`${t('Tickets are')} `}
          <HighligthedText>{t('not changeable')}</HighligthedText>

          {` ${t('and')} `}
          <HighligthedText>{t('not refundable')}</HighligthedText>
          {'. '}
          {t('Please check your order twice before ordering.')}
        </Element>
      </ElementGroup>

      {highlight === 'Checkout' && (
        <Bottom>
          <Container style={{ textAlign: 'center' }}>
            <Button to={payLink} primary fluid>
              {payLabel}
            </Button>
            {showSavedCard && (
              <>
                <Divider text={t('or')} />
                <LinkButton to={`${payLink}new-card`}>{t('Use another card')}</LinkButton>
              </>
            )}
          </Container>
        </Bottom>
      )}
    </BlockWithHeader>
  );
};

export default OrderDetailsWidget;
