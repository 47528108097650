import React from 'react';

import RightPaneWidgetFrame from '../01_atoms/RightPaneWidgetFrame';
import LanguageSettingsWidget from './LanguageSettingsWidget';

const LanguageSettingsRightPane = ({ focusedLeftItemId, ...otherProps }) => (
  <RightPaneWidgetFrame focusedLeftItemId={focusedLeftItemId}>
    <LanguageSettingsWidget {...otherProps} />
  </RightPaneWidgetFrame>
);

export default LanguageSettingsRightPane;
