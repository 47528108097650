import React from 'react';

import ResponsivePage from '../../01_atoms/ResponsivePage';

import SinglePaneGenericTripsListPage from './02_GenericTripsListPage.mobile';
import DualPaneGenericTripsListPage from './02_GenericTripsListPage.widescreen';

const GenericTripsListPage = ({ ...props }) => (
  <ResponsivePage
    wideScreenComponent={DualPaneGenericTripsListPage}
    mobileComponent={SinglePaneGenericTripsListPage}
    {...props}
  />
);

export default GenericTripsListPage;
