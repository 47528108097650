import React from 'react';

import DefaultScreen from '../../02_molecules/DefaultScreen';
import EditPassengersWidget from '../../03_organisms/EditPassengersWidget';

const MobileEditPassengersPage = ({
  userPassengerId = null,
  savedPassengers = [],
  // onPassengerDelete = () => {},
  onPassengerEdit = () => {},
  addPassengerLink = '',
  onBack = () => {},
  translator: t,
}) => (
  <DefaultScreen title={t('Edit known passengers')} onBack={onBack}>
    <EditPassengersWidget
      {...{
        userPassengerId,
        savedPassengers,
        // onPassengerDelete,
        onPassengerEdit,
        addPassengerLink,
        onBack,
        translator: t,
      }}
    />
  </DefaultScreen>
);
export default MobileEditPassengersPage;
