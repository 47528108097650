import React from 'react';

import RightPaneWidgetFrame from '../01_atoms/RightPaneWidgetFrame';
import TicketWidget from './TicketWidget';

const TicketRightPane = ({ scrollTop = 0, focusedLeftItemId = null, ...otherProps }) => (
  <RightPaneWidgetFrame scrollTop={scrollTop} focusedLeftItemId={focusedLeftItemId}>
    <TicketWidget {...otherProps} />
  </RightPaneWidgetFrame>
);

export default TicketRightPane;
