import React from 'react';
import { Icon } from 'semantic-ui-react';
import formatNumber from 'format-number';
import { ucfirstEachWord } from '@seafront/ucfirst';
import check from 'check-types';
import arrayUnique from 'array-unique';

import BlockWithHeader from '../01_atoms/BlockWithHeader';
import Container from '../01_atoms/Container';
import Bottom from '../01_atoms/Bottom';
import Button from '../01_atoms/Button';
import TicketDetails from '../02_molecules/TicketDetails';
import TitledContainer from '../01_atoms/TitledContainer';
import TripPriceDetails from '../02_molecules/TripPriceDetails';
import TripSeatDetails from '../02_molecules/TripSeatDetails';

const formatPrice = (price) => formatNumber({ prefix: '$', round: 2, padRight: 2 })(price);

const TripDetailsWidget = ({
  sdk,
  trip,
  isReturn,
  numberOfPassengers,
  isOneWayTrip,
  showConfirmButton = true,
  onBack = () => {},
  confirmLink,
  showSeatsLink = null,
}) => {
  const {
    i18n: { translationManager, translatorForNamespace },
  } = sdk;
  const t = translatorForNamespace('GenericTripDetailsPageUI');

  const selectedSeats = sdk.seats.getSelectedSeats(isReturn);
  const nSelectedSeats = selectedSeats.length;
  const seatsLeftToBook = numberOfPassengers - nSelectedSeats;

  let readableTicketPrice = (numberOfPassengers > 1 ? `${numberOfPassengers}x ` : '') + formatPrice(trip.grossPrice);
  if (check.nonEmptyArray(selectedSeats)) {
    const prices = sdk.seats.getSelectedSeatsData(isReturn).map((s) => s.priceInCents / 100);
    const uniquePrices = arrayUnique(Array.from(prices)).sort();
    readableTicketPrice = '';
    uniquePrices.forEach((price, i) => {
      const n = prices.filter((p) => p === price).length;
      readableTicketPrice += `${n}x ${formatPrice(price)}`;
      if (i < uniquePrices.length - 1) {
        readableTicketPrice += ', ';
      }
    });
  }

  const ticketTitle = (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <span>{`${isReturn ? t('Return trip') : t('Outbound trip')}`}</span>
      <span>{readableTicketPrice}</span>
    </div>
  );

  const ribbonText = (
    <div style={{ display: 'inline', marginLeft: 5 }}>
      {/* `${isReturn ? t('Return') : t('Outbound')}: ` */}
      {sdk.terminals.nameFromId(trip.origin)}
      <Icon name="arrow right" style={{ display: 'inline', marginLeft: 5 }} />
      {`${sdk.terminals.nameFromId(trip.destination)}`}
      &nbsp;&nbsp;&nbsp;
    </div>
  );

  let tripName = t('trip');
  if (!isOneWayTrip) {
    tripName = isReturn ? t('return trip') : t('outbound trip');
  }

  return (
    <TitledContainer
      title={isReturn ? t('Return details') : t('Outbound details')}
      rightButtonText={t('Modify')}
      onRightButtonClick={onBack}
    >
      <BlockWithHeader
        ribbonContent={ribbonText}
        headerContent={(
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
              textAlign: 'left',
            }}
          >
            <div
              style={{
                fontSize: '1.6rem',
                fontWeight: '600',
                marginTop: 5,
                marginBottom: 2,
              }}
            >
              {sdk.routes.humanPricingForRoute(trip, numberOfPassengers, false)}
            </div>
            <div style={{ fontSize: '0.9rem', fontWeight: 'normal' }}>
              {`${numberOfPassengers} ${t('passenger')}${numberOfPassengers > 1 ? 's' : ''}`}
            </div>
          </div>
        )}
      >
        <TicketDetails
          title={ticketTitle}
          ticketDetails={trip}
          nPassengers={numberOfPassengers}
          locale={translationManager.getCurrentLanguage()}
        />

        <TripPriceDetails
          trip={trip}
          numberOfPassengers={numberOfPassengers}
          isOneWayTrip={isOneWayTrip}
          translatorForNamespace={translatorForNamespace}
        />

        <TripSeatDetails
          id="seats-details"
          selectedSeats={sdk.seats.getSelectedSeats(isReturn)}
          numberOfPassengers={numberOfPassengers}
          translator={t}
          onEditLink={showSeatsLink}
        />

        {showConfirmButton && seatsLeftToBook === 0 && (
          <Bottom>
            <Container>
              <Button to={confirmLink} fluid primary>
                {`${t('Confirm')} ${tripName}`}
              </Button>
            </Container>
          </Bottom>
        )}
      </BlockWithHeader>
    </TitledContainer>
  );
};

export default TripDetailsWidget;
