function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export const roundedElementBorderRadius = 6;
export const displayTopBar = !inIframe();
export const paneMarginTop = '2%';
export const panePadding = '2%';
export const appName = 'Reina del Camino';
export const appWebsite = 'https://reinadelcamino.ec/';
export const serviceFeeName = 'Internet booking service';
export const askForCovidInfo = false;
