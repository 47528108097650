import React from 'react';

import DefaultScreen from '../../02_molecules/DefaultScreen';
import EditPassengerForm from '../../02_molecules/EditPassengerForm';

const MobileEditPassengerPopup = ({
  sdk,
  onEdit = () => {},
  onBack = () => {},
  onDelete = () => {},
  passenger = {},
  translator: t,
  translatorForNamespace,
}) => (
  <DefaultScreen title={t('Edit passenger')} onBack={onBack}>
    <EditPassengerForm
      {...{
        sdk,
        translatorForNamespace,

        defaultValue: passenger,

        submitButtonLabel: t('Save'),
        onSubmit: onEdit,

        leftButtonLabel: t('Delete'),
        onLeftButtonClick: onDelete,
        leftButtonColor: 'google plus',

        config: {
          name: {
            title: t('Passenger name'),
            subtitle: t('Will display on your tickets'),
            show: true,
          },
          phone: {
            title: t('Phone number'),
            subtitle: t('Used to send ticket information'),
            show: true,
          },
          ssn: {
            title: t('Identification document'),
            subtitle: t('Necessary to onboard the bus'),
            show: true,
          },

          email: {
            show: true,
          },
          address: {
            show: true,
          },
        },
      }}
    />
  </DefaultScreen>
);
export default MobileEditPassengerPopup;
